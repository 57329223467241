<template>
  <transition  name="modal-fade">
  <div class="modal" role="dialog" @click.self="closeModal">
    <div class="container">
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="close-btn" @click="closeModal" aria-label="Close modal"></div>
    </div>
  </div>
  </transition>
</template>


<script>
export default {
  methods: {
    closeModal(){
      this.$emit('close');
    },
  },
}
</script>
<style lang="stylus" scoped>
.modal{
  position: fixed;
  z-index: 9999;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: auto;
  }

  .container{
    background: white;
    width: 90%;
    height: 90%;
    position: relative;
    .modal-body{
      height: 100%;
    }
    .close-btn{
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #E9E9E9;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      transform: rotate(45deg);
      &:after, &:before{
        position: absolute;
        top: 50%;
        left: 50%;
        content:'';
        width:1px;
        height: 15px;
        background-color: #2A3642;
        transform: translate(-50%, -50%);
      }
      &:after{
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}
 .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .25s ease;
  }
@media only screen and (max-width: 1024px) {
  .modal{
    .container{
      width: 100%;
      height: 100%;
    }
  }
}
</style>