<template>
  <div class="flex flex-column">
    <h2 class="subtitle">{{ subtitleProp }}<span></span></h2>
    <header class="fixed">
      <!-- Title -->
      <h1>
        <router-link to="/" class="primary">
          <svg
            id="windmill"
            width="33"
            height="33"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Canvas" transform="translate(39283 -6710)">
              <g id="Group">
                <use
                  xlink:href="#path0_fill"
                  transform="translate(-39268.5 6727.7)"
                  fill="#E43852"
                  id="Vector"
                />
                <use
                  xlink:href="#path1_fill"
                  transform="translate(-39282.3 6723.92)"
                  fill="#E43852"
                  id="Vector"
                />
                <use
                  xlink:href="#path2_fill"
                  transform="translate(-39271.3 6710)"
                  fill="#E43852"
                  id="Vector"
                />
                <use
                  xlink:href="#path3_fill"
                  transform="translate(-39264.7 6721.04)"
                  fill="#E43852"
                  id="Vector"
                />
              </g>
            </g>
            <defs>
              <path
                id="path0_fill"
                d="M7.474 14.754c.156-.157 0-.47-.31-.942C2.806 8.476.938 3.767 1.716 0 .006 2.668-.46 5.494.473 8.476c.933 2.982 2.956 5.18 6.068 6.278.467.314.778.314.933 0z"
              />
              <path
                id="path1_fill"
                d="M14.742 1.733C12.097.006 9.297-.465 6.34.477 3.384 1.419 1.206 3.459.117 6.598c-.156.471-.156.785 0 .942.155.157.466 0 .933-.314 5.29-4.395 9.958-6.121 13.692-5.493z"
              />
              <path
                id="path2_fill"
                d="M.995.118C.528-.04.217-.04.06.118c-.156.157 0 .47.311.941C4.73 6.396 6.596 11.105 5.818 14.872c1.712-2.668 2.178-5.494 1.245-8.476C5.973 3.414 3.95 1.216.995.118z"
              />
              <path
                id="path3_fill"
                d="M14.626 1.003c.155-.47.155-.784 0-.941-.156-.157-.467 0-.934.313C8.402 4.77 3.734 6.654 0 5.87c2.645 1.727 5.446 2.197 8.402 1.256 2.956-1.099 4.979-3.14 6.224-6.122z"
              />
            </defs>
          </svg>
          <svg
            width="153"
            height="53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="logo_asiance"
              d="M14.52 46.59H5.68l-2.26 5H0l10.3-21.9 9.8 21.9h-3.41l-2.17-5zm-1.27-2.98l-3.07-7.01-3.2 7.01h6.27zM32.02 35.48c-.19-.47-.47-.9-.93-1.3-.65-.53-1.33-.74-2.23-.74-1.92 0-2.92 1.15-2.92 2.48 0 .62.22 1.71 2.23 2.51l2.08.84c3.82 1.55 4.87 3.72 4.87 6.08 0 3.94-2.79 6.64-6.67 6.64-2.39 0-3.82-.9-4.87-2.08-1.12-1.24-1.61-2.61-1.74-4.03l3.13-.68c0 1.02.37 1.99.87 2.64.59.74 1.46 1.24 2.7 1.24 1.92 0 3.41-1.4 3.41-3.48 0-2.11-1.61-2.98-2.98-3.54l-1.99-.84c-1.71-.71-4.22-2.14-4.22-5.24 0-2.79 2.17-5.46 6.05-5.46 2.23 0 3.51.84 4.16 1.4.56.5 1.15 1.21 1.58 2.08l-2.53 1.48zM43.25 30.89v20.69h-3.16V30.89h3.16zM61.37 46.59h-8.84l-2.26 5h-3.41l10.3-21.9 9.8 21.9h-3.41l-2.18-5zm-1.27-2.98l-3.07-7.01-3.2 7.01h6.27zM70.56 51.59V29.5l15.02 15.73V30.89h3.16v21.97L73.72 37.13v14.46h-3.16zM110.02 35.73c-2.3-2.08-4.44-2.3-5.62-2.3-4.5 0-7.54 3.32-7.54 7.88 0 4.37 3.16 7.76 7.57 7.76 2.48 0 4.41-1.27 5.58-2.39v3.75c-2.08 1.24-4.25 1.55-5.68 1.55-3.75 0-6.11-1.71-7.32-2.85-2.42-2.27-3.32-4.9-3.32-7.82 0-3.82 1.58-6.45 3.32-8.1 2.14-2.02 4.62-2.7 7.48-2.7 1.89 0 3.72.34 5.52 1.49v3.73h.01zM126.34 33.87h-8.25v5.24h8.01v2.98h-8.01v6.52h8.25v2.98h-11.42v-20.7h11.42v2.98z"
              fill="#fff"
            />
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 0h152.91v52.86H0z" />
              </clipPath>
            </defs>
          </svg>
        </router-link>
      </h1>

      <nav>
        <ul>
          <li
            v-on:click="this.$parent.updateSub"
            :attr-sub="$t('case_studies')"
          >
            <router-link :to="{ name: 'stories' }">work</router-link>
            <span></span>
          </li>
          <li
            v-on:click="this.$parent.updateSub"
            :attr-sub="$t('our_services')"
          >
            <router-link :to="{ name: 'what_we_do' }">services</router-link>
            <span></span>
          </li>
          <li
            v-on:click="this.$parent.updateSub"
            :attr-sub="$t('agency')"
            class="hasSub"
          >
            solutions
            <span></span>
            <ul class="subnav">
              <li>
                <a href="https://www.kpible.com" target="_blank">KPIBLE</a
                ><span></span>
              </li>
              <li>
                <a href="https://academy.asiance.com" target="_blank"
                  >Asiance Academy</a
                ><span></span>
              </li>
              <li>
                <a
                  :href="
                    this.$i18n.locale == 'ko'
                      ? 'https://lets-tako.com/ko'
                      : 'https://lets-tako.com'
                  "
                  target="_blank"
                  >TAKO</a
                ><span></span>
              </li>
            </ul>
          </li>
          <li v-on:click="this.$parent.updateSub" :attr-sub="$t('follow_us')">
            <a
              v-if="this.$i18n.locale == 'ko'"
              href="https://asiance.tistory.com/"
              target="_blank"
              >insights</a
            >
            <a v-else href="https://blog.asiance.com" target="_blank"
              >insights</a
            >
            <span></span>
          </li>
          <li v-on:click="this.$parent.updateSub" :attr-sub="$t('careers')">
            <router-link :to="{ name: 'join_us' }">careers</router-link>
            <span></span>
          </li>
          <li v-on:click="this.$parent.updateSub" :attr-sub="$t('agency')">
            <router-link :to="{ name: 'who_we_are' }">about</router-link>
            <span></span>
          </li>
          <li
            v-on:click="this.$parent.updateSub"
            class="contact"
            :attr-sub="$t('contact')"
          >
            <router-link :to="{ name: 'contact' }">contact</router-link>
            <span></span>
          </li>
          <li v-on:click="this.$parent.switchlang" attr-lang="en" class="lang">
            EN<span></span>
          </li>
          <li v-on:click="this.$parent.switchlang" attr-lang="ja" class="lang">
            日本語<span></span>
          </li>
          <li v-on:click="this.$parent.switchlang" attr-lang="ko" class="lang">
            한국어<span></span>
          </li>
        </ul>
      </nav>

      <div class="burger">
        <svg
          width="45"
          height="45"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="Canvas" transform="translate(37463 -5869)">
            <use
              xlink:href="#burger0_fill"
              transform="translate(-37459 5869)"
              fill="#FFF"
              id="Ellipse 2"
              filter="url(#filter0_d)"
            />
            <use
              xlink:href="#burger1_fill"
              transform="translate(-37449 5881)"
              fill="#E43852"
              id="Vector"
            />
            <use
              xlink:href="#burger2_fill"
              transform="translate(-37449 5887)"
              fill="#E43852"
              id="Vector"
            />
            <use
              xlink:href="#burger2_fill"
              transform="translate(-37449 5893)"
              fill="#E43852"
              id="Vector"
            />
          </g>
          <defs>
            <filter
              id="filter0_d"
              filterUnits="userSpaceOnUse"
              x="-37463"
              y="5869"
              width="45"
              height="45"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <path
              id="burger0_fill"
              d="M37 18.5C37 28.717 28.717 37 18.5 37S0 28.717 0 18.5 8.283 0 18.5 0 37 8.283 37 18.5z"
            />
            <path
              id="burger1_fill"
              d="M.425 1.932C.19 1.932 0 1.499 0 .965 0 .432.19 0 .425 0h16.15c.235 0 .425.432.425.965 0 .534-.19.967-.424.967H.425z"
            />
            <path
              id="burger2_fill"
              d="M.425 1.932C.19 1.932 0 1.499 0 .965 0 .432.19 0 .425 0h16.15c.235 0 .425.432.425.965 0 .534-.19.967-.424.967H.425z"
            />
          </defs>
        </svg>
      </div>
    </header>

    <div class="mobile-menu">
      <div class="close">
        <svg
          width="34"
          height="34"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="close-menu"
        >
          <use xlink:href="#close0_fill" fill="#FFF" />
          <defs>
            <path
              id="close0_fill"
              d="M.051 33.932C.119 34 .187 34 .323 34s.204 0 .272-.068l16.38-16.44 16.38 16.372c.067.068.135.068.271.068s.204 0 .272-.068a.328.328 0 0 0 0-.475L17.45 17.017 33.83.645a.328.328 0 0 0 0-.475.329.329 0 0 0-.476 0l-16.38 16.372L.595.101a.329.329 0 0 0-.475 0 .328.328 0 0 0 0 .475l16.38 16.44L.05 33.389a.75.75 0 0 0 0 .543z"
            />
          </defs>
        </svg>
      </div>
      <ul>
        <li><router-link :to="{ name: 'stories' }">work</router-link></li>
        <li>
          <router-link :to="{ name: 'what_we_do' }">services</router-link>
        </li>
        <li>
          solutions
          <ul class="subnav">
            <li>
              <a href="https://www.kpible.com" target="_blank">KPIBLE</a
              ><span></span>
            </li>
            <li>
              <a href="https://academy.asiance.com" target="_blank"
                >Asiance Academy</a
              ><span></span>
            </li>
            <li>
              <a
                :href="
                  this.$i18n.locale == 'ko'
                    ? 'https://lets-tako.com/ko'
                    : 'https://lets-tako.com'
                "
                target="_blank"
                >TAKO</a
              ><span></span>
            </li>
          </ul>
        </li>
        <li>
          <a
            v-if="this.$i18n.locale == 'ko'"
            href="https://asiance.tistory.com/"
            target="_blank"
            >insights</a
          >
          <a v-else href="https://blog.asiance.com" target="_blank">insights</a>
        </li>
        <li><router-link :to="{ name: 'join_us' }">careers</router-link></li>
        <li><router-link :to="{ name: 'who_we_are' }">about</router-link></li>
        <li><router-link :to="{ name: 'contact' }">contact</router-link></li>
      </ul>

      <ul class="language-mobile">
        <li v-on:click="this.$parent.switchlang" attr-lang="en" class="lang">
          EN
        </li>
        <li v-on:click="this.$parent.switchlang" attr-lang="ja" class="lang">
          日本語
        </li>
        <li v-on:click="this.$parent.switchlang" attr-lang="ko" class="lang">
          한국어
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PagesService from '../services/PagesService';
import GlobalService from '../services/GlobalService';

export default {
  props: ['subtitleProp'],
  data() {
    return {
      items: [],
      infoData: [],
      mobileNavigation: false,
    };
  },
  created() {
    this.fetchItems();
    this.fetchInfoData();

    if (this.$i18n.locale == 'ja') {
      $('body').css('word-break', 'initial');
    }
  },

  methods: {
    isActive: function (menuItem) {
      return this.activeItem === menuItem;
    },
    setActive: function (menuItem) {
      this.activeItem = menuItem;
    },

    fetchItems() {
      return PagesService.get().then((result) => {
        this.items = result.data;
      });
    },
    fetchInfoData() {
      return GlobalService.get().then((result) => {
        this.infoData = result.data;
      });
    },
    toggleNavigation() {
      this.mobileNavigation = !this.mobileNavigation;
    },
  },

  mounted() {
    $('.burger').on('click', function () {
      $('.mobile-menu').addClass('active');
      $('html').addClass('mobile-menu');
      $(this).addClass('inactive');
      // $(".subtitle:first").hide();
      $('header').removeClass('scroll');
      $('header').addClass('white');
    });

    $('.mobile-menu a, header h1').on('click', function () {
      $('html').removeClass('mobile-menu');
      $('.mobile-menu').removeClass('active');
      $('.burger').removeClass('inactive');
      //$(".subtitle:first").show();
      $('header').removeClass('white');
    });

    $('.mobile-menu > ul > li').on('click', function () {
      $(this).find('.subnav').toggleClass('open');
    });

    $('nav, .subnav').on('mouseover', function (e) {
      e.stopPropagation();
    });
    $('nav > ul > li').on('mouseenter', function () {
      $('.subnav').removeClass('open');
      $(this).find('.subnav').addClass('open');
    });
    $('html, body').on('mouseover', function () {
      $('.subnav').removeClass('open');
    });

    $("li[attr-lang='" + this.$i18n.locale + "']").addClass('active');

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('header').first().addClass('scroll');
      } else {
        $('header').first().removeClass('scroll');
      }
    });
  },
};
</script>

<style lang="stylus" scoped>


.mobile-menu {
  display: none;
}

header {
  position: fixed;
  z-index: 999;
  padding: 0 30px;
  display: flex;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.22, 0.44, 0, 1);

  .burger {
    display: none;
  }

  h1 {
    margin-top: 25px;
    order: 1;
    flex: 1;
    flex-basis: auto;
    padding-left: 60px;
    margin: 10px 0;
    position: relative;
    transform: scale(0.9);

    &:hover {
      #windmill {
        transform-origin: center;
        animation: spin 1600ms ease-in-out;
      }
    }

    #windmill {
      position: absolute;
      top: 0;
      left: 185px;

      use {
        fill: #E43852;
      }
    }
  }

  nav {
    order: 1;
    margin-top: 40px;
    transition: all 0.5s cubic-bezier(0.22, 0.44, 0, 1);

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      > li {
        margin-right: calc(50vw / 19.2);
        cursor: pointer;
        text-align: center;
        position: relative;


        &:last-child {
          position: relative;
          cursor: pointer;
        }
        &.hasSub:hover {
          > span {
            width: 100%;
          }
        }
        a:not(.router-link-active):hover {
          + span {
            width: 100%;
          }
        }

        svg {
          margin-top: 7px;
          margin-left: 5px;
          float: right;
        }
      }

      span {
        display: block;
        width: 0;
        margin: 2px auto auto auto;
        border-bottom: 1px solid #fff;
        transition: width 0.3s cubic-bezier(0.22, 0.44, 0, 1);
      }

      li, a {
        color: #FFF;
        font-family: futura-pt;
        line-height: 21px;
        font-size: 14px;
        letter-spacing: 0.1em;
        padding-bottom: 5px;
        text-transform: uppercase;
        box-sizing: border-box;
        transition: all 0.3s linear;
      }

      a.router-link-active {
        border-bottom: 1px solid;
      }
    }

    .contact {
      margin-right: 38px;
    }

    .lang {
      margin-right: 12px;
      padding-bottom: 0;
      height: 24px;

      &.active {
        border-bottom: 1px solid;
      }
    }
  }
  .subnav{
    list-style: none;
    padding: 0;
    position: absolute;
    left: 0;
    margin-top: 13px;
    text-align: left;
    overflow: hidden;
    height: 0;
    &:before{
      content: '';
      position: fixed;
      left: 0;
      width: 100vw;
      z-index: -1;
      height: 0;
      overflow: hidden;
    }
    &.open{
      transition: all 0.5s cubic-bezier(0.22, 0.44, 0, 1);
      height: 120px;
      &:before{
        /* height: 90px; */
        height: 120px;
        transition: all 0.5s cubic-bezier(0.22, 0.44, 0, 1);
      }
    }
    li{
      text-align: left;
      display: inline-block;
      margin-top: 10px;
      white-space: nowrap;
      &:hover {
        > span {
          width: 100%;
        }
      }
    }
  }
}
.scroll{
  .subnav{
    &.open{
      &:before{
        background: white;
      }
    }
  }
}

.black {
  svg {
    #logo_asiance {
      fill: #000;
    }
  }

  li {
    color: #000;

    span {
      border-bottom: 1px solid #000;
    }

    a, span {
      color: #000;
    }

    svg {
      use {
        fill: #000;
      }
    }
  }
}

.black-white {
  svg {
    #logo_asiance {
      fill: #000;
    }
  }

  li {
    color: #fff;

    span {
      border-bottom: 1px solid #fff;
    }

    a, span {
      color: #fff;
    }

    svg {
      use {
        fill: #fff;
      }
    }
  }
}

.white {
  svg {
    g {
      use {
        fill: #fff;
        transition: fill 0.3s linear;
      }
    }
  }

  .burger {
    svg {
      g {
        use {
          fill: #E43852;
        }
      }
    }
  }
}

.white-black {
  svg {
    g {
      use {
        fill: #fff;
        transition: fill 0.3s linear;
      }
    }
  }

  li {
    color: #000;
    a, span {
      color: #000;
    }

    svg {
      use {
        fill: #000;
      }
    }
  }
}

.fixed {
  position: fixed;
}

/deep/ .subtitle {
  z-index: 10;
  position: absolute;
  color: #000;
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 53%;
  position: fixed;
  top: 240px;
  left: -10px;
  min-width: 200px;
  text-align: right;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  transition: color 0.5s linear;

  span {
    display: inline-block;
    width: 47px;
    height: 1px;
    background-size: 1px;
    background-color: #000;
    margin-top: 6px;
    margin-left: 10px;
    float: right;
  }

  &.white {
    color: #fff;

    span {
      background-color: #fff;
    }
  }
}

.ja {
  .subtitle {
    font-size: 14px;
    min-width: 276px;
    left: -51px;
    top: 272px;

    span {
      margin-top: 9px;
    }
  }
}

.ko {
  .subtitle {
    font-size: 14px;

    span {
      margin-top: 9px;
    }
  }
}

.scroll {
  background: #fff;

  svg {
    #logo_asiance {
      fill: #000;
    }
  }

  .burger {
    svg {
      g {
        use {
          fill: #E43852;
        }
      }
    }
  }

  ul {
    li {
      color: #000 !important;

      span {
        border-color: #000;
      }

      a, span {
        color: #000 !important;
      }

      svg {
        use {
          fill: #000;
        }
      }
    }
  }
}

@keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    transform-origin: center;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform-origin: center;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform-origin: center;
  }
}

@media only screen and (max-width: 1024px) {
  header {
    .burger {
      display: block;
      background: white;
      height: 37px;
      width: 37px;
      border-radius: 50%;
      position: absolute;
      top: 15px;
      right: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.22, 0.44, 0, 1);

      &.inactive {
        display: none;
      }

      svg {
        margin-left: -4px;
      }
    }

    h1 {
      padding-left: 0;
      margin-top: calc(8vw / 3.2);
      margin-left: calc(-1vw / 3.2);

      &:hover {
        #windmill {
          animation: none;
        }
      }

      #windmill {
        top: 5px;
        left: 100px;
      }

      svg {
        transform: scale(0.65) translateX(-54px) translateY(-16px);
      }
    }

    nav {
      display: none;
    }
  }

  .mobile-menu {
    transform: translateX(-100%);
    display: block;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background-image: url('../../public/img/bgd_menu_mobile.jpg');
    background-size: cover;
    background-position: center center;
    transition: all 0.3s cubic-bezier(0.22, 0.44, 0, 1);

    .close {
      position: absolute;
      top: 30px;
      right: 27px;
      z-index: 9999;
    }

    &.active {
      transform: translateX(0);
      opacity: 1;
    }

   > ul {
      list-style: none;
      margin: 100px 0 0 20px;
      padding: 0;

      li {
        font-family: futura-pt, 'Noto Sans KR';
        text-transform: uppercase;
        line-height: 45px;
        font-size: 24px;
        letter-spacing: 0.2em;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }
    .subnav{
      list-style: none;
      padding: 0.5em 1em 1em;
      display: none;
      &.open{
        display: block;
      }
      li{
        font-size: 20px;
      }
    }

    .language-mobile {
      position: absolute;
      bottom: 35px;
      display: flex;

      li {
        font-size: 18px;
        margin-right: 20px;

        &.active {
          border-bottom: 1px solid;
        }
      }
    }
  }

  .subtitle {
    left: -75px;
  }

  .scroll {
    height: 70px;
  }
}
</style>
