<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{
            'background-image': 'url(' + this.acf.hero.background_image + ')',
          }"
        >
          <hgroup>
            <h1>{{ this.item.title.rendered }}</h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4
              v-html="this.acf.introduction.short_description"
              v-scroll-reveal
            ></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <div class="highlight">
            <div class="entry">
              <img :src="this.acf.poster[0].image" alt v-scroll-reveal />
            </div>
            <div class="wrapper">
              <div class="entry">
                <img :src="this.acf.poster[1].image" alt v-scroll-reveal />
              </div>
              <div class="entry">
                <img :src="this.acf.poster[2].image" alt v-scroll-reveal />
              </div>
            </div>
          </div>

          <div class="sub-highlight">
            <div class="entry">
              <div class="visual">
                <img :src="this.acf.screen.image_1" alt v-scroll-reveal />
              </div>              
              <p v-html="this.acf.screen.content_1" v-scroll-reveal></p>
            </div>
            <div class="entry">
              <p v-html="this.acf.screen.content_2" v-scroll-reveal></p>
              <div class="visual">
                <img :src="this.acf.screen.image_2" alt v-scroll-reveal />
              </div>
            </div>
          </div>
          <div class="main-visual">
            <img :src="this.acf.footer.image" alt v-scroll-reveal />
            <p v-html="this.acf.footer.text" v-scroll-reveal />
          </div>
        </section>
      </div>
    </div>
    <project-navigator
      ref="projectNavigator"
      :projectId="this.item.id"
      v-if="this.item.id"
    ></project-navigator>
  </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, "tumi")
        .then((result) => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
            $(".hero, [data-paroller-factor]").paroller({
              factor: 0.3,
              type: "background",
              direction: "vertical",
            });
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },

  mounted: function () {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header").addClass("white");

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".highlight .entry:first, .sub-highlight .visual").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });
  },
};
</script>

<style lang="stylus" scoped>


.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-image: url('../../public/img/cases/finair/bgd_case_finair.jpg');
  justify-content: center;
  text-align: center;

  hgroup {
    color: #fff;
    max-width: 950px;
  }

  h1 {
    margin: 0 0 40px 0;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h2 {
    font-family: futura-pt;
    font-size: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    margin: 100px 0 0 0;
    line-height: 52px;
    font-size: 40px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .separator {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 0;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      height: 1px;
      width: 29px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      max-width: 577px;
      font-size: 18px;
      line-height: 23px;
      margin: 32px auto 0 auto;
    }
  }
}

.highlight {
  position: relative;

  > .entry {
    width: calc((1278vw / 14.4));

    &:nth-child(1) {
      transform: translateX(-100%);
      transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 0;

      &.active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .wrapper {
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

    .entry {
      height: calc((435vw / 14.4));
      display: flex;
      align-items: flex-start;

      &:nth-child(1) {
        margin-right: calc((48vw / 14.4));
      }
    }
  }
}

.sub-highlight {
  margin-top: 83px;
  width: 100%;
  padding: 0 calc((78vw / 14.4));
  .entry {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:nth-child(1), p {

      .visual {
        transform: translateX(-100%);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;
        width: calc((741vw / 14.4));
        margin-right: calc((18vw / 14.4));

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    &:nth-child(1){
      p{
        margin-right: calc((70vw / 14.4));
        max-width: 447px;
      }
    }

    &:nth-child(2) {
      p{
        max-width: 457px;
      }
      .visual {
        width: calc((576vw / 14.4));
        max-width: 576px;
        transform: translateX(100%);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;


        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
  }

  .visual {
    width: 100%;
  }
}

.main-desc {
  font-family: futura-pt;
  margin: 34px auto;
  padding: 0 25px;
  max-width: 1190px;
  line-height: 52px;
  font-size: 40px;
  text-align: center;
  color: #2A3642;
}


.main-visual {
  z-index: -1;
  position: relative;
  p{
      position: absolute;
      top: calc((240vw / 14.4));
      right: calc((177vw / 14.4));
      color: #fff;
      font-size: 22px;
      line-height: 29px;
      width: calc((454vw / 14.4));
      max-width: 454px;
      font-weight: 300;
  }
}


@media only screen and (max-width: 1024px) {
  .hero {
    height: 385px;
    position: relative;
    align-items: flex-end;

    hgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;
      margin-bottom: 38px;

      h1 {
        line-height: 64px;
        font-size: 50px;
        margin-bottom: 0;
      }

      h2 {
        font-family: futura-pt, 'Noto Sans KR';
        margin-top: 15px;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        text-transform: lowercase;
        letter-spacing: 0;
        text-shadow: none;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      h3 {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: normal;
        text-shadow: none;
      }
    }
  }

  .intro {
    div {
      max-width: none;
      margin: auto;
      padding: 45px 20px 0;

      h3 {
        font-size: 12px;
      }

      h4 {
        font-size: 20px;
        margin-top: 18px;
      }

      p {
        margin-bottom: 75px;
      }
    }
  }

  .highlight {
    height: auto;

    .entry {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }

    .wrapper {
      display: block;
      margin: 0;
      margin-top: 0;

      .entry {
        padding: 0 30px;
        height: auto;
        width: 100% !important;
        margin: auto !important;
        margin-bottom: 30px !important;

        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .sub-highlight {
    text-align: center;
    padding: 0;
    margin-top: 0;

    .entry {
      margin: auto !important;
      margin-bottom: 55px;
      flex-direction: column;
      justify-content: center;

      &:nth-child(1) {
        p{
          order: 1;
          margin-right: auto;
        }
        .visual{
          order: 2;       
          margin: 40px 0;
        }
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          order: 1;
        }

        .visual {
          justify-content: flex-end;
          order: 2;
        }
      }

      p {
        font-size: 18px;
        margin: 30px auto;
        width: 100%;
        padding: 0 30px;
        max-width: 426px !important;
      }

      .visual {
        height: auto !important;
        width: 100% !important;
        max-width: 320px;   
      }
    }
  }

  .main-visual {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
        img {
            object-fit: cover;
            height: 255px;
            width: auto;
            order: 2;
        }
        p{
            order: 1;
            position: static;
            color: inherit;
            font-size: 18px;
            line-height: 23px;
            width: auto;
            max-width: 426px;
            text-align: center;
            margin: 20px auto 40px;
            padding: 0 20px;
        }
    }

  .main-desc {
    padding: 0;
    margin-top: 0;
    max-width: 268px;
    line-height: 28px;
    font-size: 22px;
    text-align: center;
  }  
}
</style>
<style lang="stylus">
br.mobile-only{
  content:" ";
}
br.web-only{
    content: none;
}
@media only screen and (max-width: 30rem){
    br.mobile-only{
      content: none;
    }
    br.web-only{
      content:" ";
    }
}
</style>
