<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{ 'background-image': 'url(' + this.acf.hero.background_image + ')' }"
        >
          <hgroup>
            <h1>{{ this.item.title.rendered }}</h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4 v-html="this.acf.introduction.short_description" v-scroll-reveal></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <section class="highlight">
            <div class="entry" v-for="(poster, key) in this.acf.posters" :key="key">
              <img :src="poster.image" alt v-scroll-reveal />
            </div>
          </section>

          <div class="sub-highlight">
            <div class="entry">
              <p v-html="this.acf.screen.content_1" v-scroll-reveal></p>
              <div class="visual">
                <img src="/wp-content/themes/asiance/dist/img/cases/pfizer/sub_highlight_01.jpg" alt />
              </div>
            </div>
            <div class="entry">
              <div class="visual">
                <img :src="this.acf.screen.image" alt v-scroll-reveal />
              </div>
              <p v-html="this.acf.screen.content_2" v-scroll-reveal></p>
            </div>
          </div>

          <div class="screen-thumbs">
            <div class="wrapper">
              <div class="thumb" v-for="thumbnails in this.acf.thumbnails" :key="thumbnails.image">
                <img :src="thumbnails.image" alt v-scroll-reveal />
              </div>
            </div>
          </div>

          <p class="main-desc" v-html="this.acf.pre_footer" v-scroll-reveal></p>
        </section>

        <section class="award">
          <img src="/wp-content/themes/asiance/dist/img/cases/award.png" alt />
          <hgroup>
            <h3>{{ this.acf.footer.award_title }}</h3>
            <h4>
              <p v-for="awards in this.acf.footer.awards" :key="awards.award">
                {{ awards.award }}
              </p>
            </h4>
          </hgroup>
        </section>
      </div>
    </div>
    <project-navigator ref="projectNavigator" :projectId="this.item.id" v-if="this.item.id"></project-navigator>
  </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {}
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    projectNavigator: function(id) {
      this.$refs.projectNavigator.projectId = id;
    },

    fetchItem(lang) {
      return PostSlugService.get(lang, "pfizer")
        .then(result => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
            $(".hero").paroller({
              factor: 0.3,
              type: "background",
              direction: "vertical"
            });
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    }
  },

  mounted: function() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header").addClass("white");

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".sub-highlight .entry:first .visual").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });

    setTimeout(() => {
      $(".screen-thumbs .thumbs").paroller({
        factor: 0.1,
        type: "foreground",
        direction: "vertical"
      });
    }, 1000);
  }
};
</script>

<style lang="stylus" scoped>


.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-image: url('../../public/img/cases/pfizer/bgd_case_pfizer.jpg');
  justify-content: center;
  text-align: center;

  hgroup {
    color: #fff;
    max-width: 950px;
  }

  h1 {
    margin: 0 0 40px 0;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h2 {
    font-family: futura-pt;
    font-size: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    margin: 100px 0 0 0;
    line-height: 52px;
    font-size: 40px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .separator {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 0;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      height: 1px;
      width: 29px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      max-width: 865px;
      font-size: 18px;
      line-height: 23px;
      margin: 32px auto 125px auto;
    }
  }
}

.highlight {
  position: relative;
  height: calc((468vw / 14.4));
  max-height: 468px;
  display: flex;

  .entry {
    max-width: 313px;
  }
}

.sub-highlight {
  margin-top: 130px;
  width: 100%;
  display: flex;
  align-content: center;

  .entry {
    width: 50%;

    &:nth-child(1) {
      p {
        margin-top: 85px;
        margin-bottom: 150px;
      }

      .visual {
        transform: translateX(-100%);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    &:nth-child(2) {
      width: calc((542vw / 14.4));
      max-width: 542px;

      p {
        margin-top: 85px;
      }
    }

    p {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 23px;
      font-size: 18px;
      font-weight: 100;
      max-width: 470px;
      margin: auto;

      span {
        font-family: futura-pt;
      }
    }
  }

  .visual {
    width: 100%;
  }
}

.screen-thumbs {
  margin-top: 110px;

  .wrapper {
    display: flex;
    max-width: calc((1200vw / 14.4));
    margin: auto;
    justify-content: space-between;
  }

  .thumb {
    height: calc((380vw / 14.4));
    width: calc((380vw / 14.4));

    &:nth-child(1) {
      margin-top: calc((-210vw / 14.4));
    }

    &:nth-child(3) {
      margin-top: calc((140vw / 14.4));
    }
  }
}

.main-desc {
  font-family: futura-pt;
  margin: 100px auto auto auto;
  padding: 0 25px;
  max-width: 1190px;
  line-height: 52px;
  font-size: 40px;
  text-align: center;
  color: #2A3642;
}

.footer {
  position: relative;
  margin: 100px 0 0 0;
  background: url('../../public/img/cases/tesla/footer.jpg');
  background-size: cover;
  background-position: bottom;
  height: calc((749vw / 14.4));

  p {
    font-family: futura-pt, 'Noto Sans KR';
    position: absolute;
    max-width: 454px;
    bottom: 208px;
    right: calc((180vw / 14.4));
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    color: #2A3642;
  }
}

.award {
  margin: 205px 0 0 0;
  background: #F9F9F9;
  background-repeat: repeat-x;
  background-position: bottom;
  height: 286px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: -100px;
    height: 360px;
    width: auto;
  }

  hgroup {
    margin-left: 100px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      font-weight: 700;
      line-height: normal;
      font-size: 30px;
      color: #2A3642;
      margin-bottom: 22px;
    }

    h4 {
      font-family: futura-pt, 'Noto Sans KR';
      font-weight: 100;
      line-height: 42px;
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    height: 385px;
    position: relative;
    align-items: flex-end;

    hgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;
      margin-bottom: 38px;

      h1 {
        line-height: 64px;
        font-size: 50px;
        margin-bottom: 0;
      }

      h2 {
        font-family: futura-pt, 'Noto Sans KR';
        margin-top: 15px;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        text-transform: lowercase;
        letter-spacing: 0;
        text-shadow: none;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      h3 {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: normal;
        text-shadow: none;
      }
    }
  }

  .intro {
    div {
      max-width: 282px;
      margin: auto;
      padding-top: 45px;

      h3 {
        font-size: 12px;
      }

      h4 {
        font-size: 20px;
        margin-top: 18px;
      }

      p {
        margin-bottom: 75px;
      }
    }
  }

  .highlight {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    max-height: none;

    .entry {
      height: calc((187.5vw / 3.8));
      width: 50%;
      overflow: hidden;
      margin: 0;

      img {
        margin-top: -25%;
      }
    }

    .wrapper {
      display: block;
      margin: 0;
      margin-top: 10px;

      .entry {
        height: auto;
        width: 100% !important;
        margin: auto !important;
        margin-bottom: 10px !important;
      }
    }
  }

  .sub-highlight {
    text-align: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    display: flex;

    .entry {
      margin: auto !important;
      width: 100% !important;
      margin-bottom: 55px;

      &:nth-child(1) {
        p {
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        display: flex;
        flex-wrap: wrap;

        p {
          margin: 0 auto 80px auto;
        }

        .visual {
          margin: 60px 0;
        }
      }

      p {
        font-size: 18px;
        margin: 80px auto;
        width: 90%;
      }

      .visual {
        height: auto !important;
        width: auto !important;
      }
    }
  }

  .main-visual {
    img {
      height: auto;
      width: 100%;
    }
  }

  .main-desc {
    padding: 0;
    margin-top: 45px;
    max-width: 90%;
    line-height: 28px;
    font-size: 22px;
    text-align: center;
  }

  .screen-thumbs {
    padding: 0;
    margin: auto;
    width: 90%;

    .wrapper {
      flex-wrap: wrap;
      display: block;
      max-width: none;
    }

    .thumb {
      width: 100%;
      margin: 0 0 10px 0 !important;
      height: auto;
    }

    p {
      margin: auto;
      margin-top: 50px;
      width: 272px;
      text-align: center;
    }
  }

  .award {
    background-repeat: no-repeat;
    height: 186px;
    margin-top: 250px;
    flex-direction: column;

    img {
      height: 205px;
      margin-left: calc((-79vw / 3.8));
      margin-top: -210px;
    }

    hgroup {
      margin-left: 0;
      max-width: 95%;
      text-align: center;
      margin-top: 20px;

      h3 {
        font-size: 16px;
        line-height: 21px;
      }

      h4 {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>
