<template>
<div id="privacy">
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content" :attr-sub="this.title">        
        <section class="title">
            <hgroup>            
                <h1 v-html="this.title"></h1>
            </hgroup>
        </section>
        <div class="wrapper">
        <div v-html="this.content"></div>
    </div>
</div>
</div>
</template>

<script>
import PageService from "../services/PageService";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen },

  data() {
    return {
      isLoading: true, 
      title: '',
      content: ''
    };
  },

  created: function() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
      fetchItem(lang) {
      return PageService.get(lang, "privacy-policy")
        .then(result => {
          this.item = result.data[0];
          this.title = this.item.title.rendered;
          this.content = this.item.content.rendered;   
          setTimeout(() => {
            this.isLoading = false;
            }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
  }
}


</script>


<style lang="stylus">


.ja {
  .inquiry {
    font-family: 'Noto Sans JP', sans-serif!important;
  }
}
#privacy{
.title {
  background-image: url('../../public/img/bgd_contact.jpg');
  background-size: cover;
  background-position: center center;
  height: 100vh;
  position: relative;
  display: flex;
  padding-top: calc(200vw/14.4);
  min-height: 700px;

  hgroup {
      margin-left: calc(171vw/14.4);
      max-width: calc(871vw/14.4);
  }

  h1 {
      font-family: futura-pt, 'Noto Sans KR';
      color: #fff;
      font-size: calc(90vw/14.4);
      line-height: calc(116vw/14.4);
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 0;
  }

  h2 {
    font-family: futura-pt, 'Noto Sans KR';
    color: #fff;
    font-size: 40px;
    line-height: 52px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
  }
}
.wrapper{
    padding: calc(63vw / 14.4) calc(133.5vw / 14.4) calc(67vw / 14.4);
    font-weight: 400;
}
h3{
    font-size: 1.17em;
    margin-top: 1rem;
    margin-bottom: 1em;
}
p{
    margin-top: 1em;
    margin-bottom: 2em;
    a{
        color: #2A3642;
        text-decoration: underline;
    }
}
ul, ol{
    margin: 0;
    margin-bottom: 2em;
    padding: 0;
    padding-left: 40px;
    li:not(:last-child){
        padding-bottom: 15px;
    }
}
@media screen and (max-width: 1024px){
    .wrapper{
        padding: calc(37vw / 3.75) 20px calc(49vw / 3.75);
    }
}
}
</style>