<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content" :attr-sub="$t('careers')">
      <div class="wrapper" v-if="this.hero.background">
        <section class="title" :style="{ 'background-image': 'url(' + this.hero.background + ')' }">
          <hgroup>
            <h1>{{ hero.title }}</h1>
          </hgroup>
          <!-- <div class="separator">
                <span></span>
                <span class="black"></span>
          </div>-->
        </section>
        <BannerJoin :onClick="onClick" />

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ introduction.title }}</h3>
            <h4 v-html="introduction.subtitle" v-scroll-reveal></h4>
            <p v-html="introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="jobs-opening" id="jobs">
          <h2>{{ $t("job_opening") }}</h2>
          <div class="jobs">
            <div class="jobs-wrapper">
              <div class="entry" v-for="(job, itemObjKey) in this.jobs" :key="itemObjKey" v-scroll-reveal>
                <a :href="job.acf.link" target="_blank">
                  <h3 v-html="job.title.rendered"></h3>
                  <p v-html="job.description"></p>
                  <span v-if="job.acf.is_new === true">New</span>

                  <svg width="30" height="16" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <use xlink:href="#arrow_fill" transform="matrix(0 -1 -1 0 30 16)" fill="#2A3642" />
                    <defs>
                      <path id="arrow_fill" fill-rule="evenodd"
                        d="M8.324.137a.47.47 0 0 0-.665 0L.146 7.664c-.443.442.222 1.104.665.662l6.386-6.36c.184-.183.333-.125.333.137v27.429c0 .2.128.368.305.435A.407.407 0 0 0 8 30c.26 0 .47-.21.47-.468V2.103c0-.259.147-.322.333-.137l6.386 6.36c.443.442 1.108-.22.665-.662L8.324.137z" />
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>

        <div v-for="(point, itemObjKey) in this.key_points" :key="itemObjKey">
          <section v-bind:class="{
              'expert experience desktop': itemObjKey,
              'expert desktop': !itemObjKey,
            }" :style="{ 'background-image': 'url(' + point.background.url + ')' }">
            <div class="overlay"></div>
            <div class="wrapper">
              <h2 v-html="point.title"></h2>
              <p v-html="point.content"></p>
            </div>
          </section>
          <section v-bind:class="{
              'expert experience mobile': itemObjKey,
              'expert mobile': !itemObjKey,
            }" :style="{
              'background-image':
                'url(' +
                point.background.url
                  .replace('.png', '_mob.png')
                  .replace('.jpg', '_mob.jpg')
                  .replace('.gif', '_mob.gif') +
                ')',
            }">
            <div class="overlay"></div>
            <div class="wrapper">
              <h2 v-html="point.title"></h2>
              <p v-html="point.content"></p>
            </div>
          </section>
        </div>

        <section class="why-asiance">
          <h2>{{ why_asiance.title }}</h2>
          <div class="wrapper">
            <template >
              <div class="entry" v-for="(why, itemObjKey) in this.why_asiance.points" :key="itemObjKey"
               :style="{ 'background-image': 'url(' + why.visual + ')' }"
                v-scroll-reveal>
                <p v-html="why.title"></p>
              </div>
            </template>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import PageService from "../services/PageService";
import JobsService from "../services/JobsService";
import { mapGetters } from "vuex";
import BannerJoin from "../components/BannerJoin.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: {
    LoadingScreen,
    BannerJoin,
  },

  data() {
    return {
      isLoading: true,
      backgroundImage: "",
      hero: {},
      introduction: {},
      key_points: {},
      job_opening: {},
      jobs: {},
      why_asiance: {},
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PageService.get(lang, "join-us")
        .then((result) => {
          this.item = result.data[0];
          this.hero = this.item.acf.hero;
          this.introduction = this.item.acf.introduction;
          this.key_points = this.item.acf.introduction.key_points;
          this.job_opening = this.item.acf.job_opening;
          this.why_asiance = this.item.acf.why_asiance;

          Promise.all(
            this.job_opening.map((job) =>
              JobsService.get(job.ID).then((result) => {
                return result.body;
              })
            )
          ).then((data) => {
            this.jobs = data;
            setTimeout(() => {
              this.$parent.updateSub();
              this.isLoading = false;
              $(".title").paroller({
                factor: 0.3,
                type: "background",
                direction: "vertical",
              });
            }, 500);
          });
        })
        .catch(() => {
          this.error = true;
        });
    },
    onClick: function () {
      this.$ga.event("Banner", "click", "joinus_banner_notion_blog");
    },
  },

  computed: {
    ...mapGetters({
      items: "allPosts",
      filteredItems: "filteredPosts",
    }),
  },

  mounted: function () {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").show();
    $(".subtitle:first").addClass("white");

    $("header").removeClass();
    $("header").removeClass("black");
    $("header:first").addClass("fixed");

    setTimeout(() => {
      $(".jobs-view-all").on("click", function (e) {
        e.preventDefault();
        $(".jobs-wrapper").addClass("open");
        $(".jobs-view-all").hide();
      });
    }, 2000);

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".expert").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });
  },
};
</script>

<style lang="stylus" scoped>


.subtitle {
  z-index: 999;
  position: absolute;
  color: #fff;
  transform: rotate(-90deg);
  position: fixed;
  top: 180px;
  left: 5px;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.3em;
  text-transform: uppercase;

  span {
    display: inline-block;
    width: 47px;
    height: 1px;
    background-size: 1px;
    background-color: #fff;
    margin-top: 6px;
    margin-left: 10px;
    float: right;
  }
}

.title {
  background-image: url('../../public/img/bgd_join_us.jpg');
  background-size: cover;
  background-position: center center;
  height: 100vh;
  position: relative;
  display: flex;
  padding-top: calc((200vw / 14.4));
  min-height: 700px;

  hgroup {
    margin-left: calc((171vw / 14.4));
    max-width: calc((871vw / 14.4));
  }

  h1 {
    font-family: futura-pt, 'Noto Sans KR';
    color: #fff;
    font-size: calc((90vw / 14.4));
    line-height: calc((116vw / 14.4));
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
  }

  h2 {
    font-family: futura-pt, 'Noto Sans KR';
    color: #fff;
    font-size: 40px;
    line-height: 52px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
  }

  .separator {
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      position: relative;
      top: 30px;
      width: 1px;
      height: 30px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      font-family: futura-pt, 'Noto Sans KR';
      max-width: 577px;
      font-size: 18px;
      line-height: 23px;
      margin: 40px auto 110px auto;
      font-weight: 100;
      @media screen and (max-width : 320px){
        font-size 17px;
      }
    }
  }
}

.expert {
  background-image: url('../../public/img/bgd_challange.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: calc((1320vw / 14.4));
  height: calc((780vw / 14.4));
  // min-height: 780px;
  max-height: 782px;
  // max-width: 1320px;
  padding-top: calc((320vw / 14.4));
  padding-left: calc((257vw / 14.4));
  margin-bottom: 125px;
  transform: translateX(-100%);
  transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;

  &.active {
    transform: translateX(0);
    opacity: 1;

    .wrapper {
      opacity: 1;
    }
  }

  &.mobile {
    display: none;
  }

  .wrapper {
    opacity: 0;
    transition: opacity 900ms linear;
    transition-delay: 0.5s;
  }

  h2 {
    font-family: futura-pt;
    color: #FFF;
    line-height: normal;
    font-size: 45px;
    max-width: 495px;
    margin-bottom: 50px;
    font-weight: normal;
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    color: #FFF;
    line-height: 23px;
    font-size: 18px;
    max-width: 468px;
    font-weight: 100;
  }
}

@media only screen and (min-width: 1930px) {
  .expert {
    padding-top: calc((225vw / 14.4));
  }
}

.experience {
  position: relative;
  background-image: url('../../public/img/bgd_team.jpg');
  float: right;
  transform: translateX(100%);

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .wrapper {
    position: absolute;
    right: 0;
    margin-right: calc((65vw / 14.4));
    z-index: 2;
  }

  h2 {
    max-width: inherit;
  }

  p {
    max-width: 468px;
  }
}

.jobs-opening {
  clear: both;
  text-align: center;

  h2 {
    font-family: futura-pt;
    font-size: 20px;
    line-height: 23px;
    color: #2A3642;
    margin-bottom: 83px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }

  .jobs-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    transition: all 0.3s ease-in-out;

    &.open {
      max-height: 1000px;
    }
  }

  .jobs {
    width: calc((1200vw / 14.4));
    margin: auto;
  }

  .jobs-view-all {
    font-family: futura-pt;
    float: right;
    font-size: 12px;
    text-align: right;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #2A3642;
    margin-top: 63px;

    svg {
      float: right;
      margin-top: -10px;
      margin-left: 10px;
    }
  }

  .entry {
    position: relative;
    font-family: futura-pt;
    text-align: left;
    width: calc((570vw / 14.4));
    // padding: 40px 70px 40px 35px;
    background: #F2F2F2;
    transition: background 0.3s cubic-bezier(0.22, 0.44, 0, 1);
    margin-bottom: 38px;
    cursor: pointer;

    a {
      padding: 40px 70px 40px 35px;
      display: block;
      position: relative;
      display: flex;

      span {
        font-family: futura-pt;
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;
        color: #e33a55;
        position: absolute;
        right: 65px;
        text-transform: uppercase;
      }
    }

    &:hover {
      background-color: #C43E51;

      h3, a, span {
        color: #fff;
      }

      svg {
        transform: translateX(10px);

        use {
          fill: #fff;
        }
      }
    }

    a {
      color: #000000;
      transition: color 0.3s cubic-bezier(0.22, 0.44, 0, 1);
    }

    h3 {
      font-size: 16px;
      transition: color 0.3s cubic-bezier(0.22, 0.44, 0, 1);
      // margin-bottom: 12px
    }

    p {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 21px;
      font-size: 16px;
      font-weight: 100;
      max-width: 377px;
      display: none;
    }

    svg {
      position: absolute;
      right: 25px;
      top: 40%;
      transition: transform 0.3s cubic-bezier(0.22, 0.44, 0, 1);

      use {
        transition: fill 0.3s linear;
      }
    }
  }

  .view-more {
    width: 100%;
    text-align: right;
    line-height: 32px;
    vertical-align: middle;
    display: flex;
    justify-content: flex-end;

    a {
      font-family: futura-pt;
      color: #2A3642;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-size: 12px;
    }

    svg {
      margin-left: 20px;
      float: right;
    }
  }

  .visual {
    margin-top: 135px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
      width: calc((690vw / 14.4));
      height: calc((478vw / 14.4));
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      transform: translateX(-100%);
      transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 0;

      &.active {
        transform: translateX(0) !important;
        opacity: 1;
      }

      &:nth-child(1) {
        background-image: url('../../public/img/job_visual_left.jpg');
      }

      &:nth-child(2) {
        margin-top: 65px;
        background-image: url('../../public/img/job_visual_right.jpg');
        transform: translateX(100%);
        margin-bottom: calc((150vw / 14.4));
      }
    }
  }
}

.why-asiance {
  text-align: center;
  margin-top: 135px;
  margin-bottom: 40px;
  clear: both;

  h2 {
    font-family: futura-pt;
    font-size: 20px;
    line-height: 23px;
    color: #2A3642;
    margin-bottom: 83px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    width: calc((1200vw / 14.4));
    margin: auto;

    /deep/ .entry {
      display: flex;
      min-width: 50%;
      height: calc((300vw / 14.4));
      background: #C43E51;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: calc((220vw / 14.4));

      p {
        font-family: futura-pt;
        text-transform: uppercase;
        color: #fff;
        font-size: 30px;
        line-height: 35px;
      }

      span {
        font-family: futura-pt, 'Noto Sans KR';
        font-weight: 700;
        display: block;
      }

      &:nth-child(3n) {
        background-color: #FFF;

        p {
          color: #2A3642;
        }
      }

      &:nth-child(2) {
        background-color: #FFF;

        p {
          color: #2A3642;
        }
      }
    }

    .events {
      background-image: url('../../public/img/svg/bgd_events.svg');
    }

    .work {
      background-image: url('../../public/img/svg/bgd_work.svg');
    }

    .award {
      background-image: url('../../public/img/svg/bgd_award.svg');
    }

    .clients {
      background-image: url('../../public/img/svg/bgd_client.svg');
    }

    .asia {
      background-image: url('../../public/img/svg/bgd_asia.svg');
    }

    .multicultural {
      background-image: url('../../public/img/svg/bgd_multicultural.svg');
    }
  }
}

@media only screen and (max-width: 1024px) {
  .subtitle {
    left: -40px;
    top: 170px;
  }

  .title {
    min-height: auto;
    height: 50vh;
    padding-top: calc((100vw / 3.2));

    hgroup {
      left: 70px;
      top: 130px;

      h1 {
        font-size: 30px;
        line-height: normal;
      }

      h2 {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    div {
      h3 {
        font-size: 12px;
        line-height: 18px;
        max-width: 246px;
      }

      p {
        font-size: 12px;
        margin: 20px auto;
        max-width: 330px;
      }
    }
  }

  .intro {
    max-width: 90%;
    margin: auto;

    div {
      h3 {
        font-size: 12px;
        line-height: 18px;
        max-width: 246px;
      }

      h4 {
        font-size: 20px;
      }

      p {
        margin-bottom: 77px;
        max-width: 330px;
      }
    }
  }

  .expert {
    height: calc(100vw * 1.123);
    min-height: 350px;
    width: 100%;
    padding: 50px 33px 60px 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 40px;
    background-position: 50% 50%;

    .wrapper {
      position: initial;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    p {
      font-size: 18px;
    }

    &.desktop {
      display: none;
    }

    &.mobile {
      display: flex;
    }
  }

  .experience {
    background-position: center;
  }

  .jobs-opening {
    padding-top: 0;

    h2 {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .jobs {
      .entry {
        width: 90%;
        padding: 20px;
        margin-left: auto;
        margin-right: auto;

        h3 {
          font-size: calc((13vw / 3.2));
        }

        svg {
          right: -13px;
        }

        a {
          color: #000000 !important;
          padding: 0;

          span {
            display: none;
          }
        }
      }

      .view-more {
        justify-content: center;

        a {
          position: relative;
          padding-top: 40px;
        }

        svg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .jobs-view-all {
      margin-top: 40px;
      float: none;
      display: flex;
      margin: auto;
      text-align: center;
      align-items: center;
      align-content: center;
      flex-direction: column-reverse;

      svg {
        margin-bottom: 10px;
      }
    }

    .visual {
      display: none;
    }

    .entry {
      &:hover {
        svg {
          transform: translateX(0px);
        }
      }
    }
  }

  .why-asiance {
    margin-top: 40px;
    margin-bottom: 0;

    h2 {
      font-size: 12px;
      margin-bottom: 15px;
    }

    .wrapper {
      width: 100%;

      /deep/ .entry {
        height: calc((200vw / 3.7));
        width: 50%;
        background-size: 65%;

        p {
          font-size: 14px;
          line-height: normal;
        }
      }
    }
  }

  .ja, .ko {
    .jobs-opening, .why-asiance {
      h2 {
        font-size: 16px;
      }
    }
  }
}
</style>
