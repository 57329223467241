<template>
  <div class="insightreport">
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div
      id="content"
      :attr-sub="$t('insightreport')"
      :class="this.$i18n.locale"
    >
      <section class="insight-title">
        <hgroup>
          <h1 v-html="this.title"></h1>
        </hgroup>

        <div class="bannerlinks" v-if="this.banner">
          <div
            v-for="banner in this.banner"
            :key="banner"
            :id="banner.id"
            class="report"
          >
            <p class="report-title">{{ banner.title }}</p>
            <p class="report-description">{{ banner.description }}</p>
            <p
              class="report-low-link"
              :id="banner.id"
              @click="
                scroll(banner.id + '-info');
                bannerclicked(banner.id);
              "
            ></p>
          </div>
        </div>
      </section>

      <section class="report-info" id="banner-covid-info">
        <div class="container">
          <h2 v-html="this.covid_report_info.title"></h2>
          <div class="report-key-wrap covid-wrap">
            <div
              class="report-key-features"
              v-html="this.covid_report_info.key_features"
            ></div>
          </div>
          <div
            class="report-more-description"
            v-html="this.covid_report_info.description"
          ></div>
          <div class="blog">
            <a :href="this.covid_report_info.blog_link.link" target="_blank">
              <p
                class="blog-link"
                v-html="this.covid_report_info.blog_link.text"
                @click="blogclicked('post-covid-blog-link')"
              ></p>
            </a>
          </div>
        </div>
      </section>

      <section class="quotes">
        <div class="container">
          <h3
            v-html="this.covid_report_info.covid_quote_table_text.header_text"
          ></h3>
          <table class="table">
            <thead>
              <tr>
                <th class="empty"></th>
                <th
                  class="plans-head th-basic"
                  v-html="
                    this.covid_report_info.covid_quote_table_plans[0].plan_name
                  "
                ></th>
                <th
                  class="plans-head th-plus"
                  v-html="
                    this.covid_report_info.covid_quote_table_plans[1].plan_name
                  "
                ></th>
                <th
                  class="plans-head th-premium"
                  v-html="
                    this.covid_report_info.covid_quote_table_plans[2].plan_name
                  "
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in covid_quote_table" v-bind:key="row">
                <td class="t-title">{{ row.title }}</td>
                <td class="plans plans-basic" v-if="row.basic">
                  <img
                    class="check-icon"
                    src="/wp-content/themes/asiance/dist/img/check-icon.svg"
                    alt="Yes"
                  />
                </td>
                <td class="plans plans-basic" v-else></td>
                <td class="plans plans-plus" v-if="row.plus">
                  <img
                    class="check-icon"
                    src="/wp-content/themes/asiance/dist/img/check-icon.svg"
                    alt="Yes"
                  />
                </td>
                <td class="plans plans-plus" v-else></td>
                <td class="plans plans-premium" v-if="row.premium">
                  <img
                    class="check-icon"
                    src="/wp-content/themes/asiance/dist/img/check-icon.svg"
                    alt="Yes"
                  />
                </td>
                <td class="plans plans-premium" v-else></td>
              </tr>
              <tr>
                <td></td>
                <td colspan="3">
                  <p
                    class="quotebutton"
                    v-html="
                      this.covid_report_info.covid_quote_table_text
                        .quote_link_text
                    "
                    @click="
                      scroll('quote-form');
                      askquoteclicked('covid-table');
                    "
                  ></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section class="report-info">
        <div class="container" id="banner-landscape-info">
          <h2 v-html="this.landscape_report_info.title"></h2>
          <div class="report-key-wrap landscape-wrap">
            <div
              class="report-key-features"
              v-html="this.landscape_report_info.key_features"
            ></div>
          </div>
          <div
            class="report-more-description"
            v-html="this.landscape_report_info.description"
          ></div>
          <div class="blog">
            <a
              :href="this.landscape_report_info.blog_link.link"
              target="_blank"
            >
              <p
                class="blog-link"
                v-html="this.landscape_report_info.blog_link.text"
                @click="blogclicked('landscape-blog-link')"
              ></p>
            </a>
          </div>
        </div>
      </section>

      <section class="quotes">
        <div class="container">
          <h3
            v-html="
              this.landscape_report_info.landscape_quote_table_text.header_text
            "
          ></h3>
          <table class="table">
            <thead>
              <tr>
                <th class="empty"></th>
                <th
                  class="plans-head th-basic"
                  v-html="
                    this.landscape_report_info.landscape_quote_table_plans[0]
                      .plan_name
                  "
                ></th>
                <th
                  class="plans-head th-plus"
                  v-html="
                    this.landscape_report_info.landscape_quote_table_plans[1]
                      .plan_name
                  "
                ></th>
                <th
                  class="plans-head th-premium"
                  v-html="
                    this.landscape_report_info.landscape_quote_table_plans[2]
                      .plan_name
                  "
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in landscape_quote_table" v-bind:key="row">
                <td class="t-title">{{ row.title }}</td>
                <td class="plans plans-basic" v-if="row.basic">
                  <img
                    class="check-icon"
                    src="/wp-content/themes/asiance/dist/img/check-icon.svg"
                    alt="Yes"
                  />
                </td>
                <td class="plans plans-basic" v-else></td>
                <td class="plans plans-plus" v-if="row.plus">
                  <img
                    class="check-icon"
                    src="/wp-content/themes/asiance/dist/img/check-icon.svg"
                    alt="Yes"
                  />
                </td>
                <td class="plans plans-plus" v-else></td>
                <td class="plans plans-premium" v-if="row.premium">
                  <img
                    class="check-icon"
                    src="/wp-content/themes/asiance/dist/img/check-icon.svg"
                    alt="Yes"
                  />
                </td>
                <td class="plans plans-premium" v-else></td>
              </tr>
              <tr>
                <td></td>
                <td colspan="3">
                  <p
                    class="quotebutton"
                    v-html="
                      this.landscape_report_info.landscape_quote_table_text
                        .quote_link_text
                    "
                    @click="
                      scroll('quote-form');
                      askquoteclicked('landscape-table');
                    "
                  ></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <div class="inquiry-thanks">
        <img src="/wp-content/themes/asiance/dist/img/pinwheel.svg" />
        <h3>{{ $t("form_inquiry_thanks_title") }}</h3>
        <p>
          {{ $t("form_inquiry_thanks_message") }}<br />{{
            $t("form_inquiry_thanks_message2")
          }}
        </p>
      </div>
      <section class="get-quotes">
        <h2 id="quote-form">{{ $t("form_quote_header") }}</h2>
        <div class="form-container">
          <form action="" method="post">
            <fieldset class="select_plan_covid">
              <div class="error">
                {{ $t("form_inquiry_error_report") }}
              </div>
              <legend>{{ $t("form_quote_select_plan_title") }}</legend>
              <div class="plan-options">
                <div class="report-checkbox">
                  <div class="field">
                    <input
                      type="checkbox"
                      value="Post Covid-19 Insight Report"
                      name="report[]"
                      id="report_covid"
                      v-model="covid_checked"
                    />
                    <label for="report_covid">
                      {{ $t("form_quote_report_covid") }}
                      <img
                        class="white-check"
                        src="/wp-content/themes/asiance/dist/img/white-check.svg"
                        v-if="covid_checked"
                      />
                    </label>
                  </div>
                  <div v-show="covid_checked" class="field_select">
                    <label for="covid_quote_plan"
                      >{{ $t("form_quote_plan_inquiry") }}
                    </label>
                    <select
                      id="quote_plan"
                      name="plan[]"
                      v-bind:disabled="!covid_checked"
                    >
                      <option
                        value="No plan selected for Post Covid-19 Insight Report"
                        selected
                      >
                        {{ $t("form_quote_plan_inquiry") }}
                      </option>
                      <option value="Post Covid-19 Insight Report - Basic Plan">
                        {{ $t("form_quote_basic_plan") }}
                      </option>
                      <option value="Post Covid-19 Insight Report - Plus Plan">
                        {{ $t("form_quote_plus_plan") }}
                      </option>
                      <option
                        value="Post Covid-19 Insight Report - Premium Plan"
                      >
                        {{ $t("form_quote_premium_plan") }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="report-checkbox">
                  <div class="field">
                    <input
                      type="checkbox"
                      value="Korean Digital Landscape Report"
                      name="report[]"
                      id="report_landscape"
                      v-model="landscape_checked"
                    />
                    <label for="report_landscape">
                      {{ $t("form_quote_report_landscape") }}
                      <img
                        class="white-check"
                        src="/wp-content/themes/asiance/dist/img/white-check.svg"
                        v-if="landscape_checked"
                      />
                    </label>
                  </div>
                  <div v-show="landscape_checked" class="field_select">
                    <label for="landscape_quote_plan"
                      >{{ $t("form_quote_plan_inquiry") }}
                    </label>
                    <select
                      id="quote_plan"
                      name="plan[]"
                      v-bind:disabled="!landscape_checked"
                    >
                      <option
                        value="No plan selected for Korean Digital Landscape Report"
                        selected
                      >
                        {{ $t("form_quote_plan_inquiry") }}
                      </option>
                      <option
                        value="Korean Digital Landscape Report - Basic Plan"
                      >
                        {{ $t("form_quote_basic_plan") }}
                      </option>
                      <option
                        value="Korean Digital Landscape Report - Plus Plan"
                      >
                        {{ $t("form_quote_plus_plan") }}
                      </option>
                      <option
                        value="Korean Digital Landscape Report - Premium Plan"
                      >
                        {{ $t("form_quote_premium_plan") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="project_details">
              <legend>{{ $t("form_quote_project_details_title") }}</legend>
              <div class="wrapper">
                <textarea name="details" id="details"></textarea>
              </div>
            </fieldset>
            <fieldset class="project_contacts">
              <legend>{{ $t("form_inquiry_contact_title") }}</legend>

              <div class="wrapper">
                <div class="field">
                  <input
                    type="text"
                    name="info_full_name"
                    id="info_full_name"
                    :placeholder="$t('form_inquiry_full_name')"
                    oninput="setCustomValidity('')"
                    oninvalid="setCustomValidity('Please enter a valid name')"
                    :data-error="$t('form_inquiry_error_name')"
                    required
                  />
                  <label for="info_full_name">{{
                    $t("form_inquiry_full_name")
                  }}</label>
                </div>

                <div class="field">
                  <input
                    type="text"
                    name="info_email"
                    id="info_email"
                    :placeholder="$t('form_inquiry_email')"
                    oninvalid="if (this.value == ''){this.setCustomValidity('Please enter a valid email address')} if (this.value != ''){this.setCustomValidity('The email you entered is invalid!')}"
                    oninput="setCustomValidity('')"
                    :data-error="$t('form_inquiry_error_email')"
                    required
                  />
                  <label for="info_email">{{ $t("form_inquiry_email") }}</label>
                </div>

                <div class="field">
                  <input
                    type="text"
                    name="info_organization"
                    id="info_organization"
                    :placeholder="$t('form_quote_company')"
                    oninput="setCustomValidity('')"
                    oninvalid="setCustomValidity('Please enter a valid company name')"
                    :data-error="$t('form_inquiry_error_company')"
                    required
                  />
                  <label for="info_organization">{{
                    $t("form_quote_company")
                  }}</label>
                </div>

                <div class="field">
                  <input
                    type="text"
                    name="info_brand"
                    id="info_brand"
                    :placeholder="$t('form_quote_brand')"
                  />
                  <label for="info_brand">{{ $t("form_quote_brand") }}</label>
                </div>

                <div class="field_select">
                  <label for="info_country_code"
                    >{{ $t("form_inquiry_code") }}
                  </label>
                  <select
                    id="info_country_code"
                    name="info_country_code"
                    oninput="setCustomValidity('')"
                    oninvalid="setCustomValidity('Please select a country code')"
                    :data-error="$t('form_quote_error_countrycode')"
                    required
                  >
                    <option value="" disabled selected>
                      {{ $t("form_quote_code") }}
                    </option>
                    <optgroup>
                      <option data-countryCode="CN" value="86">
                        China (+86)
                      </option>
                      <option data-countryCode="FR" value="33">
                        France (+33)
                      </option>
                      <option data-countryCode="JP" value="81">
                        Japan (+81)
                      </option>
                      <option data-countryCode="KR" value="82">
                        Korea South (+82)
                      </option>
                      <option data-countryCode="SG" value="65">
                        Singapore (+65)
                      </option>
                      <option data-countryCode="US" value="1">USA (+1)</option>
                    </optgroup>
                    <option data-countryCode="DZ" value="213">
                      Algeria (+213)
                    </option>
                    <option data-countryCode="AD" value="376">
                      Andorra (+376)
                    </option>
                    <option data-countryCode="AO" value="244">
                      Angola (+244)
                    </option>
                    <option data-countryCode="AI" value="1264">
                      Anguilla (+1264)
                    </option>
                    <option data-countryCode="AG" value="1268">
                      Antigua &amp; Barbuda (+1268)
                    </option>
                    <option data-countryCode="AR" value="54">
                      Argentina (+54)
                    </option>
                    <option data-countryCode="AM" value="374">
                      Armenia (+374)
                    </option>
                    <option data-countryCode="AW" value="297">
                      Aruba (+297)
                    </option>
                    <option data-countryCode="AU" value="61">
                      Australia (+61)
                    </option>
                    <option data-countryCode="AT" value="43">
                      Austria (+43)
                    </option>
                    <option data-countryCode="AZ" value="994">
                      Azerbaijan (+994)
                    </option>
                    <option data-countryCode="BS" value="1242">
                      Bahamas (+1242)
                    </option>
                    <option data-countryCode="BH" value="973">
                      Bahrain (+973)
                    </option>
                    <option data-countryCode="BD" value="880">
                      Bangladesh (+880)
                    </option>
                    <option data-countryCode="BB" value="1246">
                      Barbados (+1246)
                    </option>
                    <option data-countryCode="BY" value="375">
                      Belarus (+375)
                    </option>
                    <option data-countryCode="BE" value="32">
                      Belgium (+32)
                    </option>
                    <option data-countryCode="BZ" value="501">
                      Belize (+501)
                    </option>
                    <option data-countryCode="BJ" value="229">
                      Benin (+229)
                    </option>
                    <option data-countryCode="BM" value="1441">
                      Bermuda (+1441)
                    </option>
                    <option data-countryCode="BT" value="975">
                      Bhutan (+975)
                    </option>
                    <option data-countryCode="BO" value="591">
                      Bolivia (+591)
                    </option>
                    <option data-countryCode="BA" value="387">
                      Bosnia Herzegovina (+387)
                    </option>
                    <option data-countryCode="BW" value="267">
                      Botswana (+267)
                    </option>
                    <option data-countryCode="BR" value="55">
                      Brazil (+55)
                    </option>
                    <option data-countryCode="BN" value="673">
                      Brunei (+673)
                    </option>
                    <option data-countryCode="BG" value="359">
                      Bulgaria (+359)
                    </option>
                    <option data-countryCode="BF" value="226">
                      Burkina Faso (+226)
                    </option>
                    <option data-countryCode="BI" value="257">
                      Burundi (+257)
                    </option>
                    <option data-countryCode="KH" value="855">
                      Cambodia (+855)
                    </option>
                    <option data-countryCode="CM" value="237">
                      Cameroon (+237)
                    </option>
                    <option data-countryCode="CA" value="1">Canada (+1)</option>
                    <option data-countryCode="CV" value="238">
                      Cape Verde Islands (+238)
                    </option>
                    <option data-countryCode="KY" value="1345">
                      Cayman Islands (+1345)
                    </option>
                    <option data-countryCode="CF" value="236">
                      Central African Republic (+236)
                    </option>
                    <option data-countryCode="CL" value="56">
                      Chile (+56)
                    </option>
                    <option data-countryCode="CN" value="86">
                      China (+86)
                    </option>
                    <option data-countryCode="CO" value="57">
                      Colombia (+57)
                    </option>
                    <option data-countryCode="KM" value="269">
                      Comoros (+269)
                    </option>
                    <option data-countryCode="CG" value="242">
                      Congo (+242)
                    </option>
                    <option data-countryCode="CK" value="682">
                      Cook Islands (+682)
                    </option>
                    <option data-countryCode="CR" value="506">
                      Costa Rica (+506)
                    </option>
                    <option data-countryCode="HR" value="385">
                      Croatia (+385)
                    </option>
                    <option data-countryCode="CU" value="53">Cuba (+53)</option>
                    <option data-countryCode="CY" value="90392">
                      Cyprus North (+90392)
                    </option>
                    <option data-countryCode="CY" value="357">
                      Cyprus South (+357)
                    </option>
                    <option data-countryCode="CZ" value="42">
                      Czech Republic (+42)
                    </option>
                    <option data-countryCode="DK" value="45">
                      Denmark (+45)
                    </option>
                    <option data-countryCode="DJ" value="253">
                      Djibouti (+253)
                    </option>
                    <option data-countryCode="DM" value="1809">
                      Dominica (+1809)
                    </option>
                    <option data-countryCode="DO" value="1809">
                      Dominican Republic (+1809)
                    </option>
                    <option data-countryCode="EC" value="593">
                      Ecuador (+593)
                    </option>
                    <option data-countryCode="EG" value="20">
                      Egypt (+20)
                    </option>
                    <option data-countryCode="SV" value="503">
                      El Salvador (+503)
                    </option>
                    <option data-countryCode="GQ" value="240">
                      Equatorial Guinea (+240)
                    </option>
                    <option data-countryCode="ER" value="291">
                      Eritrea (+291)
                    </option>
                    <option data-countryCode="EE" value="372">
                      Estonia (+372)
                    </option>
                    <option data-countryCode="ET" value="251">
                      Ethiopia (+251)
                    </option>
                    <option data-countryCode="FK" value="500">
                      Falkland Islands (+500)
                    </option>
                    <option data-countryCode="FO" value="298">
                      Faroe Islands (+298)
                    </option>
                    <option data-countryCode="FJ" value="679">
                      Fiji (+679)
                    </option>
                    <option data-countryCode="FI" value="358">
                      Finland (+358)
                    </option>
                    <option data-countryCode="GF" value="594">
                      French Guiana (+594)
                    </option>
                    <option data-countryCode="PF" value="689">
                      French Polynesia (+689)
                    </option>
                    <option data-countryCode="GA" value="241">
                      Gabon (+241)
                    </option>
                    <option data-countryCode="GM" value="220">
                      Gambia (+220)
                    </option>
                    <option data-countryCode="GE" value="7880">
                      Georgia (+7880)
                    </option>
                    <option data-countryCode="DE" value="49">
                      Germany (+49)
                    </option>
                    <option data-countryCode="GH" value="233">
                      Ghana (+233)
                    </option>
                    <option data-countryCode="GI" value="350">
                      Gibraltar (+350)
                    </option>
                    <option data-countryCode="GR" value="30">
                      Greece (+30)
                    </option>
                    <option data-countryCode="GL" value="299">
                      Greenland (+299)
                    </option>
                    <option data-countryCode="GD" value="1473">
                      Grenada (+1473)
                    </option>
                    <option data-countryCode="GP" value="590">
                      Guadeloupe (+590)
                    </option>
                    <option data-countryCode="GU" value="671">
                      Guam (+671)
                    </option>
                    <option data-countryCode="GT" value="502">
                      Guatemala (+502)
                    </option>
                    <option data-countryCode="GN" value="224">
                      Guinea (+224)
                    </option>
                    <option data-countryCode="GW" value="245">
                      Guinea - Bissau (+245)
                    </option>
                    <option data-countryCode="GY" value="592">
                      Guyana (+592)
                    </option>
                    <option data-countryCode="HT" value="509">
                      Haiti (+509)
                    </option>
                    <option data-countryCode="HN" value="504">
                      Honduras (+504)
                    </option>
                    <option data-countryCode="HK" value="852">
                      Hong Kong (+852)
                    </option>
                    <option data-countryCode="HU" value="36">
                      Hungary (+36)
                    </option>
                    <option data-countryCode="IS" value="354">
                      Iceland (+354)
                    </option>
                    <option data-countryCode="IN" value="91">
                      India (+91)
                    </option>
                    <option data-countryCode="ID" value="62">
                      Indonesia (+62)
                    </option>
                    <option data-countryCode="IR" value="98">Iran (+98)</option>
                    <option data-countryCode="IQ" value="964">
                      Iraq (+964)
                    </option>
                    <option data-countryCode="IE" value="353">
                      Ireland (+353)
                    </option>
                    <option data-countryCode="IL" value="972">
                      Israel (+972)
                    </option>
                    <option data-countryCode="IT" value="39">
                      Italy (+39)
                    </option>
                    <option data-countryCode="JM" value="1876">
                      Jamaica (+1876)
                    </option>
                    <option data-countryCode="JO" value="962">
                      Jordan (+962)
                    </option>
                    <option data-countryCode="KZ" value="7">
                      Kazakhstan (+7)
                    </option>
                    <option data-countryCode="KE" value="254">
                      Kenya (+254)
                    </option>
                    <option data-countryCode="KI" value="686">
                      Kiribati (+686)
                    </option>
                    <option data-countryCode="KP" value="850">
                      Korea North (+850)
                    </option>
                    <option data-countryCode="KW" value="965">
                      Kuwait (+965)
                    </option>
                    <option data-countryCode="KG" value="996">
                      Kyrgyzstan (+996)
                    </option>
                    <option data-countryCode="LA" value="856">
                      Laos (+856)
                    </option>
                    <option data-countryCode="LV" value="371">
                      Latvia (+371)
                    </option>
                    <option data-countryCode="LB" value="961">
                      Lebanon (+961)
                    </option>
                    <option data-countryCode="LS" value="266">
                      Lesotho (+266)
                    </option>
                    <option data-countryCode="LR" value="231">
                      Liberia (+231)
                    </option>
                    <option data-countryCode="LY" value="218">
                      Libya (+218)
                    </option>
                    <option data-countryCode="LI" value="417">
                      Liechtenstein (+417)
                    </option>
                    <option data-countryCode="LT" value="370">
                      Lithuania (+370)
                    </option>
                    <option data-countryCode="LU" value="352">
                      Luxembourg (+352)
                    </option>
                    <option data-countryCode="MO" value="853">
                      Macao (+853)
                    </option>
                    <option data-countryCode="MK" value="389">
                      Macedonia (+389)
                    </option>
                    <option data-countryCode="MG" value="261">
                      Madagascar (+261)
                    </option>
                    <option data-countryCode="MW" value="265">
                      Malawi (+265)
                    </option>
                    <option data-countryCode="MY" value="60">
                      Malaysia (+60)
                    </option>
                    <option data-countryCode="MV" value="960">
                      Maldives (+960)
                    </option>
                    <option data-countryCode="ML" value="223">
                      Mali (+223)
                    </option>
                    <option data-countryCode="MT" value="356">
                      Malta (+356)
                    </option>
                    <option data-countryCode="MH" value="692">
                      Marshall Islands (+692)
                    </option>
                    <option data-countryCode="MQ" value="596">
                      Martinique (+596)
                    </option>
                    <option data-countryCode="MR" value="222">
                      Mauritania (+222)
                    </option>
                    <option data-countryCode="YT" value="269">
                      Mayotte (+269)
                    </option>
                    <option data-countryCode="MX" value="52">
                      Mexico (+52)
                    </option>
                    <option data-countryCode="FM" value="691">
                      Micronesia (+691)
                    </option>
                    <option data-countryCode="MD" value="373">
                      Moldova (+373)
                    </option>
                    <option data-countryCode="MC" value="377">
                      Monaco (+377)
                    </option>
                    <option data-countryCode="MN" value="976">
                      Mongolia (+976)
                    </option>
                    <option data-countryCode="MS" value="1664">
                      Montserrat (+1664)
                    </option>
                    <option data-countryCode="MA" value="212">
                      Morocco (+212)
                    </option>
                    <option data-countryCode="MZ" value="258">
                      Mozambique (+258)
                    </option>
                    <option data-countryCode="MN" value="95">
                      Myanmar (+95)
                    </option>
                    <option data-countryCode="NA" value="264">
                      Namibia (+264)
                    </option>
                    <option data-countryCode="NR" value="674">
                      Nauru (+674)
                    </option>
                    <option data-countryCode="NP" value="977">
                      Nepal (+977)
                    </option>
                    <option data-countryCode="NL" value="31">
                      Netherlands (+31)
                    </option>
                    <option data-countryCode="NC" value="687">
                      New Caledonia (+687)
                    </option>
                    <option data-countryCode="NZ" value="64">
                      New Zealand (+64)
                    </option>
                    <option data-countryCode="NI" value="505">
                      Nicaragua (+505)
                    </option>
                    <option data-countryCode="NE" value="227">
                      Niger (+227)
                    </option>
                    <option data-countryCode="NG" value="234">
                      Nigeria (+234)
                    </option>
                    <option data-countryCode="NU" value="683">
                      Niue (+683)
                    </option>
                    <option data-countryCode="NF" value="672">
                      Norfolk Islands (+672)
                    </option>
                    <option data-countryCode="NP" value="670">
                      Northern Marianas (+670)
                    </option>
                    <option data-countryCode="NO" value="47">
                      Norway (+47)
                    </option>
                    <option data-countryCode="OM" value="968">
                      Oman (+968)
                    </option>
                    <option data-countryCode="PW" value="680">
                      Palau (+680)
                    </option>
                    <option data-countryCode="PA" value="507">
                      Panama (+507)
                    </option>
                    <option data-countryCode="PG" value="675">
                      Papua New Guinea (+675)
                    </option>
                    <option data-countryCode="PY" value="595">
                      Paraguay (+595)
                    </option>
                    <option data-countryCode="PE" value="51">Peru (+51)</option>
                    <option data-countryCode="PH" value="63">
                      Philippines (+63)
                    </option>
                    <option data-countryCode="PL" value="48">
                      Poland (+48)
                    </option>
                    <option data-countryCode="PT" value="351">
                      Portugal (+351)
                    </option>
                    <option data-countryCode="PR" value="1787">
                      Puerto Rico (+1787)
                    </option>
                    <option data-countryCode="QA" value="974">
                      Qatar (+974)
                    </option>
                    <option data-countryCode="RE" value="262">
                      Reunion (+262)
                    </option>
                    <option data-countryCode="RO" value="40">
                      Romania (+40)
                    </option>
                    <option data-countryCode="RU" value="7">Russia (+7)</option>
                    <option data-countryCode="RW" value="250">
                      Rwanda (+250)
                    </option>
                    <option data-countryCode="SM" value="378">
                      San Marino (+378)
                    </option>
                    <option data-countryCode="ST" value="239">
                      Sao Tome &amp; Principe (+239)
                    </option>
                    <option data-countryCode="SA" value="966">
                      Saudi Arabia (+966)
                    </option>
                    <option data-countryCode="SN" value="221">
                      Senegal (+221)
                    </option>
                    <option data-countryCode="CS" value="381">
                      Serbia (+381)
                    </option>
                    <option data-countryCode="SC" value="248">
                      Seychelles (+248)
                    </option>
                    <option data-countryCode="SL" value="232">
                      Sierra Leone (+232)
                    </option>
                    <option data-countryCode="SG" value="65">
                      Singapore (+65)
                    </option>
                    <option data-countryCode="SK" value="421">
                      Slovak Republic (+421)
                    </option>
                    <option data-countryCode="SI" value="386">
                      Slovenia (+386)
                    </option>
                    <option data-countryCode="SB" value="677">
                      Solomon Islands (+677)
                    </option>
                    <option data-countryCode="SO" value="252">
                      Somalia (+252)
                    </option>
                    <option data-countryCode="ZA" value="27">
                      South Africa (+27)
                    </option>
                    <option data-countryCode="ES" value="34">
                      Spain (+34)
                    </option>
                    <option data-countryCode="LK" value="94">
                      Sri Lanka (+94)
                    </option>
                    <option data-countryCode="SH" value="290">
                      St. Helena (+290)
                    </option>
                    <option data-countryCode="KN" value="1869">
                      St. Kitts (+1869)
                    </option>
                    <option data-countryCode="SC" value="1758">
                      St. Lucia (+1758)
                    </option>
                    <option data-countryCode="SD" value="249">
                      Sudan (+249)
                    </option>
                    <option data-countryCode="SR" value="597">
                      Suriname (+597)
                    </option>
                    <option data-countryCode="SZ" value="268">
                      Swaziland (+268)
                    </option>
                    <option data-countryCode="SE" value="46">
                      Sweden (+46)
                    </option>
                    <option data-countryCode="CH" value="41">
                      Switzerland (+41)
                    </option>
                    <option data-countryCode="SI" value="963">
                      Syria (+963)
                    </option>
                    <option data-countryCode="TW" value="886">
                      Taiwan (+886)
                    </option>
                    <option data-countryCode="TJ" value="7">
                      Tajikstan (+7)
                    </option>
                    <option data-countryCode="TH" value="66">
                      Thailand (+66)
                    </option>
                    <option data-countryCode="TG" value="228">
                      Togo (+228)
                    </option>
                    <option data-countryCode="TO" value="676">
                      Tonga (+676)
                    </option>
                    <option data-countryCode="TT" value="1868">
                      Trinidad &amp; Tobago (+1868)
                    </option>
                    <option data-countryCode="TN" value="216">
                      Tunisia (+216)
                    </option>
                    <option data-countryCode="TR" value="90">
                      Turkey (+90)
                    </option>
                    <option data-countryCode="TM" value="7">
                      Turkmenistan (+7)
                    </option>
                    <option data-countryCode="TM" value="993">
                      Turkmenistan (+993)
                    </option>
                    <option data-countryCode="TC" value="1649">
                      Turks &amp; Caicos Islands (+1649)
                    </option>
                    <option data-countryCode="TV" value="688">
                      Tuvalu (+688)
                    </option>
                    <option data-countryCode="UG" value="256">
                      Uganda (+256)
                    </option>
                    <option data-countryCode="GB" value="44">UK (+44)</option>
                    <option data-countryCode="UA" value="380">
                      Ukraine (+380)
                    </option>
                    <option data-countryCode="AE" value="971">
                      United Arab Emirates (+971)
                    </option>
                    <option data-countryCode="UY" value="598">
                      Uruguay (+598)
                    </option>
                    <option data-countryCode="UZ" value="7">
                      Uzbekistan (+7)
                    </option>
                    <option data-countryCode="VU" value="678">
                      Vanuatu (+678)
                    </option>
                    <option data-countryCode="VA" value="379">
                      Vatican City (+379)
                    </option>
                    <option data-countryCode="VE" value="58">
                      Venezuela (+58)
                    </option>
                    <option data-countryCode="VN" value="84">
                      Vietnam (+84)
                    </option>
                    <option data-countryCode="VG" value="84">
                      Virgin Islands - British (+1284)
                    </option>
                    <option data-countryCode="VI" value="84">
                      Virgin Islands - US (+1340)
                    </option>
                    <option data-countryCode="WF" value="681">
                      Wallis &amp; Futuna (+681)
                    </option>
                    <option data-countryCode="YE" value="969">
                      Yemen (North)(+969)
                    </option>
                    <option data-countryCode="YE" value="967">
                      Yemen (South)(+967)
                    </option>
                    <option data-countryCode="ZM" value="260">
                      Zambia (+260)
                    </option>
                    <option data-countryCode="ZW" value="263">
                      Zimbabwe (+263)
                    </option>
                  </select>
                </div>

                <div class="field">
                  <input
                    type="phone"
                    id="info_phone"
                    name="info_phone"
                    :placeholder="$t('form_quote_phone')"
                    oninput="setCustomValidity('')"
                    oninvalid="setCustomValidity('Please enter a valid phone number')"
                    :data-error="$t('form_quote_error_phone')"
                    required
                  />
                  <label for="info_phone">{{ $t("form_quote_phone") }} </label>
                </div>
              </div>
            </fieldset>
            <div class="wrapper_submit">
              <input
                type="hidden"
                name="language"
                id="language"
                :value="this.$i18n.locale"
              />
              <button id="send" v-on:click="checkForm">
                <img src="/wp-content/themes/asiance/dist/img/btn-loader.gif" v-if="sending" />
                <span v-else>{{ $t("form_inquiry_send") }}</span>
              </button>
              <input type="submit" v-on:click="sendData" id="form_submit" />
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PageService from "../services/PageService";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: {
    LoadingScreen,
  },
  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
      banner: {},
      covid_report_info: {},
      covid_quote_table: {},
      landscape_report_info: {},
      landscape_quote_table: {},
      covid_checked: false,
      landscape_checked: false,
    };
  },
  created() {
    this.fetchItem(this.$i18n.locale);
  },
  mounted() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").show();
    $(".subtitle:first").addClass("white");

    $("header").removeClass();
    $("header").removeClass("black");
    $("header:first").addClass("fixed");

    var Utils = this.$parent.scrollReveal();
    $(document).on("scroll", () => {
      $(".expert").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });
  },
  methods: {
    sendData() {
      var form = document.querySelectorAll("form")[0];
      form.addEventListener(
        "submit",
        function (event) {
          event.preventDefault();
          return false;
        },
        true
      );
    },
    checkForm(e) {
      var form = document.querySelectorAll("form")[0];

      form.addEventListener(
        "invalid",
        function (event) {
          event.preventDefault();
        },
        true
      );

      $("#form_submit").click();
      $(".error-message").hide();

      var invalidFields = form.querySelectorAll(":invalid"),
        errorMessages = form.querySelectorAll(".error-message"),
        parent;

      for (var i = 0; i < errorMessages.length; i++) {
        errorMessages[i].parentNode.removeChild(errorMessages[i]);
      }

      setTimeout(() => {
        for (var i = 0; i < invalidFields.length; i++) {
          parent = invalidFields[i].parentNode;
          console.log(parent);
          parent.insertAdjacentHTML(
            "beforeend",
            "<div class='error-message'>" +
              invalidFields[i].dataset.error +
              "</div>"
          );
        }
      }, 100);

      var error = false;

      var checked = $(".select_plan_covid input[type=checkbox]:checked").length;
      if (!checked) {
        $(".select_plan_covid .error").show();
        var element = document.getElementsByClassName("select_plan_covid");
        element[0].scrollIntoView();
        error = true;
      } else {
        $(".select_plan_covid .error").hide();
      }

      if (error == true) {
        return false;
      }

      e.preventDefault();

      if (invalidFields.length > 0) {
        invalidFields[0].scrollIntoView();
        return false;
      }

      // var datastring = $("form").serialize();
      // var data = new FormData();
      var formData = new FormData(form);
      $("#send").attr("disabled", true);
      this.sending = true;
      console.log("is it sending?");
      $.ajax({
        type: "POST",
        url: "/reportquote",
        enctype: "multipart/form-data",
        processData: false,
        contentType: false,
        cache: false,
        timeout: 600000,
        data: formData,
        success: function () {
          $(".inquiry-thanks").show();
          $(".get-quotes").hide();
          $("#send").attr("disabled", false);
          //   this.$ga.event('button', 'form submit success', 'Insight report form submit success');
          this.sending = false;
        },
      });
    },
    fetchItem(lang) {
      return PageService.get(lang, "insightreport")
        .then((result) => {
          this.item = result.data[0];
          this.acf = this.item.acf;
          this.title = this.item.acf.title;
          this.banner = this.item.acf.banner;
          this.covid_report_info = this.item.acf.covid_report_info;
          this.covid_quote_table =
            this.item.acf.covid_report_info.covid_quote_table;
          this.landscape_report_info = this.item.acf.landscape_report_info;
          this.landscape_quote_table =
            this.item.acf.landscape_report_info.landscape_quote_table;
          setTimeout(() => {
            this.isLoading = false;
            this.$parent.updateSub();
          }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
    scroll(id) {
      var element = document.getElementById(id);
      var headerOffset = $("header.fixed").outerHeight();
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;
      if ($(window).width() < 769) {
        offsetPosition = elementPosition - 70;
      }
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    bannerclicked(id) {
      this.$ga.event("banner", "click to scroll", id + "-more-info");
    },
    askquoteclicked(id) {
      this.$ga.event(
        "button",
        "click to scroll",
        id + " scroll to request a quote form"
      );
    },
    blogclicked(id) {
      this.$ga.event("link", "click", id);
    },
    // formsubmitclicked(){
    //     this.$ga.event('button', 'form submit success', 'Insight report form submit success');
    // }
  },
};
</script>

<style lang="stylus">


#content{
    font-family: futura-pt, 'Noto Sans KR';
}
.container{
    margin: calc(100vw / 14.4) calc(171vw / 14.4);
    h2{
        font-weight: 400;
        font-size: 48px;
        line-height: 1.5;
    }
    h3{
        font-weight: 400;
        font-size: 36px;
        line-height: 1.3;
    }
}
.quotes{
    background-color: #F7F7F7;
    h3{
        text-align: center;
        margin-bottom: 50px;
    }
    .container{
        padding: 50px 0;
    }
}
.insight-title {
    background-color: #26313D;
    background-image: url('../../public/img/insightreport-main.png');
    background-position: center;
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    padding-top: calc(160vw / 14.4);
    min-height: 700px;

    hgroup {
        margin-left: calc(171vw / 14.4);
        max-width: 66%;
    }

    h1 {
        font-family: futura-pt, 'Noto Sans KR';
        color: #fff;
        font-size: calc(35vw / 14.4);
        line-height: calc(50vw / 14.4);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.3em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 0;
    }

    .separator {
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 60px;
        left: 0;
        right: 0;
        margin: auto;

        span {
            float: right;
            position: relative;
            top: 30px;
            width: 1px;
            height: 30px;
            background: #fff;
        }

        span.black {
            background: #2A3642;
        }
    }
}
.bannerlinks{
    position: absolute;
    margin-left: calc(171vw / 14.4);
    bottom: calc(150vh / 14.4);

    .report{
        background-color: white;
        margin-top: 28px;
        padding: 18px 40px;
        width: calc(50vw);
        font-family: futura-pt, 'Noto Sans KR';
        line-height: calc(24vw/14.4);

        .report-title{
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          transform: scaleY(0.9);
          margin-bottom: 8px;
        }
        .report-description{
          font-weight: 400;
          font-size: 20px;
          display: inline-block;
        }
        span{
            border-bottom: 1.5px solid black;
            padding-bottom: 2px;
        }

        .report-low-link {
            display: inline-block;
            float: right;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 400;
            color: black;
            letter-spacing: 3px;
            cursor: pointer;
        }

    }
}

#banner-covid{
    &.report-low-link{
        &:after {
            content: url('../../public/img/black-arrow.svg');
            display: inline-block;
            transform: translateX(0px) translateY(-18px) scale(1) rotate(90deg);
            transition: transform 0.3s cubic-bezier(0.22, 0.44, 0, 1);
        }
    }
    &:hover:after {
        transform: translateX(0px) translateY(-5px) scale(1) rotate(90deg);
    }
}

#banner-landscape{
    &.report-low-link{
        &:after {
            content: url('../../public/img/black-arrow.svg');
            display: inline-block;
            transform: translateX(0px) translateY(-18px) scale(1) rotate(90deg);
            transition: transform 0.3s cubic-bezier(0.22, 0.44, 0, 1);
        }
    }
    &:hover:after {
        transform: translateX(0px) translateY(-5px) scale(1) rotate(90deg);
    }
}

.report-key-wrap{
    &.covid-wrap{
        background-image: url('../../public/img/insightreport-covid1.png');
        min-height: 660px;
        background-size: cover;
        position: relative;
    }
    &.landscape-wrap{
        background-image: url('../../public/img/insightreport-landscape1.png');
        min-height: 660px;
        background-size: cover;
        position: relative;
    }
    width: 100%;
    margin-top: 100px;

    .report-key-features{
        color: white;
        background-color: rgba(42, 54, 66, 0.68);
        padding: 50px 60px;
        position: absolute;
        width: 88%;
        left: 0;
        right: 0;
        bottom: 12%;
        margin-left: auto;
        margin-right: auto;

        p{
            color: white;
            font-size: 32px;
            font-weight: 400;
        }
        ul{
            padding-bottom: 0;
            margin-left: 4px;
        }
        ul li{
            font-size: 20px;
            margin-top: 1.5em;
        }
        ul li::marker {
            color: #E33D50;
            font-size: 14px;
        }
    }
}
.report-more-description{
    margin-top: 100px;
    p{
        font-weight: 300;
        font-size: 24px;
        line-height: 1.5em;
        margin-bottom: 2em;
    }
}
.blog{
    .blog-link{
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 18px;
        font-weight: 300;
        color: #2a3642;
        &:after {
            content: url('../../public/img/black-arrow.svg');
            display: inline-block;
            transform: translateX(8px) translateY(4px) scale(0.8);
            transition: transform 0.3s cubic-bezier(0.22, 0.44, 0, 1);
        }
    }
    &:hover {
        :after {
            transform: translateX(18px) translateY(4px) scale(0.8);
        }
    }
}
table{
    border-collapse: collapse;
    font-size: 22px;
    font-weight: 400;
    width: 100%;
    border: none;
    background-color: white;
    border-top: #F7F7F7;
    .empty{
        background-color: #F7F7F7;
        border-top: 4px solid #F7F7F7;
    }
    th, td{
        padding: 20px;
    }
    .plans-head{
        text-align: center;
        border: 1px solid #E7E7E7;
        font-weight: 400;
        &.th-basic{
            background-color: white;
            color: #E33D50;
            border-top: 4px solid #E33D50;
        }
        &.th-plus{
            background-color: #F4F4F4;
            border-top: 4px solid #2A3642;
        }
        &.th-premium{
            background-color: white;
            color: #E33D50;
            border-top: 4px solid #E33D50;
        }
    }
    .t-title{
        border: 1px solid #E7E7E7;
        padding-left: 28px;
    }
    .plans{
        width: 18%;
        text-align: center;
        border: 1px solid #E7E7E7;

        &.plans-plus{
            background-color: #F4F4F4;
        }
    }
    .check-icon{
        width: 1em;
        margin: auto;
    }
    tr:last-child{
        background-color: #F7F7F7;
    }
}
.quotebutton{
    text-align: center;
    margin: auto;
    margin-top: 16px;
    color: white;
    background-color: #2A3642;
    padding: 16px;
    border: none;
    text-decoration: none;
    width: 60%;
}
.quotebutton:hover{
        cursor: pointer;
    }

@media only screen and (max-width: 1024px) {
    .insight-title{
        h1{
            font-size: calc(60vw / 14.4);
            line-height: calc(70vw / 14.4);
        }
        hgroup{
            max-width: 75%;
        }
    }
    .bannerlinks{
        .report{
            width: 100%;
            padding: 24px;
            .report-title{
                margin-bottom: 10px;
            }
        }
    }
    #banner-covid.report-low-link:after {
        transform: translateX(0px) translateY(-24px) scale(.7) rotate(90deg);
    }
    #banner-covid:hover:after{
        transform: translateX(0px) translateY(-10px) scale(.7) rotate(90deg);
    }
    #banner-landscape.report-low-link:after {
        transform: translateX(0px) translateY(-24px) scale(.7) rotate(90deg);
    }
    #banner-landscape:hover:after{
        transform: translateX(0px) translateY(-10px) scale(.7) rotate(90deg);
    }
}

@media only screen and (max-width: 768px) {
    .insight-title{
        min-height: auto;
        height: 100vh;
        padding-top: calc(100vw / 3.2);

        hgroup {
            left: 20px;
            max-width: 87%;
            margin-left: calc(100vw / 14.4);

            h1 {
                font-size: 19px;
                line-height: 30px;
                letter-spacing: 0.2em;
            }
        }
        .bannerlinks{
            margin-left: calc(100vw / 14.4);
            bottom: calc(100vh / 14.4);
            .report{
                width: 86vw;
                padding: 16px 16px;
                margin-top: 24px;
            }
            .report-title{
                width: 90%;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 20px;
                margin-bottom: 0;
            }
            .report-description{
                display: none;
            }
        }
    }

    #banner-covid.report-low-link:after {
        transform: translateX(0px) translateY(-24px) scale(.7) rotate(90deg);
    }
    #banner-covid:hover:after{
        transform: translateX(0px) translateY(-17px) scale(.7) rotate(90deg);
    }
    #banner-landscape.report-low-link:after {
        transform: translateX(0px) translateY(-37px) scale(.7) rotate(90deg);
    }
    #banner-landscape:hover:after{
        transform: translateX(0px) translateY(-23px) scale(.7) rotate(90deg);
    }
    .container{
        margin: 0;
        h2{
            margin: 50px calc(171vw / 14.4);
            font-size: 28px;
            line-height: 38px;
        }
        h3{
            font-size: 20px;
            margin-bottom: 50px;
        }
        .report-info{
            margin-top: 50px;
        }
        .report-key-wrap{
            height: 100vh;
            background-position-x: -200px;
            margin: 0;
        }
        .report-key-features{
            width: 88%;
            padding: 20px 30px;
            font-size: 26px;
            margin-top: 220px;
            p{
                font-size: 26px;
            }
            ul{
                padding-bottom: 0;
                margin-left: -12px;
                padding-top: 0;
                padding-right: 0;

                li{
                    font-size: 15px;
                    line-height: 23px;
                }
            }
        }
        .report-more-description {
            p{
                font-size: 20px;
            }
            margin-top: 50px;
            padding: 0 20px;
        }
        .blog{
            padding: 0 20px;
            margin-bottom: 50px;
            .blog-link{
                letter-spacing: 2px;
                font-size: 12px;
                font-weight: 400;
                line-height: 1;
            }
        }
    }
    table {
        font-size: 14px;
        width: 96%;
        border: none;
        margin: 0 auto;
        .t-title {
            padding: 16px;
        }
        .plans-head,
        .plans{
            padding: 14px;
        }
    }
    .quotebutton{
        width: 100%;
        margin-top: 0px;
    }
}

select {
  outline: 0 !important;
  border: 0 !important;
}

input[type=submit] {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.title_contact {
  background-image: url('../../public/img/bgd_contact.jpg');
  background-size: cover;
  background-position: center center;
  height: 100vh;
  position: relative;
  display: flex;
  padding-top: calc(200vw/14.4);
  min-height: 700px;

  hgroup {
      margin-left: calc(171vw/14.4);
      max-width: calc(871vw/14.4);
  }

  h1 {
      font-family: futura-pt, 'Noto Sans KR';
      color: #fff;
      font-size: calc(90vw/14.4);
      line-height: calc(116vw/14.4);
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 0;
  }

  h2 {
    font-family: futura-pt, 'Noto Sans KR';
    color: #fff;
    font-size: 40px;
    line-height: 52px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
  }
}



.get-quotes {

    .form-container{
        margin: auto;
        width: calc(873vw / 14.4);
    }


  font-family: futura-pt, 'Noto Sans KR';
  margin: calc(100vw / 14.4) calc(171vw / 14.4);
  font-size: calc(16vw/14.4);

  h2 {
    text-align: center;
    font-size: calc(41vw/14.4);
    margin: 0 0 calc(28vw/14.4) 0;
    font-weight: 400;

    span {
      display: block;
      color: #C43E51;
    }
  }

  p {
    font-size: calc(20vw/14.4);
  }

  fieldset {
    border: none;
    margin: calc(80vw/14.4) 0 0 0;
    padding: 0;
  }

  input[type="text"],
  input[type="url"],
  input[type="phone"] {
    padding-left: calc(7vw/14.4);
    border: 0;
    border-bottom: 1px solid;
    padding-bottom: calc(10vw / 14.4);
    transition: border-color .3s linear;

    &:focus {
      border-bottom: 1px solid #c43e51;
    }
  }

  select {
    border: 0;
  }

  legend {
    margin: 0 0 calc(20vw/14.4) 0;
    font-size: calc(24vw/14.4);
    font-weight: 500;
    color: #2A3642;
  }

  .error {
    display: none;
    color: red;
    width: 100%;
    margin-top: calc(12vw / 14.4);
    margin-bottom: calc(12vw/14.4);
    font-size: calc(15vw/14.4);
  }

  .error-message {
    color: red;
    font-size: calc(15vw/14.4);
    display: none!important;
  }

  .field, .field_select, .project_details {
    .error-message, {
      display: inherit!important;
    }
  }

  .field_select {
    position: relative;
  }

  .select {
    position: absolute;
    width: 100%;
    background: #fff;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #C43E51;

    li {
      padding: 0 calc(45vw/14.4);
      line-height: calc(63vw/14.4);
      vertical-align: middle;

      &:hover {
        background: #C43E51;
        color: #fff;
      }
    }
  }

  input::placeholder {
    color: #000;
    font-family: futura-pt;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }

.select_plan_covid{
  .plan-options {
      display: flex;
      justify-content: space-between;

    legend {
      margin: 0 0 calc(10vw/14.4) 0;
    }

    h4 {
      font-size: calc(12vw/14.4);
      font-weight: 100;
      padding: 0 0 calc(19vw/14.4) 0;
    }
    .report-checkbox{
        width: 100%;
        &:first-child{
            margin-right: calc(70vw/12.4);
        }
        p{
            margin: 30px 0;
            font-weight: 400;
            text-align: center;
        }
    }
    .field {
      background-color: #F2F2F2;
      height: calc(66vw / 14.4);
      display: flex;
      align-items: center;
      margin: 0 0 calc(15vw/14.4) 0;
      border: 1px solid transparent;
      transition: border-color .1s ease-in-out;

      &:hover {
        border: 1px solid #c43e51;
      }

      label {
          position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 0 0 calc(36vw / 14.4);
        cursor: pointer;
        transition: background-color .2s ease-in-out;
        font-weight: 400;
        .white-check{
            position: absolute;
            height: 32%;
            left: 42%;
        }
      }

      svg {
        margin: 0 calc(24vw/14.4) 0 0;
      }

      input {
        display: none;
      }

      input[type="checkbox"]:checked + label {
        background-color: #C43E51;
        color: #FFF;

        svg {
          path {
            fill: #FFF;
          }
        }
      }
    }
    .field_select {
      position: relative;
      width: 100%;
      height: calc(59vw/14.4);
      border-bottom: 1px solid;
      padding: 0 0 calc(12vw/14.4) 0;
      margin: 0 0 calc(29vw / 14.4) 0;

      label {
        display: none;
      }

      select {
        position: absolute;
        bottom: calc(10vw/14.4);
        background: transparent;
        border: 0;
        width: 100%;
        font-size: 100%;
        font-family:inherit;
      }
      label {
      opacity: 0;
      transform: translateY(calc(-20vw/14.4));
      font-size: calc(12vw/14.4);
      position: absolute;
      top: calc(10vw/14.4);
      padding-left: calc(7vw / 14.4);
    }
    }
  }
}

  .project_details {
    position: relative;

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }

    textarea {
      order: 1;
      padding: 5px;
      border: 1px solid;
      width: 100%;
      height: calc(212vw/14.4);
      box-shadow: none;
      -webkit-appearance: none;
      transition: border-color .3s linear;
      outline: none;
      min-width: 100%;

      &:focus {
        border-color: #c43e51;
      }
    }

    .error-message {
        order: 2;
        margin: calc(8vw/14.4) 0 0 0;
    }

    .file-wrapper {
      order: 3;
      margin-top: calc(20vw/14.4);

      p {
        font-size: calc(12vw/14.4);
      }
    }

    input[type="file"] {
    }

    h4 {
      font-family: futura-pt, 'Noto Sans KR';
      margin-top: calc(20vw/14.4);
    }
  }

  .project_contacts {
    .wrapper {
      margin-top: calc(-30vw/14.4);
    }

    legend {
      margin-bottom: calc(30vw/14.4);
    }

    .field {
      position: relative;
      width: 46%;
      height: calc(59vw/14.4);
      margin: 0 0 calc(29vw/14.4) 0;

      &:last-child {
        margin: 0;
      }
    }

    .field_select {
      position: relative;
      width: 46%;
      height: calc(59vw/14.4);
      border-bottom: 1px solid;
      padding: 0 0 calc(12vw/14.4) 0;
      margin: 0 0 calc(29vw / 14.4) 0;
      label {
        display: none;
      }

      select {
        position: absolute;
        bottom: calc(10vw/14.4);
        background: transparent;
        border: 0;
        width: 100%;
        font-family:inherit;
      }
    }

    label {
      opacity: 0;
      transform: translateY(calc(-20vw/14.4));
      font-size: calc(12vw/14.4);
      position: absolute;
      top: calc(10vw/14.4);
      padding-left: calc(7vw / 14.4);
    }

    .error-message {
      color: #f00;
      position: absolute;
      bottom: calc(-26vw/14.4);
    }

    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="phone"] {
      position: absolute;
      bottom: 0;
      // height: calc(25vw/14.4);
      width: 100%;
      outline: none;
      font-size: calc(15vw/14.4);

      &:focus + label,
      &:not(:placeholder-shown) + label {
        opacity: 1;
        transform: translateY(0)
        transition: all 0.2s ease-in-out;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }


  .wrapper_submit {
    text-align: center;
    img{
      width: auto;
      max-height: 100%;
      display: inline-block;
    }

    button {
      width: calc(300vw/14.4);1
      height: calc(48vw/14.4);
      margin: calc(40vw/14.4) auto 0 auto;
      background: #2A3642;
      border: none;
      color: #FFF;
      font-size: calc(18vw/14.4);
      text-transform: uppercase;
      cursor: pointer;
      transition: background-color 0.3s linear;
      text-align: center;

      &.sent {
        background: #c43e51;
      }
    }
  }
}

.inquiry-thanks {
    display: none;
    width: calc(873vw / 14.4);
    text-align: left;
    margin: calc(99vw / 14.4) auto calc(140vw/14.4) auto;
    font-size: calc(16vw / 14.4);
    text-align: center;
  img{
    width: calc(95vw/14.4);
    height: calc(95vw/14.4);
    min-width: 50px;
    min-height: 50px;
    margin: 0 auto 30px auto;
  }
  h3 {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: normal;
    font-size: calc(60vw/14.4);
    letter-spacing: 0.1em;
    font-weight: 300;
    margin-bottom: calc(32vw/14.4);
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: calc(26vw/14.4);
    font-size: calc(20vw/14.4);
  }
}

@media only screen and (max-width: 768px) {
  .get-quotes {
    text-align: left;
    padding: calc(37vw/3.75) 0;
    font-size: calc(16vw/3.75);

    .form-container{
        width: 100%;
    }

    form{
        width: 100%;
    }

    .select_plan_covid .plan-options .field{
        width: 100%;
        height: calc(12vw);
    }

    input, select {
      color: #000;
    }
    .select_plan_covid{
        display: block;
    }

    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="phone"] {
      padding: calc(4vw / 3.78);
      font-size: calc(18vw / 3.75)!important;
    }

    .error-message {
      font-size: calc(15vw / 3.75)!important;
    }

    @media only screen and (max-width: 768px) {
        .field_select{
            .error-message{
                padding-top: 30px;
            }
        }
        .wrapper_submit{
            margin-top: calc(35vw / 3.75)
        }
    }

    .error {
      font-size: calc(15vw / 3.75)!important;
    }

    h2 {
      font-size: calc(24vw/3.75);
      margin-bottom: calc(20vw/3.75);
      text-align: left
    }

    p {
      font-size: calc(16vw/3.75);
      line-height: calc(21vw/3.75);
    }

    fieldset {
      padding: 0;
      margin-top: calc(45vw/3.75);
    }

    legend {
      font-size: calc(18vw/3.75);
      margin: 0 0 calc(20vw/3.75) 0;
    }

    .wrapper {
      display: block;
      padding: 0;
    }

    .select_plan_covid {
        .plan-options{
            text-align: left
            display: block;
            font-size: calc(18vw / 3.75) !important;

            .field_select{
                height: calc(90vw / 14.4);
                font-size: calc(18vw / 3.75) !important;
                margin-top: 20px;
                margin-bottom: 30px;
            }
        }
      legend {
        margin: 0 0 calc(10vw/3.78) 0;
      }

      h4 {
        font-size: calc(12vw/3.78);
        font-weight: 100;
        padding: 0 0 calc(19vw/3.78) 0;
      }

      .field {
          width: 100%;
          height: calc(12vw);
          margin-bottom: calc(18vw/3.75) !important;

        label {
          font-size: calc(16vw/3.75);
        }
      }
    }

    .project_details {
      textarea {
        height: calc(140vw/3.75);
      }

      .error-message {
        bottom: calc(-24vw / 3.78);
        font-size: calc(18vw / 3.75)
      }

      .file-wrapper {
        p {
          font-size: calc(12vw/3.78);
        }
      }
    }

    .project_contacts {
      .field,
      .field_select {
        width: 100%;
        height: calc(45vw/3.75);
        margin-bottom: calc(20vw/3.75);

        select {
          margin-bottom: calc(5vw/3.78);
          font-size: calc(18vw / 3.75) !important;
        }

        label {
          font-size: calc(12vw/3.75);
        }

        input {
          height: calc(35vw/3.75);
        }
      }

      .field_select {
        height: calc(45vw / 3.75);
      }

      .error-message {
        bottom: calc(-24vw / 3.78) !important;
      }

    }


    .wrapper_submit {
      button {
        height: calc(68vw/3.75);
        width: 100%;
        font-size: calc(20vw/3.75);
        margin-top: calc(30vw/3.75);
      }
    }
  }

}
</style>
