<template v-if="this.stories">
    <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content" :attr-sub="$t('case_studies')">
    <div class="wrapper">
      <h1 v-if="this.item.title" v-html="this.item.title.rendered"></h1>
      <section v-for="(story, itemObjKey) in this.stories" v-bind:key="itemObjKey" v-scroll-reveal>
        <router-link :to="{ name: story.slug }"><div class="inner" 
          :style="{ 'background-image': 'url(' + story.featured_image + ')' }"></div>
        <div class="content">
          <h2 v-html="story.title.rendered"></h2>
          <p v-html="story.acf.short_description"></p>
        </div></router-link>
      </section>
    </div>
    </div>
    </div>
</template>

<script>
import PageService from "../services/PageService";
import PostService from "../services/PostService";
import { mapGetters } from "vuex";

const LoadingScreen = () =>
  import(/* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue");

export default {
  components: { LoadingScreen },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
      stories: {}
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PageService.get(lang, "our-work")
        .then(result => {
          this.item = result.data[0];

          Promise.all(
            this.item.acf.case_studies.map(post =>
              PostService.get(lang, post.ID).then(result => {
                return result.body;
              })
            )
          ).then(data => {
            setTimeout(() => {
                this.$parent.updateSub();
                this.isLoading = false;
            }, 500);
            this.stories = data;
          });
        })

        .catch(() => {
          this.error = true;
        });
    }
  },

  computed: {
    ...mapGetters({
      items: "allPosts",
      filteredItems: "filteredPosts"
    })
  },

  mounted: function() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").show();
    $(".subtitle:first").removeClass("white");

    $("header").removeClass();
    $("header").addClass("black");
    $("header:first").addClass("fixed");
  }
};
</script>

<style lang="stylus" scoped>

.wrapper {
    max-width: calc((1200vw / 14.4));
    margin: 200px auto 50px auto;
}

h1 {
    font-family: futura-pt, 'Noto Sans KR';
    margin-left: calc((80vw / 14.4));
    font-size: calc(90vw/14.4);
    line-height: calc(116vw/14.4);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.3em;
}

section {
    height: calc((400vw / 14.4));
    position: relative;
    margin-bottom: 4px;
    overflow: hidden;

    .inner {
        position: relative;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center center;
        transition: all 0.5s cubic-bezier(0.22, 0.44, 0, 1);

        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }

    a {
        display: block;
        height: 100%;
        width: 100%;
    }

    .content {
        position: absolute;
        bottom: 35px;
        left: 45px;
    }

    h2 {
        color: #ffffff;
        font-size: 30px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    p {
        color: #ffffff;
        font-size: 16px;
        max-width: 310px;
    }
}

.ja {
    section {
        p {
            max-width 350px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .subtitle {
        left: -60px;
        top: 160px;
    }

    .wrapper {
        margin-top: 83px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
    }

    h1 {
        font-size: 35px;
        line-height: 49px;
        margin-bottom: 80px;
        margin-left: 40px;
    }


    section {
        height: calc((258vw / 3.8));

        .content {
            left: 17px;
            bottom: 23px;
        }

        h2 {
            font-size: 16px;
        }

        p {
            font-size: 16px;
        }
    }
}
</style>
