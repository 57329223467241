<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{ 'background-image': 'url(' + this.acf.hero.background_image + ')' }"
        >
          <hgroup>
            <h1>{{ this.item.title.rendered }}</h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4 v-html="this.acf.introduction.short_description" v-scroll-reveal></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <div class="highlight">
            <div class="entry">
              <img :src="this.acf.poster[0].image" alt v-scroll-reveal />
            </div>
            <div class="wrapper">
              <div class="entry">
                <img :src="this.acf.poster[1].image" alt v-scroll-reveal />
              </div>
              <div class="entry">
                <img :src="this.acf.poster[2].image" alt v-scroll-reveal />
              </div>
            </div>
          </div>

          <div class="sub-highlight">
            <div class="entry">
              <p v-html="this.acf.screen.content_1" v-scroll-reveal></p>
              <div class="visual">
                <img :src="this.acf.screen.image_1" alt v-scroll-reveal />
              </div>
            </div>
            <div class="entry">
              <div class="visual">
                <img :src="this.acf.screen.image_2" alt v-scroll-reveal />
              </div>
              <p v-html="this.acf.screen.content_2" v-scroll-reveal></p>
            </div>
          </div>

          <div class="main-visual">
            <img :src="this.acf.large_screen" alt v-scroll-reveal />
          </div>
          <p class="main-desc" v-html="this.acf.middle_content.content_1" v-scroll-reveal></p>

          <div class="screen-thumbs">
            <p v-html="this.acf.middle_content.content_2"></p>
            <div class="wrapper" v-scroll-reveal>
              <div class="thumb" v-for="thumbnails in this.acf.thumbnails" :key="thumbnails.image">
                <img :src="thumbnails.image" alt="thumb" />
              </div>
            </div>
          </div>
        </section>

        <section class="award">
          <div class="visual-wrapper">
            <img src="/wp-content/themes/asiance/dist/img/cases/finair/awards.png" alt />
          </div>
          <hgroup>
            <h3>{{ this.acf.footer.award_title }}</h3>
            <h4>
              <p v-for="awards in this.acf.footer.awards" :key="awards.award">
                {{ awards.award }}
              </p>
            </h4>
          </hgroup>
        </section>
      </div>
    </div>
    <project-navigator ref="projectNavigator" :projectId="this.item.id" v-if="this.item.id"></project-navigator>
  </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {}
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, "finnair")
        .then(result => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
            $(".hero, [data-paroller-factor]").paroller({
              factor: 0.3,
              type: "background",
              direction: "vertical"
            });
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    }
  },

  mounted: function() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header").addClass("white");

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".highlight .entry:first, .sub-highlight .visual").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });
  }
};
</script>

<style lang="stylus" scoped>


.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-image: url('../../public/img/cases/finair/bgd_case_finair.jpg');
  justify-content: center;
  text-align: center;

  hgroup {
    color: #fff;
    max-width: 950px;
  }

  h1 {
    margin: 0 0 40px 0;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h2 {
    font-family: futura-pt;
    font-size: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    margin: 100px 0 0 0;
    line-height: 52px;
    font-size: 40px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .separator {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 0;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      height: 1px;
      width: 29px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      max-width: 577px;
      font-size: 18px;
      line-height: 23px;
      margin: 32px auto 125px auto;
    }
  }
}

.highlight {
  position: relative;
  height: calc((960vw / 14.4));

  > .entry {
    width: calc((1226vw / 14.4));
    height: calc((500vw / 14.4));

    &:nth-child(1) {
      transform: translateX(-100%);
      transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 0;

      &.active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .wrapper {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .entry {
      width: calc((597vw / 14.4));
      height: calc((400vw / 14.4));
      display: flex;
      align-items: center;

      &:nth-child(1) {
        margin-left: 80px;
      }

      &:nth-child(2) {
        width: calc((715vw / 14.4));
      }
    }
  }
}

.sub-highlight {
  margin-top: 130px;
  width: 100%;
  display: flex;
  align-content: center;

  .entry {
    width: 50%;

    &:nth-child(1), p {
      margin-bottom: 110px;

      .visual {
        transform: translateX(-100%);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    &:nth-child(2) {
      width: calc((645vw / 14.4));
      margin-top: 85px;

      .visual {
        margin-bottom: 160px;
        transform: translateX(100%);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    max-width: 470px;
    margin: auto;
  }

  .visual {
    width: 100%;
  }
}

.main-desc {
  font-family: futura-pt;
  margin: 34px auto;
  padding: 0 8px;
  max-width: 1190px;
  line-height: 52px;
  font-size: 40px;
  text-align: center;
  color: #2A3642;
}

.main-visual {
  margin-top: -100px;
  z-index: -1;
  position: relative;
}

.screen-thumbs {
  position: relative;
  width: 100%;
  padding: 0 120px;

  p {
    font-family: futura-pt, 'Noto Sans KR';
    max-width: 403px;
    margin-top: 93px;
    margin-left: 133px;
    line-height: 23px;
    font-size: 18px;
    color: #2A3642;
    font-weight: 100;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .thumb {
    &:nth-child(1) {
      margin-top: calc((90vw / 14.4));
    }

    &:nth-child(2) {
      margin-top: calc((290vw / 14.4));
      margin-left: calc((-128vw / 14.4));
      z-index: -1;
    }

    &:nth-child(3) {
      z-index: -2;
      margin-left: calc((-218vw / 14.4));
    }
  }
}

.award {
  margin: 205px 0 0 0;
  background: #F9F9F9;
  background-repeat: repeat-x;
  background-position: bottom;
  height: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;

  .visual-wrapper {
    width: 600px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }

  img {
    height: 332px;
    width: auto;
    object-fit: cover;
    margin: auto;
    margin-right: 0;
  }

  hgroup {
    // float: right;
    margin-left: -160px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      font-weight: 700;
      line-height: normal;
      font-size: 30px;
      margin-bottom: 20px;
      color: #2A3642;
    }

    h4 {
      font-family: futura-pt, 'Noto Sans KR';
      font-weight: 100;
      line-height: 38px;
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    height: 385px;
    position: relative;
    align-items: flex-end;

    hgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;
      margin-bottom: 38px;

      h1 {
        line-height: 64px;
        font-size: 50px;
        margin-bottom: 0;
      }

      h2 {
        font-family: futura-pt, 'Noto Sans KR';
        margin-top: 15px;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        text-transform: lowercase;
        letter-spacing: 0;
        text-shadow: none;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      h3 {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: normal;
        text-shadow: none;
      }
    }
  }

  .intro {
    div {
      max-width: 282px;
      margin: auto;
      padding-top: 45px;

      h3 {
        font-size: 12px;
      }

      h4 {
        font-size: 20px;
        margin-top: 18px;
      }

      p {
        margin-bottom: 75px;
      }
    }
  }

  .highlight {
    height: auto;

    .entry {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    .wrapper {
      display: block;
      margin: 0;
      margin-top: 10px;

      .entry {
        height: auto;
        width: 100% !important;
        margin: auto !important;
        margin-bottom: 10px !important;
      }
    }
  }

  .sub-highlight {
    text-align: center;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;

    .entry {
      margin: auto !important;
      width: 100% !important;
      margin-bottom: 55px;

      &:nth-child(2) {
        display: flex;
        flex-wrap: wrap;

        p {
          margin: 0 auto 80px auto;
        }

        .visual {
          margin: 60px 0;
          display: flex;
          justify-content: flex-end;
          width: 100% !important;

          img {
            width: calc((296vw / 3.8));
            height: calc((156vw / 3.8));
          }
        }
      }

      p {
        font-size: 18px;
        margin: 80px auto;
        width: 90%;
      }

      .visual {
        height: auto !important;
        width: auto !important;
      }
    }
  }

  .main-visual {
    img {
      height: auto;
      width: 100%;
    }
  }

  .main-desc {
    padding: 0;
    margin-top: 0;
    max-width: 90%;
    line-height: 28px;
    font-size: 22px;
    text-align: center;
  }

  .screen-thumbs {
    padding: 0;
    margin: auto;
    width: 90%;

    .wrapper {
      flex-wrap: wrap;
    }

    .thumb {
      width: calc((232vw / 3.8)) !important;

      &:nth-child(1) {
        margin-left: -50px;
      }

      &:nth-child(2) {
        margin-top: -35px;
        margin-left: 50px;
      }

      &:nth-child(3) {
        margin-top: -35px;
        z-index: 1;
      }
    }

    p {
      margin: auto;
      margin-top: 50px;
      width: 272px;
      text-align: center;
    }
  }

  .award {
    background-repeat: no-repeat;
    display: flex;
    margin-top: 60px;
    height: 148px;

    .visual-wrapper {
      height: 188px;
      margin-top: -50px;
      margin-left: -250px;

      img {
        height: 200px;
      }
    }

    hgroup {
      text-align: left;
      font-size: 22px;
      line-height: 28px;
      width: 121px;
      right: 75px;

      h3, h4 {
        font-size: 19px;
        line-height: 19px;
      }

      p {
        font-size: 15px;
      }
    }
  }
}
</style>
