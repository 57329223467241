<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{ 'background-image': 'url(' + this.acf.hero.background_image + ')' }"
        >
          <hgroup>
            <h1 v-html="this.item.title.rendered"></h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4 v-html="this.acf.introduction.short_description" v-scroll-reveal></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <div class="highlight">
            <div class="entry">
              <img :src="this.acf.poster[0].image" alt />
            </div>
            <div class="wrapper">
              <div class="entry">
                <img :src="this.acf.poster[1].image" alt v-scroll-reveal />
              </div>
              <div class="entry">
                <img :src="this.acf.poster[2].image" alt v-scroll-reveal />
              </div>
            </div>
          </div>

          <div class="sub-highlight">
            <div class="entry">
              <p v-html="this.acf.screen.content" v-scroll-reveal></p>
            </div>
          </div>

          <div class="main-visual">
            <img :src="this.acf.screen.image" alt v-scroll-reveal />
          </div>
          <p
            class="main-desc"
            v-if="this.acf.middle_content"
            v-html="this.acf.middle_content.content"
            v-scroll-reveal
          ></p>
        </section>

        <section
          class="footer"
          :style="{ 'background-image': 'url(' + this.acf.footer.background + ')' }"
        >
          <p v-html="this.acf.footer.content" v-scroll-reveal></p>
        </section>
      </div>
    </div>
    <project-navigator ref="projectNavigator" :projectId="this.item.id" v-if="this.item.id"></project-navigator>
  </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {}
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, "ballantines")
        .then(result => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    }
  },

  mounted: function() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header").addClass("white");

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".highlight .entry:first").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });

    setTimeout(() => {
      $(".hero, [data-paroller-factor]").paroller({
        factor: 0.3,
        type: "background",
        direction: "vertical"
      });
    }, 1000);
  }
};
</script>

<style lang="stylus" scoped>


.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  // background-image: url('../../public/img/cases/tesla/bgd_case_tesla.jpg')
  justify-content: center;
  text-align: center;

  hgroup {
    color: #fff;
    max-width: 840px;
  }

  h1 {
    margin: 0 0 40px 0;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h2 {
    font-family: futura-pt;
    font-size: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    margin: 100px 0 0 0;
    line-height: 52px;
    font-size: 40px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .separator {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 0;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      height: 1px;
      width: 29px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      max-width: 585px;
      font-size: 18px;
      line-height: 23px;
      margin: 32px auto 125px auto;
    }
  }
}

.highlight {
  position: relative;
  height: calc((960vw / 14.4));

  > .entry {
    width: calc((1226vw / 14.4));
    height: calc((500vw / 14.4));

    &:nth-child(1) {
      transform: translateX(-100%);
      transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 0;

      &.active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .wrapper {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .entry {
      width: calc((597vw / 14.4));
      height: calc((400vw / 14.4));
      display: flex;
      align-items: center;

      &:nth-child(1) {
        margin-left: 80px;
      }

      &:nth-child(2) {
        width: calc((715vw / 14.4));
      }
    }
  }
}

.sub-highlight {
  margin-top: 100px;
  width: 100%;
  display: flex;
  align-content: center;

  .entry {
    width: 50%;
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    max-width: 397px;
    margin: auto;
    color: #2A3642;
  }

  .visual {
    width: 100%;
  }
}

.main-desc {
  font-family: futura-pt;
  margin: 100px auto auto auto;
  padding: 0 8px;
  max-width: 1190px;
  line-height: 52px;
  font-size: 40px;
  text-align: center;
  color: #2A3642;
}

.main-visual {
  position: relative;
}

.footer {
  position: relative;
  margin: 100px 0 0 0;
  background: url('../../public/img/cases/tesla/footer.jpg');
  background-size: cover;
  background-position: bottom;
  height: calc((530vw / 14.4));

  p {
    font-family: futura-pt, 'Noto Sans KR';
    position: absolute;
    max-width: 454px;
    top: calc((51vw / 14.4));
    right: calc((180vw / 14.4));
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    color: #FFF;
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    height: 385px;
    position: relative;
    align-items: flex-end;

    hgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;
      margin-bottom: 38px;

      h1 {
        line-height: 64px;
        font-size: 27px;
        margin-bottom: 0;
      }

      h2 {
        font-family: futura-pt, 'Noto Sans KR';
        margin-top: 15px;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        text-transform: lowercase;
        letter-spacing: 0;
        text-shadow: none;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      h3 {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: normal;
        text-shadow: none;
      }
    }
  }

  .intro {
    div {
      max-width: 282px;
      margin: auto;
      padding-top: 45px;

      h3 {
        font-size: 12px;
      }

      h4 {
        font-size: 20px;
        margin-top: 18px;
      }

      p {
        margin-bottom: 75px;
      }
    }
  }

  .highlight {
    height: auto;

    .entry {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    .wrapper {
      display: block;
      margin: 0;

      .entry {
        height: auto;
        width: 100% !important;
        margin: 0 !important;
        margin-bottom: 10px !important;
      }
    }
  }

  .sub-highlight {
    margin: 50px auto auto auto;
    text-align: center;

    .entry {
      margin: auto;
      width: 85%;
      margin-bottom: 55px;
    }
  }

  .main-visual {
    img {
      height: auto;
      width: 100%;
    }
  }

  .main-desc {
    padding: 0;
    margin-top: 45px;
    max-width: 90%;
    line-height: 28px;
    font-size: 22px;
    text-align: center;
  }

  .footer {
    margin-top: 45px;
    height: calc((800vw / 3.8));
    max-height: 800px;
    background-size: auto 420px;
    background-repeat: no-repeat;

    p {
      position: initial;
      max-width: 90%;
      margin: auto;
      text-align: center;
      color: #2a3642;
    }
  }
}
</style>
