<template>
    <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content" :attr-sub="$t('follow_us')">
    <div class="wrapper">
        <h1 v-html="$t('latest_news')"></h1>
        <div id="instafeed" class="entries"></div>
        <p class="error-message">{{ $t("try_later") }}</p>
        <LoadingSpinner v-if="this.isFeedLoading"></LoadingSpinner>
    </div>
    </div>
    </div>
</template>

<script>
//import VueInstagram from 'vue-instagram'
import InstagramService from "../services/InstagramService";

const LoadingScreen = () =>
  import(/* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue");

const LoadingSpinner = () =>
  import(/* webpackChunkName: "below-fold" */ "../components/LoadingSpinner.vue");

export default {
  components: {
    LoadingScreen,
    LoadingSpinner
    //VueInstagram
  },

  data() {
    return {
      isLoading: true,
      isFeedLoading: true,
      instaFeed: {},
      after: '',
      data: [],
      busy: false
    };
  },

  created() {
    // this.fetchItem(this.$i18n.locale);
  },

  computed: {},

  mounted: function() {
    setTimeout(() => {
      this.isLoading = false;
      this.$parent.updateSub();
    }, 1000);

    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").show();
    $(".subtitle:first").removeClass("white");

    $("header").removeClass();
    $("header").addClass("black");
    $("header:first").addClass("fixed");

    this.fetchFeeds();

    $(window).on("scroll", function() {
      if(($(window).scrollTop() === $(document).height() - $(window).height()) && !this.isFeedLoading) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.after) {
          this.fetchFeeds(this.after);
        }
      }
    }.bind(this));
  },

  methods: {
    fetchItem() {
      this.isLoading = false;
      this.$parent.updateSub();
    },
    loadMore: function() {
      this.busy = true;
    },
    fetchFeeds: function(after) {
      this.isFeedLoading = true;
      InstagramService.getFeeds(after).then((response) => {
        this.isFeedLoading = false;
        const feeds = response.body;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!feeds) {
          this.after = feeds.after; 
          for (let feed of feeds.data) {
            // eslint-disable-next-line no-extra-boolean-cast
            const url = !!feed.thumbnail_url ? feed.thumbnail_url : feed.media_url
            const template = `
              <div class="entry instagram">
                <a href="${feed.permalink}" target="_blank" style="background-image: url(${url})">
                  <div class="caption">${feed.caption.length > 400 ? feed.caption.slice(0, 400) + "..." : feed.caption}</div>
                </a>
              </div>
            `;
            $("#instafeed").append(template);
          }
        } else {
          $("#instafeed .error-message").show();
        }
      })
    }
  }
};
</script>

<style lang="stylus" scoped>


.wrapper {
    max-width: calc((1242vw / 14.4));
    margin: 200px auto 50px auto;
}

h1 {
    font-family: futura-pt, 'Noto Sans KR';
    font-size: calc(90vw/14.4);
    line-height: calc(116vw/14.4);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    margin-left: calc((80vw / 14.4));
}

.subtitle {
    z-index: 999;
    position: absolute;
    color: #000;
    transform: rotate(-90deg);
    position: fixed;
    top: 190px;
    left: -10px;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 0.3em;
    text-transform: uppercase;

    span {
        display: inline-block;
        width: 47px;
        height: 1px;
        background-size: 1px;
        background-color: #000;
        margin-top: 6px;
        margin-left: 10px;
        float: right;
    }
}

.error-message {
  display: none;
}

/deep/ .entries {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;

    .images {
        display: flex;
        flex: 1;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .entry {
        position: relative;
        flex: 1;
        height: calc((320vw / 14.4));
        max-width: calc((300vw / 14.4));
        min-width: calc((300vw / 14.4));
        margin-bottom: calc((15vw / 14.4));
        overflow: hidden;

        &:hover {
            a {
                transform: scale(1.08);

                .caption {
                    opacity: 1;
                }
            }
        }

        a {
            display: block;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center center;
            transition: all 4s cubic-bezier(0.22, 0.44, 0, 1);
        }

        .caption {
            overflow: hidden;
            opacity: 0;
            height: 100%;
            width: 100%;
            background: rgba(255, 255, 255, 0.7);
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 20px;
            color: #3B434D;
            font-size: 12px;
            transition: opacity 1s cubic-bezier(0.22, 0.44, 0, 1);
            text-align: center;
            font-weight: normal;

        }
    }

    .large {
        max-width: 100%;
    }

    .logo {
        position: absolute;
        bottom: 40px;
        right: 40px;
        display: none;
    }

    .play {
        width: 80px;
        margin: auto;
        margin-top: calc((212vw / 14.4));
    }
}

@media only screen and (max-width: 1024px) {
    .subtitle {
        left: -60px;
        top: 160px;
    }

    .wrapper {
        margin-top: 83px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
    }

    h1 {
        font-size: 42px;
        line-height: 49px;
        margin-bottom: 80px;
        margin-left: 40px;
    }

    /deep/ .entries {
        .entry {
            // max-width: 49.5%
            height: calc((186vw / 3.8));
            width: calc((186vw / 3.8));
            max-width: calc((186vw / 3.8));
            min-width: calc((186vw / 3.8));
            margin-bottom: calc((20vw / 14.4));

            .logo {
                transform: scale(0.7);
                bottom: 5px;
                right: 5px;
            }
        }

        .large {
            max-width: 100%;

            .logo {
                transform: scale(0.7);
                bottom: 5px;
                right: 5px;
            }

            .play {
                transform: scale(0.5);
            }
        }
    }
}
</style>
