<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{
            'background-image': 'url(' + this.acf.hero.background_image + ')',
          }"
        >
          <hgroup>
            <h1>{{ this.item.title.rendered }}</h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4
              v-html="this.acf.introduction.short_description"
              v-scroll-reveal
            ></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <div class="highlight">
            <div class="visual">
              <img :src="this.acf.poster" alt="" v-scroll-reveal />
            </div>
          </div>

          <div class="sub-highlight">
            <div class="splash-screen">
              <img :src="this.acf.screen.image" alt="splash" v-scroll-reveal />
            </div>
            <div class="text-content">
              <p v-html="this.acf.screen.content" v-scroll-reveal></p>
            </div>
          </div>
          <div class="sub-highlight2">
            <div class="splash-screen">
              <img
                :src="this.acf.screen.image_2"
                alt="splash"
                v-scroll-reveal
              />
            </div>
            <div class="text-content">
              <p v-html="this.acf.screen.content_2" v-scroll-reveal></p>
            </div>
          </div>
          <div class="main-visual">
            <p v-html="this.acf.footer.text" v-scroll-reveal></p>
          </div>
        </section>
      </div>
    </div>
    <project-navigator
      ref="projectNavigator"
      :projectId="this.item.id"
      v-if="this.item.id"
    ></project-navigator>
  </div>
</template>

<script>
import PostSlugService from '../services/PostSlugService';
import ProjectNavigator from '../components/ProjectNavigator.vue';

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ '../components/LoadingScreen.vue'
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
      height: 500,
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, 'exotec')
        .then((result) => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
            $('.hero').paroller({
              factor: 0.3,
              type: 'background',
              direction: 'vertical',
            });
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },

  mounted: function () {
    if ($('body').css('overflow') == 'hidden') {
      $('#fullpage').fullpage.destroy('all');
    }

    $('.subtitle:first').hide();
    $('header').removeClass();
    $('header').addClass('white');

    var Utils = this.$parent.scrollReveal();

    $(document).on('scroll', () => {
      $('.highlight').each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass('active');
        }
      });
    });
  },
};
</script>

<style lang="stylus" scoped>

.web-display{
  display: block;
}
.mobile-display{
  display: none;
}
@media only screen and (max-width: 1024px) {
  .web-display{
  display: none;
}
.mobile-display{
  display: block;
}
}
.hero {
    display: flex;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    justify-content: center;
    text-align: center;

    hgroup {
        color: #fff;
        max-width: 950px;
    }

    h1 {
        margin: 0 0 40px 0;
        font-size: 80px;
        text-align: center;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    h2 {
        font-family: futura-pt;
        font-size: 16px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    h3 {
        margin: 100px 0 0 0;
        line-height: 52px;
        font-size: 40px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .separator {
        transform: rotate(-90deg);
        position: absolute;
        bottom: 0;
        width: 60px;
        left: 0;
        right: 0;
        margin: auto;

        span {
            float: right;
            height: 1px;
            width: 29px;
            background: #fff;
        }

        span.black {
            background: #2A3642;
        }
    }
}
.intro {
    div {
        background: #FFF;
        text-align: center;
        width: 100%;
        padding-top: 62px;

        h3 {
            font-family: futura-pt, 'Noto Sans KR';
            line-height: 30px;
            font-size: 20px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            max-width: 415px;
            margin: auto;
            letter-spacing: 0.3em;
            font-weight: normal;
        }

        h4 {
            margin-top: 35px;
            font-family: futura-pt;
            line-height: normal;
            font-size: 26px;
            text-align: center;
            font-weight: normal;
        }

        p {
            max-width: 632px;
            font-size: 18px;
            line-height: 23px;
            margin: 32px auto 125px auto;
        }
    }
}

.highlight {
    transform: translateX(100%);
    transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    opacity: 0;
    margin-bottom: 50px;
    &.active {
        transform: translateX(0);
        opacity: 1;
    }

    .visual{
      img{
        width: auto;
        margin: 0 auto;
      }
    }

    p {
        font-family: futura-pt, 'Noto Sans KR';
        position: absolute;
        bottom: 0;
        right: calc((168vw / 14.4));
        max-width: 453px;
        line-height: 27px;
        font-size: 22px;
        font-weight: 100;
        color: #2A3642;
    }
}

.main-desc {
    font-family: futura-pt;
    margin: 90px auto 112px auto;
    padding: 0;
    max-width: 727px;
    line-height: 52px;
    font-size: 40px;
    text-align: center;
    color: #2A3642;
}

.main-visual {
  p{
      font-size: 18px;
      line-height: 23px;
      font-weight: 100;
      text-align: center;
  }
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: calc((100vw / 14.4)) 0;
}

.sub-description{
    position relative;
}
.sub-desc {
    max-width: 491px;
    position: absolute;
    top: calc((50vw / 14.4));
    left: calc((194vw / 14.4));

    p {
        font-family: futura-pt, 'Noto Sans KR';
        line-height: 23px;
        font-size: 18px;
        font-weight: 100;
        margin: 0;
    }
}

.sub-highlight {
    padding: 0 calc((118vw / 14.4));
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom:  calc((100vw / 14.4));

    .splash-screen {
        width: calc((741vw / 14.4));
        max-width 741px;
        order: 1;
    }

    .text-content {
        order: 2;
        width: 100%;
        max-width: 462px;
    }
}
.sub-highlight2{
    padding: 0 calc((118vw / 14.4));
    display: flex;
    justify-content: space-around;
    align-items: center;
    .splash-screen {
        order: 2;
    }

    .text-content {
        order: 1;
        width: 100%;
        max-width: 404px;
    }
}

p {
    font-family: futura-pt, 'Noto Sans KR';
    font-weight: 100;
    line-height: 23px;
    font-size: 18px;
    color: #2A3642;
}

.thumbnails {
    position: relative;
    top: -5%;
    right: 0;
    width: calc((1440vw / 14.4));
    max-width 100%;
}
@media only screen and (max-width: 1200px) {
.sub-highlight {
    .text-content{
        p{
            left: calc((-30vw / 14.4));
        }
    }
}
.thumbnails{
    padding-top: 150px;
}
}
@media only screen and (max-width: 1024px) {
    .hero {
        height: 385px;
        position: relative;
        align-items: flex-end;

        hgroup {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            max-width: 90%;
            margin-bottom: 38px;

            h1 {
                line-height: 64px;
                font-size: 50px;
                margin-bottom: 0;
            }

            h2 {
                font-family: futura-pt, 'Noto Sans KR';
                margin-top: 15px;
                font-size: 16px;
                line-height: normal;
                font-weight: 100;
                text-transform: lowercase;
                letter-spacing: 0;
                text-shadow: none;

                &:first-letter {
                    text-transform: uppercase;
                }
            }

            h3 {
                margin-top: 20px;
                font-size: 20px;
                letter-spacing: 0.1em;
                line-height: normal;
                text-shadow: none;
            }
        }
    }

    .intro {
        div {
            max-width: 282px;
            margin: auto;
            padding-top: 45px;

            h3 {
                font-size: 12px;
            }

            h4 {
                font-size: 20px;
                margin-top: 18px;
            }

            p {
                margin-bottom: 75px;
            }
        }
    }

    .sub-highlight {
        text-align: center;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 0;
        top: 0;
        .splash-screen {
            margin: auto;
            max-width: 80%;
            width: auto;
        }

        .text-content {
            margin: auto;
            margin-top: 0;
            width: 90%;

            p{
              margin-top: 40px;
              margin-bottom: 50px;
            }

            img {
                width: 150px;
                margin: auto;
                float: right;
                position:relative;
            }
        }
    }

    .sub-highlight2 {
        text-align: center;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 0;
        top: 0;

        .splash-screen {
            margin: auto;
            max-width: 369px;
            order: 1;
        }

        .text-content {
            margin: auto;
            margin-top: 0;
            width: 90%;
            order: 2;

            p{
              margin-top: 40px;
              margin-bottom: 50px;
            }

            img {
                width: 150px;
                margin: auto;
                float: right;
                position:relative;
            }
        }
    }

     .main-visual {
      padding-top: 0;
      p{
          font-size: 18px;
          line-height: 23px;
          width: auto;
          max-width: 426px;
          text-align: center;
          margin: 0 auto 40px;
          padding: 0 20px;
      }
    }

    .main-desc {
        padding: 0;
        margin-top: 45px;
        margin-bottom: 40px;
        max-width: 268px;
        line-height: 28px;
        font-size: 22px;
        text-align: center;
    }

    .sub-desc {
        max-width: 90%;
        margin: 30px auto 10px;
        text-align: center;
        position: static;
    }
    .thumbnails{
        position: static;
        padding-top: 20px;
        img{
          max-width: 375px;
          width: calc((375vw / 3.8));
          margin: 0 auto;
        }
    }
}
@media only screen and (max-width: 821px) {
  .sub-highlight {
    .text-content{
      img{
        top: 30px;
      }
    }
  }
}
</style>
<style lang="stylus">
br.mobile-only{
  content:" ";
}
br.web-only{
    content: none;
}
@media only screen and (max-width: 30rem){
    br.mobile-only{
      content: none;
    }
    br.web-only{
      content:" ";
    }
}
</style>
