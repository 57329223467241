<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{ 'background-image': 'url(' + this.acf.hero.background_image + ')' }"
        >
          <hgroup>
            <h1>{{ this.item.title.rendered }}</h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4 v-html="this.acf.introduction.short_description" v-scroll-reveal></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <div class="highlight">
            <div class="entry">
              <img :src="this.acf.poster[0].image" alt v-scroll-reveal />
            </div>
            <div class="wrapper">
              <div class="entry">
                <img :src="this.acf.poster[1].image" alt v-scroll-reveal />
              </div>
              <div class="entry">
                <img :src="this.acf.poster[2].image" alt v-scroll-reveal />
              </div>
            </div>
          </div>

          <div class="sub-highlight">
            <div class="entry">
              <p v-html="this.acf.screen.content_1" v-scroll-reveal></p>
            </div>
          </div>

          <div class="main-visual" v-scroll-reveal>
            <img :src="this.acf.screen.image_1" alt />
          </div>

          <div class="sub-main">
            <div class="wrapper">
              <div>
                <p class="main" v-html="this.acf.middle_content.content_1" v-scroll-reveal></p>
              </div>
              <div>
                <img :src="this.acf.middle_content.image" alt />
              </div>
            </div>
          </div>
          <div class="main-desc">
            <p class="bottom" v-html="this.acf.middle_content.content_3" v-scroll-reveal></p>
          </div>
        </section>

        <section class="award">
          <img src="/wp-content/themes/asiance/dist/img/cases/award.png" alt />
          <hgroup>
            <h3>{{ this.acf.footer.award_title }}</h3>
            <h4>
              <p v-for="awards in this.acf.footer.awards" :key="awards.award">
                {{ awards.award }}
              </p>
            </h4>
          </hgroup>
        </section>
      </div>
    </div>
    <project-navigator ref="projectNavigator" :projectId="this.item.id" v-if="this.item.id"></project-navigator>
  </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {}
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, "3m")
        .then(result => {
          setTimeout(() => {
            this.isLoading = false;
            $(".hero, [data-paroller-factor]").paroller({
              factor: 0.3,
              type: "background",
              direction: "vertical"
            });
          }, 500);
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    }
  },

  mounted: function() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header:first").addClass("fixed");

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".highlight .entry:first, .sub-main .wrapper div:last").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });

    setTimeout(() => {
      $(".main-visual img").paroller({
        factor: 0.05,
        type: "foreground",
        direction: "vertical"
      });

      /*
        $(".sub-main div:last img").paroller({
            factor: 0.1,
            type: 'foreground',
            direction: 'vertical'
        });
        */
    }, 1000);
  }
};
</script>

<style lang="stylus" scoped>

.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-image: url('../../public/img/cases/3m/bgd_case_3m.jpg');
  justify-content: center;
  text-align: center;

  hgroup {
    color: #fff;
    max-width: 950px;
  }

  h1 {
    margin: 0 0 40px 0;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h2 {
    font-family: futura-pt;
    font-size: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    margin: 100px 0 0 0;
    line-height: 52px;
    font-size: 40px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .separator {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 0;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      height: 1px;
      width: 29px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      max-width: 577px;
      font-size: 18px;
      line-height: 23px;
      margin: 32px auto 125px auto;
    }
  }
}

.highlight {
  position: relative;

  // height: calc(960vw/14.4)
  > .entry {
    width: calc((1320vw / 14.4));

    // height: calc(500vw/14.4)
    &:nth-child(1) {
      transform: translateX(-100%);
      transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 0;

      &.active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .wrapper {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .entry {
      width: calc((608vw / 14.4));
      height: calc((400vw / 14.4));
      display: flex;
      align-items: center;
      overflow: hidden;

      &:nth-child(1) {
        margin-left: 80px;
      }

      &:nth-child(2) {
        width: calc((746vw / 14.4));
      }
    }
  }
}

.sub-highlight {
  position: relative;
  margin-top: 130px;
  padding: 0 119px;
  width: 100%;
  display: flex;
  align-content: center;

  .entry {
    width: calc((603vw / 14.4));

    &:nth-child(1) {
      p {
        margin-bottom: 110px;
      }
    }

    .visual {
      width: calc((603vw / 14.4));
      height: calc((963vw / 14.4));
      margin-left: auto;
      margin-right: auto;
    }

    &:nth-child(2) {
      width: calc((396vw / 14.4));
      margin-left: calc((119vw / 14.4));
      margin-top: -58px;

      .visual {
        width: calc((396vw / 14.4));
        height: calc((510vw / 14.4));
      }

      p {
        margin: 120px 0 0 0;
      }
    }
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    max-width: 532px;
    margin: auto;
  }

  .visual {
    width: 100%;
  }

  .visual-bag {
    position: absolute;
    bottom: 0;
    right: calc((203vw / 14.4));
    width: calc((424vw / 14.4));
    height: calc((354vw / 14.4));
  }
}

.main-desc {
  padding: 150px 0 230px 0;
  display: flex;
  align-items: center;
  background-image: url('../../public/img/cases/3m/bgd_sub_main.jpg');
  background-size: 100% calc((1060vw / 14.4));
  background-repeat: no-repeat;
  background-position: center center;
  font-family: futura-pt;
  line-height: 23px;
  font-size: 18px;
  text-align: center;
  color: #FFF;

  p {
    max-width: 693px;
    margin: auto;
  }
}

.main-visual {
  position: relative;
  background-image: url('../../public/img/cases/3m/bgd_main_visual.jpg');
  background-size: calc((1300vw / 14.4)) 500px;
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: center;

  // height: calc(500vw/14.4)
  img {
    position: relative;
    margin: auto;
    top: 55px;
    width: calc((991vw / 14.4));
  }

  .wrapper {
    width: calc((488vw / 14.4));
    padding: 0 0 0 calc((109vw / 14.4));
  }

  p {
    font-family: futura-pt;
    line-height: 52px;
    font-size: 40px;
    max-width: 411px;
  }
}

.sub-main {
  position: relative;
  padding: 140px 0 0 128px;
  margin: 0 0 95px 0;

  .wrapper {
    display: flex;
    flex-wrap: wrap;

    div {
      width: 50%;

      &:nth-child(3) {
        p {
          font-family: futura-pt, 'Noto Sans KR';
          line-height: 23px;
          font-size: 18px;
          color: #2A3642;
          margin: 0;
          font-weight: 100;
          max-width: 492px;
          margin-top: calc((190vw / 14.4));
        }
      }

      &:last-child {
        width: 100%;
        transform: translateX(100%);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;
        margin-top: -400px;

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    p {
      font-family: futura-pt;
      line-height: 23px;
      font-size: 18px;
      color: #fff;
      max-width: 537px;
      margin-top: 85px;
      max-width: 448px;
    }

    .main {
      line-height: 52px;
      font-size: 40px;
      color: #2A3642;
      max-width: 100%;
      margin-top: 0;
    }
  }

  img {
    width: calc((575vw / 14.4));
    float: right;
    margin-top: -130px;
  }
}

.award {
  margin: 0;
  background: #F9F9F9;
  background-repeat: repeat-x;
  background-position: bottom;
  height: 286px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: -100px;
    height: 360px;
    width: auto;
  }

  hgroup {
    margin-left: 100px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      font-weight: 700;
      line-height: normal;
      font-size: 30px;
      color: #2A3642;
      margin-bottom: 22px;
    }

    h4 {
      font-family: futura-pt, 'Noto Sans KR';
      font-weight: 100;
      line-height: 42px;
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 1344px) {
  .sub-main {
    .wrapper {
      .main {
        font-size: 27px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    height: 385px;
    position: relative;
    align-items: flex-end;

    hgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;
      margin-bottom: 38px;

      h1 {
        line-height: 64px;
        font-size: 50px;
        margin-bottom: 0;
      }

      h2 {
        font-family: futura-pt, 'Noto Sans KR';
        margin-top: 15px;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        text-transform: lowercase;
        letter-spacing: 0;
        text-shadow: none;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      h3 {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: normal;
        text-shadow: none;
      }
    }
  }

  .intro {
    div {
      max-width: 282px;
      margin: auto;
      padding-top: 45px;

      h3 {
        font-size: 12px;
      }

      h4 {
        font-size: 20px;
        margin-top: 18px;
      }

      p {
        margin-bottom: 75px;
      }
    }
  }

  .highlight {
    height: auto;

    .entry {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    .wrapper {
      display: block;
      margin: 0;
      margin-top: 0;

      .entry {
        height: auto;
        width: 100% !important;
        margin: auto !important;
        margin-bottom: 10px !important;
      }
    }
  }

  .sub-highlight {
    text-align: center;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;

    .entry {
      margin: auto !important;
      width: 90% !important;
      margin-bottom: 55px;

      &:nth-child(1) {
        order: 2;

        p {
          margin-bottom: 0;
          margin-top: 20px;
        }
      }

      &:nth-child(2) {
        order: 1;
        display: flex;
        flex-wrap: wrap;

        p {
          order: 1;
          margin: 0 auto 80px auto;
        }

        .visual {
          order: 2;
        }
      }

      p {
        font-size: 18px;
        margin: 80px auto;
      }

      .visual {
        height: auto !important;
        width: auto !important;
      }
    }
  }

  .main-visual {
    margin-top: 35px;
    background-size: 100% calc((285vw / 3.8));
    top: 0;

    img {
      top: 0;
      width: auto;
    }
  }

  .sub-main {
    margin: auto;
    margin-top: 80px;
    background: none;
    padding: 0;

    .wrapper {
      display: flex;
      flex-direction: column;

      p {
        font-family: futura-pt, 'Noto Sans KR';
        max-width: 100% !important;
        text-align: center;
        margin: auto !important;
        font-weight: 100;
      }

      div {
        width: 100%;
      }

      .main {
        line-height: 28px;
        font-size: 22px;
        text-align: center;
      }

      div {
        &:last-child {
          margin-top: 0;

          img {
            margin: 75px 0;
            width: 80%;
          }
        }
      }
    }
  }

  .main-desc {
    height: auto;
    padding: 105px 0;
    margin: 0 auto;
    line-height: 23px;
    font-size: 18px;
    text-align: center;
    background-size: cover;

    p {
      padding: 0;
      margin: 0 auto;
      max-width: 90%;
    }
  }

  .award {
    background-repeat: no-repeat;
    height: 155px;

    img {
      height: 205px;
      margin-top: -57px;
      margin-left: calc((-33vw / 3.8));
    }

    hgroup {
      margin-left: 0;
      max-width: 95%;
      margin-left: 30px;

      h3 {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
}

@media screen and (max-width: 320px){
  .sub-main {
    .wrapper {
      .main {
        font-size: 18px;
      }
    }
  }
}
</style>
