<template>
  <div class="notFound">
    <hgroup>
        <h1>404</h1>
        <h2>{{ $t("not_exist") }}</h2>
        <a href="/">{{ $t("go_to_main") }}</a>
    </hgroup>
  </div>
</template>

<script>

export default {}

</script>

<style lang="stylus" scoped>


.notFound {
    font-family: futura-pt, 'Noto Sans KR';
    background-image: url('../../public/img/404.jpg');
    background-size: cover;
    background-position: center center;
    display: flex;
    padding-top: 338px;
    padding-left: calc(232vw/14.4);

    hgroup {
      h1 {
        color: #fff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        line-height: 116px;
        font-size: 90px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
      }

      h2 {
         color: #fff;
         text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
         line-height: 52px;
          font-size: 40px;
          letter-spacing: 0.3em;
          text-transform: uppercase;
          margin-bottom: 50px;
      }

      a {
        color: #fff;
        border-bottom: 1px solid #fff;
        padding-bottom: 5px;
        text-transform: uppercase;
        font-weight: 100;
      }
    }
  }
</style>
