import Vue from 'vue';

export default {
  get() {
    return Vue.http.get("https://www.instagram.com/asiance_now/?__a=1");
  },
  getFeeds(after) {
    return Vue.http.get(`${process.env.VUE_APP_WP_HOME}/wp-json/wp/v2/instafeed/${after}`);
  }
};
