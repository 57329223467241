<template>
    <section>
        <a href="https://asiancenotion.oopy.io" target="_blank" @click.native="onClick">
            <div class="banner" v-scroll-reveal>
                <div class="wrapper">
                    <h2 v-html="$t('notion_main_phrase')" :class="this.$i18n.locale === 'ja' ? 'more-letter-spacing' : ''"></h2>
                    <p v-html="$t('notion_sub_phrase')"></p>
                </div>
            </div>
        </a>
    </section>
</template>

<script>
export default {
  props: {
    onClick: { type: Function },
  }
}
</script>

<style lang="stylus" scoped>

section {
    padding: calc(90vw / 14.4);

    .banner {        
        background-image: url('../../public/img/joinus_banner.jpg');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: cover;
        cursor: pointer;

        .wrapper {
            padding: calc(36vw / 14.4) calc(72vw / 14.4);

            h2 {
                font-family: futura-pt, 'Noto Sans KR';
                color: #fff;
                font-size: calc(36vw / 14.4);
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: calc(2vw / 14.4);
                text-transform: uppercase;

                &.font-pink {
                  color: #F33350;
                }
            }

            p {
                font-family: futura-pt, 'Noto Sans KR';
                color: #fff;
                font-size: calc(20vw / 14.4);
                line-height: 1.3;
                margin-top: calc(10vw / 14.4);

                &:after {
                  content: url('../../public/img/arrow.svg');
                  display: inline-block;
                  transform: translateX(12px);
                  transition: transform 0.3s cubic-bezier(0.22, 0.44, 0, 1);
                }
            }
        }

        &:hover {
            .wrapper p:after {
              transform: translateX(24px);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    section {
        padding: calc(30vw / 3.2) calc(20vw / 3.2);

        .banner {
            background-image: url('../../public/img/joinus_banner_mobile.png');
            background-position: right bottom;     

            .wrapper {
                height: calc(46vw * 1.128);
                padding: calc(25vw / 3.2) calc(45vw / 3.2) calc(250vw / 3.2) calc(22vw / 3.2);

                h2 {
                    font-size: calc(22vw / 3.2);
                    letter-spacing: normal;

                    &.more-letter-spacing {
                      letter-spacing: calc(2vw / 3.2);
                    }
                }

                p {
                    font-size: calc(12vw / 3.2);
                    line-height: 1.3;
                    margin-top: calc(20vw / 3.2);
                }
            }
        }
    }
}
</style>
