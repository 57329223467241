<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{ 'background-image': 'url(' + this.acf.hero.background_image + ')' }"
        >
          <hgroup>
            <h1>{{ this.item.title.rendered }}</h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4 v-html="this.acf.introduction.short_description" v-scroll-reveal></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <div class="highlight">
            <div
              class="visual"
              :style="{ 'background-image': 'url(' + this.acf.poster + ')' }"
              v-scroll-reveal
            ></div>
          </div>

          <div class="sub-highlight">
            <div class="splash-screen">
              <img :src="this.acf.screen.image" alt="splash" v-scroll-reveal />
            </div>
            <div class="las_vegas_logo">
              <p v-html="this.acf.screen.content" v-scroll-reveal></p>
              <img
                src="/wp-content/themes/asiance/dist/img/cases/las_vegas/logo_las_vegas.png"
                alt="Las Vegas"
                v-scroll-reveal
              />
            </div>
          </div>

          <div class="video" v-scroll-reveal>
            <div class="video-wrapper">
              <iframe
                :src="this.acf.video"
                style="position:absolute;left:0;top:0;width:100%;height:100%;"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <p class="main-desc" v-html="this.acf.middle_content.content_1"></p>

          <p class="sub-desc" v-html="this.acf.middle_content.content_2"></p>

          <div class="screen-thumbs">
            <div class="wrapper">
              <div class="thumb" v-for="thumbnails in this.acf.thumbnails" :key="thumbnails.image">
                <img :src="thumbnails.image" alt="thumb" v-scroll-reveal />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <project-navigator ref="projectNavigator" :projectId="this.item.id" v-if="this.item.id"></project-navigator>
  </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
      height: 500
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, "las-vegas")
        .then(result => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
            $(".hero").paroller({
              factor: 0.3,
              type: "background",
              direction: "vertical"
            });
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    },
    playPause() {
      if ($(".play-btn svg").css("display") == "none") {
        this.$refs.player.pause();
        $(".play-btn svg").show();
      } else {
        this.$refs.player.play();
        $(".play-btn svg").hide();
      }
    }
  },

  mounted: function() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header").addClass("white");

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".highlight").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });

    setTimeout(() => {
      $(".las_vegas_logo img").paroller({
        factor: 0.1,
        type: "foreground",
        direction: "vertical"
      });
    }, 1000);

    var timeoutId;
    var $videoBgAspect = $(".videobg-aspect");
    var $videoBgWidth = $(".videobg-width");
    var videoAspect =
      $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

    function videobgEnlarge() {
      var windowAspect = $(window).height() / $(window).width();

      if (windowAspect > videoAspect) {
        $videoBgWidth.width((windowAspect / videoAspect) * 100 + "%");
      } else {
        $videoBgWidth.width(100 + "%");
      }
    }

    $(window).resize(function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(videobgEnlarge, 100);
    });

    $(function() {
      videobgEnlarge();
    });
  }
};
</script>

<style lang="stylus" scoped>


.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-image: url('../../public/img/bgd_case_chanel.jpg');
  justify-content: center;
  text-align: center;

  hgroup {
    color: #fff;
    max-width: 950px;
  }

  h1 {
    margin: 0 0 40px 0;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h2 {
    font-family: futura-pt;
    font-size: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    margin: 100px 0 0 0;
    line-height: 52px;
    font-size: 40px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .separator {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 0;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      height: 1px;
      width: 29px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      max-width: 632px;
      font-size: 18px;
      line-height: 23px;
      margin: 32px auto 125px auto;
    }
  }
}

.highlight {
  position: relative;
  height: calc((657vw / 14.4));
  transform: translateX(100%);
  transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  .visual {
    position: absolute;
    right: 0;
    background-size: cover;
    width: calc((1320vw / 14.4));
    height: calc((517vw / 14.4));
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    position: absolute;
    bottom: 0;
    right: calc((168vw / 14.4));
    max-width: 453px;
    line-height: 27px;
    font-size: 22px;
    font-weight: 100;
    color: #2A3642;
  }
}

.main-desc {
  font-family: futura-pt;
  margin: 90px auto 112px auto;
  padding: 0 25px;
  max-width: 1190px;
  line-height: 52px;
  font-size: 40px;
  text-align: center;
  color: #2A3642;
}

.sub-desc {
  max-width: 491px;
  margin-left: calc((194vw / 14.4));
  margin-bottom: 118px;

  p {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    margin: 0;
  }
}

.sub-highlight {
  margin-top: 33px;
  padding: 0 calc((233vw / 14.4));
  display: flex;
  justify-content: space-between;
  align-content: center;

  .spash-screen {
    width: calc((451vw / 14.4));
  }

  .las_vegas_logo {
    width: calc((453vw / 14.4));
    max-width: 453px;
    margin-top: 107px;

    img {
      width: calc((381vw / 14.4));
    }
  }
}

p {
  font-family: futura-pt, 'Noto Sans KR';
  font-weight: 100;
  line-height: 23px;
  font-size: 18px;
  margin-bottom: 55px;
  color: #2A3642;
}

.video {
  width: 90%;
  // height: calc((537vw / 14.4));
  cursor: pointer;
  margin: auto;
  text-align: center;

  iframe {
    margin: auto;
  }
}

.video-wrapper {
  padding: 42.25% 0 0 0;
  position: relative;
}

.videobg {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #111;
}

.videobg-width {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.videobg-aspect {
  position: absolute;
  width: 100%;
  height: 0;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.videobg-make-height {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/deep/ #vimeo-player-1 {
  box-sizing: content-box;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 55px 97.7777px;
  top: -55px;
  left: -97.7777px;
  overflow: hidden;
}

/deep/ .videobg iframe {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  border: 0 none;
}

.play-btn {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 5;
  display: none;
  justify-content: center;
  align-items: center;
}

.screen-thumbs {
  position: relative;
  width: 100%;
  padding: 0 120px;
  height: calc((825vw / 14.4));
  background-color: #212121;
  background-size: calc((946vw / 14.4));
  background-repeat: no-repeat;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .thumb {
      width: calc((366vw / 14.4));
      height: calc((650vw / 14.4));
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    height: 385px;
    position: relative;
    align-items: flex-end;

    hgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;
      margin-bottom: 38px;

      h1 {
        line-height: 64px;
        font-size: 50px;
        margin-bottom: 0;
      }

      h2 {
        font-family: futura-pt, 'Noto Sans KR';
        margin-top: 15px;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        text-transform: lowercase;
        letter-spacing: 0;
        text-shadow: none;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      h3 {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: normal;
        text-shadow: none;
      }
    }
  }

  .intro {
    div {
      max-width: 282px;
      margin: auto;
      padding-top: 45px;

      h3 {
        font-size: 12px;
      }

      h4 {
        font-size: 20px;
        margin-top: 18px;
      }

      p {
        margin-bottom: 75px;
      }
    }
  }

  .highlight {
    height: calc((407vw / 3.8));

    .visual {
      height: calc((323vw / 3.8));
      width: 100%;
      background-position: center;
    }
  }

  .sub-highlight {
    text-align: center;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;

    .splash-screen {
      margin: auto;
      max-width: 296px;
    }

    .las_vegas_logo {
      margin: auto;
      margin-top: 0;
      width: 90%;

      img {
        width: 207px;
        margin: auto;
      }
    }
  }

  .video {
    height: calc((213vw / 3.8));
  }

  .video-wrapper {
    padding: 56.25% 0 0 0;
  }

  .play-btn {
    display: flex;

    svg {
      transform: scale(0.5);
    }
  }

  .main-visual {
    img {
      height: auto;
      width: 100%;
    }
  }

  .main-desc {
    padding: 0;
    margin-top: 45px;
    margin-bottom: 40px;
    max-width: 90%;
    line-height: 28px;
    font-size: 22px;
    text-align: center;
  }

  .sub-desc {
    max-width: 90%;
    margin: auto;
    margin-bottom: 30px;
    text-align: center;
  }

  .screen-thumbs {
    padding: 40px 0;
    margin: auto;
    height: auto;
    background-image: none;

    .wrapper {
      flex-wrap: wrap;

      .thumb {
        height: auto;
        width: calc((219vw / 3.8));
        margin: 20px auto;
      }
    }
  }
}
</style>
