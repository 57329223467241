<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
      <div v-if="this.acf.hero">
        <div
          class="hero"
          :style="{
            'background-image': 'url(' + this.acf.hero.background_image + ')',
          }"
        >
          <hgroup>
            <h1>{{ this.item.title.rendered }}</h1>
            <h2 v-html="this.acf.hero.subtitle"></h2>
            <h3 v-html="this.acf.hero.content"></h3>
          </hgroup>
          <div class="separator">
            <span></span>
            <span class="black"></span>
          </div>
        </div>

        <section class="intro">
          <div>
            <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
            <h4
              v-html="this.acf.introduction.short_description"
              v-scroll-reveal
            ></h4>
            <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <section class="details">
          <div class="highlight">
            <div class="entry">
              <img :src="this.acf.poster[0].image" alt v-scroll-reveal />
            </div>
            <div class="wrapper">
              <div class="entry">
                <img :src="this.acf.poster[1].image" alt v-scroll-reveal />
              </div>
              <div class="entry">
                <img :src="this.acf.poster[2].image" alt v-scroll-reveal />
              </div>
            </div>
          </div>

          <div class="sub-highlight">
            <div class="entry">
              <div class="visual">
                <img :src="this.acf.screen.image_1" alt v-scroll-reveal />
              </div>
              <p v-html="this.acf.screen.content_1" v-scroll-reveal></p>
            </div>
          </div>

          <div class="screen-thumbs">
            <p v-html="this.acf.middle_content.content_1"></p>
            <div class="wrapper" v-scroll-reveal>
              <div class="thumb" v-for="thumbnails in this.acf.thumbnails" :key="thumbnails.image">
                <img :src="thumbnails.image" alt="thumb" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <project-navigator
      ref="projectNavigator"
      :projectId="this.item.id"
      v-if="this.item.id"
    ></project-navigator>
  </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, "bottega-veneta")
        .then((result) => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
            $(".hero, [data-paroller-factor]").paroller({
              factor: 0.3,
              type: "background",
              direction: "vertical",
            });
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },

  mounted: function () {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header").addClass("white");

    var Utils = this.$parent.scrollReveal();

    $(document).on("scroll", () => {
      $(".highlight .entry:first, .sub-highlight .visual").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });
  },
};
</script>

<style lang="stylus" scoped>


.hero {
  display: flex;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-image: url('../../public/img/cases/finair/bgd_case_finair.jpg');
  justify-content: center;
  text-align: center;

  hgroup {
    color: #fff;
    max-width: 950px;
  }

  h1 {
    margin: 0 0 40px 0;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h2 {
    font-family: futura-pt;
    font-size: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    margin: 100px 0 0 0;
    line-height: 52px;
    font-size: 40px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .separator {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 0;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      height: 1px;
      width: 29px;
      background: #fff;
    }

    span.black {
      background: #2A3642;
    }
  }
}

.intro {
  div {
    background: #FFF;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      max-width: 415px;
      margin: auto;
      letter-spacing: 0.3em;
      font-weight: normal;
    }

    h4 {
      margin-top: 35px;
      font-family: futura-pt;
      line-height: normal;
      font-size: 26px;
      text-align: center;
      font-weight: normal;
    }

    p {
      max-width: 600px;
      font-size: 18px;
      line-height: 23px;
      margin: 32px auto 125px auto;
    }
  }
}

.highlight {
  position: relative;
  height: calc((960vw / 14.4));

  > .entry {
    width: calc((1226vw / 14.4));
    height: calc((518vw / 14.4));

    &:nth-child(1) {
      transform: translateX(-100%);
      transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 0;

      &.active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .wrapper {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .entry {
      height: calc((400vw / 14.4));
      display: flex;
      align-items: center;

      &:nth-child(1) {
        margin-left: 80px;
      }

      &:nth-child(2) {
        width: calc((715vw / 14.4));
      }

      img {
        height: calc((400vw / 14.4));
        width: auto;
      }
    }
  }
}

.sub-highlight {
  width: 100%;

  .entry {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &:nth-child(1), p {
      .visual {
        transform: translateX(-100%);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: 23px;
    font-size: 18px;
    font-weight: 100;
    max-width: 474px;
    margin-top: 10%;
    position: relative;
    left: -5%;
  }
}

.screen-thumbs {
  position: relative;
  width: 100%;
  margin: calc((145vw / 14.4)) auto;

  p {
    font-family: futura-pt, 'Noto Sans KR';
    width: calc((363vw / 14.4));
    line-height: 23px;
    font-size: 18px;
    color: #2A3642;
    font-weight: 100;
    margin-left: calc((110vw / 14.4));
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 50px auto 0;
  }

  .thumb {
    width: calc((381vw / 14.4));
    position: relative;
    margin: 0 calc((20vw / 14.4));
    &:nth-child(2) {
      top: -200px;
    }

    &:nth-child(3) {
      top: -400px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hero {
    height: 385px;
    position: relative;
    align-items: flex-end;

    hgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;
      margin-bottom: 38px;

      h1 {
        line-height: 64px;
        font-size: 50px;
        margin-bottom: 0;
      }

      h2 {
        font-family: futura-pt, 'Noto Sans KR';
        margin-top: 15px;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        text-transform: lowercase;
        letter-spacing: 0;
        text-shadow: none;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      h3 {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.1em;
        line-height: normal;
        text-shadow: none;
      }
    }
  }

  .intro {
    div {
      max-width: 282px;
      margin: auto;
      padding-top: 45px;

      h3 {
        font-size: 12px;
      }

      h4 {
        font-size: 20px;
        margin-top: 18px;
      }

      p {
        margin-bottom: 75px;
      }
    }
  }

  .highlight {
    height: auto;

    .entry {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    .wrapper {
      display: block;
      margin: 0;
      margin-top: 10px;

      .entry {
        height: auto;
        width: 100% !important;
        margin: auto !important;
        margin-bottom: 10px !important;

        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .sub-highlight {
    text-align: center;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;

    .entry {
      margin: auto !important;
      width: 100% !important;
      margin-bottom: 55px;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 18px;
        margin-top: 80px;
        margin-bottom: 20px;
        width: 90%;
        position: static;
        order: 1;
      }

      .visual {
        height: auto !important;
        width: auto !important;
        order: 2;
        img {
            width: calc((296vw / 3.8));
          }
      }
    }
  }

  .screen-thumbs {
    padding: 0;
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: calc((60vw / 3.8));

    .wrapper {
      flex-wrap: wrap;
    }

    .thumb {
      position: static;
      width: calc((215vw / 3.8));
      margin-bottom: calc((10vw / 3.8));
      order: 2;
    }

    p {
      margin: auto;
      margin-top: 50px;
      width: 272px;
      text-align: center;
      order: 1;
    }
  }
}
</style>
<style lang="stylus">
br.mobile-only{
  content:" ";
}
br.web-only{
    content: none;
}
@media only screen and (max-width: 30rem){
    br.mobile-only{
      content: none;
    }
    br.web-only{
      content:" ";
    }
}
</style>

