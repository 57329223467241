<template>
    <div>
        <LoadingScreen v-if="this.isLoading"></LoadingScreen>
        <div id="content" :attr-sub="$t('our_services')">
        <header :style="{ 'background-image': 'url(' + this.acf.header + ')' }">
            <div class="title">
                <h2 v-html="this.acf.subtitle"></h2>
            </div>
        </header>
        <section class="our-approach" v-if="this.acf.our_approach">
            <h2 v-html="this.acf.our_approach.title" v-scroll-reveal></h2>
            <p v-html="this.acf.our_approach.intro" class="intro" v-scroll-reveal></p>
            <approach-points :points="this.acf.our_approach.points" />
        </section>

        <section v-for="(service, itemObjKey) in this.acf.core_services_block" 
            :key="`service-${itemObjKey}`" class="service">
            <div class="visual">
                <div class="main">
                    <h2 v-html="service.title" v-scroll-reveal></h2>
                    <p v-if="service.subtitle" v-scroll-reveal>
                        <span v-if="service.modal_link" @click="showModal(service.modal_link.ID)">
                            {{service.subtitle}} <span></span>
                        </span>
                    </p>
                    <img :src="service.background" alt="" v-scroll-reveal>
                </div>
            </div>
            <div class="wrapper">
                <div class="content">
                    <div v-for="(tags, itemObjKey) in service.tags" :key="`tags-title-${itemObjKey}`">
                        <h5 
                             v-scroll-reveal>{{ tags.title }}</h5>
                        <div v-scroll-reveal :key="`tags-${itemObjKey}`">
                            <ul>
                                <li v-for="(item, itemInd) in tags.list" :key="itemInd">
                                    {{item.description}}
                                    <span v-if="item.link" @click="showModal(item.link.ID)" class="modal-link">MORE</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-scroll-reveal v-if="service.banner">
                        <a :href="service.banner.link" target="_blank" class="banner">
                            <img :src="service.banner.image" />
                        </a>
                    </div>
                </div>
            </div>
            <img :src="service.background" alt="" class="mobile">
        </section>


        <section class="workflow">
            <div class="main-wrapper">
                <div class="wrapper" v-for="(key_points, itemObjKey) in this.acf.key_points" 
                    :key="`keypoint-${itemObjKey}`">
                    <div class="content">
                        <img :src="key_points.visual" alt="">
                        <h3 v-html="key_points.name"></h3>
                        <p v-html="key_points.description" :class="$i18n.locale === 'ja' ? 'japanese' : ''"></p>
                    </div>
                </div>
            </div>
        </section>

        <section class="partners" v-if="this.acf.our_partners_2" v-scroll-reveal>
            <div class="partner">
                <div class="wrapper">   
                    <h3 v-html="this.acf.our_partners_2.title"></h3>
                </div>
            </div>
            <div class="partner" v-for="(partner, itemObjKey) in this.acf.our_partners_2.partners" 
                :key="`partner-${itemObjKey}`">
                <div class="wrapper">
                <img :src="partner.logo" alt="">
                </div>
            </div>
        </section>
    </div>    
    <asiance-modal v-show="isModalMainVisible" @close="closeModal">
        <div class="consulting" v-if="modalContent.main_process">
            <div v-if="modalContent.main_title" class="intro">
                <h2 v-html="modalContent.main_title"></h2>
                <p v-html="modalContent.intro"></p>
            </div>
            <div class="grey-bg">
                <h2 v-html="modalContent.main_process.title"></h2>  
                <div class="process">  
                    <div v-for="(process, itemObjKey) in modalContent.main_process.process" v-bind:key="`process-${itemObjKey}`">
                        <img :src="process.icon" />
                        <h3 v-html="process.title"></h3>
                        <div class="description" v-html="process.description"></div>
                    </div>
                </div>
            </div>
            
            <div v-if="modalContent.methodologies.title" class="methodologies-wrap">
                <div class="page-container">
                    <h2 v-html="modalContent.methodologies.title"></h2>
                    <div :class="['circle-wrapper', `items-${modalContent.methodologies.steps.length}`]">
                        <ul class="circle-container" id="circle-container">
                            <li v-for="(step, itemObjKey) in modalContent.methodologies.steps" v-bind:key="`method-${itemObjKey}`">
                                <span>{{step.title}}<small>{{step.description}}</small></span>
                            </li>
                        </ul>
                        <div class="middle">Asiance</div>
                        <div class="dot animate" id="dot"></div>
                    </div>
                </div>
                <div :class="['circle-wrapper-mobile', `items-${modalContent.methodologies.steps.length}`]">
                    <div class="grid no-padding">
                        <div class="flex__container">
                            <div class="slider-circle" ref="sliderCircle">
                                <div class="slide-item" v-for="(step, itemObjKey) in modalContent.methodologies.steps" v-bind:key="`method2-${itemObjKey}`">
                                    <h3 class="rest__title_h4" v-html="step.title"></h3>
                                    <p class="description_small" v-html="step.description"></p>
                                </div>
                            </div>
                            <div class="middle">Asiance</div>
                        </div>
                    </div>
                </div>
                <p class="methodologies-footer" v-html="modalContent.methodologies.footnote"></p>
            </div> 

            <div class="subcontent" v-if="modalContent.subcontent.title">
                <h2 v-html="modalContent.subcontent.title"></h2>
                <div v-if="modalContent.subcontent.gains" class="gains-block-wrap">
                    <div :class="[gain.updown, 'gains-block']" v-for="(gain, itemObjKey) in modalContent.subcontent.gains"
                            :key="`gain-${itemObjKey}`">
                        <h3 v-html="gain.title"></h3>
                        <div class="description-wrap">
                            <p v-html="gain.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </asiance-modal>
    </div>
</template>

<script>
import PageService from "../services/PageService";
import ConsultingModalService from "../services/ConsultingModalService";
import AsianceModal from "../components/AsianceModal.vue";
import ApproachPoints from '../components/ApproachPoints.vue';
require('../static/methodologies.js');

const LoadingScreen = () =>
  import(/* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue");

export default {
  components: {
    LoadingScreen,
    AsianceModal,
    ApproachPoints
  },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
      consultingModalData:{},
      modalImages: {},
      modalContent: {},
      isModalMainVisible: this.$route.meta.showModal,
    };
  },   

  created: function() {      
    this.fetchItem(this.$i18n.locale);
    this.fetchModalData(this.$i18n.locale);
  },

  watch: {
    '$route.meta' ({showModal}) {
      this.isModalMainVisible = showModal
    }  
  },

  methods: {
    fetchItem(lang) {
      return PageService.get(lang, "what-we-do")
        .then(result => {
          this.item = result.data[0];
          this.acf = this.item.acf;
          setTimeout(() => {
            this.$parent.updateSub();
            this.isLoading = false;  
          }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
    fetchModalData(lang){
      return ConsultingModalService.get(lang)
        .then(result => {      
          this.consultingModalData = result.data;
          if(this.$route.params.slug){
            const modalId = this.$route.params.slug;
            this.modalContent = this.consultingModalData.find(obj => obj.slug === modalId);  
            this.modalContent = this.modalContent.acf; 
          }
          setTimeout(() => {    
            this.$parent.updateSub();
            this.isLoading = false;                           
          }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
    showModal(id) {      
        this.modalContent = this.consultingModalData.find(obj => obj.id === id);
        this.modalContent = this.modalContent.acf;
        if (this.modalContent){
            this.isModalMainVisible = true;
            $('html').css({'overflow':'hidden'});
            console.log("HERE", this.$refs.sliderCircle)
            setTimeout(() => {
                $(this.$refs.sliderCircle).wet({ speed: 500, length: 6 });  
            }, 500);
        }
    },
    closeModal() {
        this.isModalMainVisible = false;
        this.modalContent = {};
        $('.modal-body .consulting').scrollTop(0);
        $('html').css({'overflow':'visible'});    
        this.$router.push('/what-we-do');
    }
  }, 

  mounted: function() {
    // require('../static/methodologies.js');
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").show();
    $(".subtitle:first").addClass("white");

    $("header").removeClass();
    $("header").addClass("white");
    $("header:first").addClass("fixed");     

    if (this.isModalMainVisible === true) {
        $('html').css({'overflow':'hidden'});
        console.log("THERE", this.$refs.sliderCircle)
        setTimeout(() => {
            $(this.$refs.sliderCircle).wet({ speed: 500, length: 6 });  
        }, 1000);
    }

    /*
    setTimeout(() => {
        if($(window).width() >= 1000 ) {
            $(".service .content").paroller({
                factor: 0.1,
                type: 'foreground',
                direction: 'vertical'
            });

            $(".service .main").paroller({
                factor: -0.1,
                type: 'foreground',
                direction: 'vertical'
            });
        }
    }, 1000);
    */
  }
};
</script>

<style lang="stylus" scoped>

.mobile {
    display: none;
}

header {
    background-image: url('../../public/img/bgd_what_we_do.png');
    background-size: cover;
    background-position: top center;
    height: 100vh;
    display: flex;
    padding-top: calc(200vw / 14.4);
    min-height: auto;

    .separator {
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 60px;
        left: 0;
        right: 0;
        margin: auto;

        span {
            float: right;
            position: relative;
            top: 30px;
            width: 1px;
            height: 30px;
            background: #fff;
        }

        span.black {
          background: #2A3642;
        }
    }
}

.title {
    font-family: futura-pt, 'Noto Sans KR';
    top: calc(100vw/14.4);
    text-align: center;
    margin-left: calc(171vw/14.4);

    h1 {
        min-width: calc(915vw/14.4);
        font-size: calc(76vw/14.4);
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        color: #2A3642;
        letter-spacing: 0.3em;
    }

    h2 {
        font-family: futura-pt, 'Noto Sans KR' !important;
        text-transform: uppercase;
        color: #fff;
        font-size: calc(90vw/14.4);
        line-height: calc(116vw / 14.4);
        font-weight: 700;
        line-height: calc(116vw / 14.4);
        text-align: left;
        letter-spacing: 0.3em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 0;

        span {
            font-size: calc(40vw/14.4);
            font-weight: 300;
        }
    }
}

.our-approach {
    text-align: center;
    background: #e33d50;
    padding: calc(85vw/14.4) 0 calc(67vw/14.4) 0;

    h2 {
        font-size: calc(20vw/14.4);
        font-family: futura-pt;
        color: #ffffff;
        letter-spacing: calc(6vw/14.4);
        margin: 0 0 calc(26vw/14.4);
    }

    .intro {
        font-size: calc(20vw/14.4);
        font-family: futura-pt;
        font-weight: 300;
        color: #ffffff;
        margin: 0 0 calc(38vw / 14.4);

    }
}

.subtitle {
    z-index: 999;
    position: absolute;
    color: #000;
    transform: rotate(-90deg);
    position: fixed;
    top: 200px;
    left: -20px;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 0.3em;
    text-transform: uppercase;

    span {
        display: block;
        width: 47px;
        height: 1px;
        background-size: 1px;
        background-color: #000;
        margin-top: 6px;
        margin-left: 10px;
        float: right;
    }
}

.service {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    padding-top: calc(137vw/14.4);
    padding-left: calc(220vw/14.4);
    padding-right: calc(220vw/14.4);

    .visual {
        // width: 50%;

        .main {
            width: auto;
        }

        h2 {
            margin: 0 0 calc(10vw/14.4) 0;
            font-family: futura-pt, 'Noto Sans KR';
            font-size: calc(45vw/14.4);
            font-weight: 700;
            line-height: normal;
            letter-spacing: calc(7.2vw/14.4);
        }

        p {
            font-family: futura-pt;
            font-size: calc(18vw/14.4);
            font-weight: 100;   
        }
        

    }

    .wrapper {
        color: #2A3642;
        //width: 50%;
        // padding-top: calc(110vw/14.4);

        .content {
            width: calc(688vw/14.4);
            min-height: calc(600vw/14.4);
            // padding: 0 calc(98vw/14.4) 0 0;
            position: relative;
            z-index: 5;

            h5 {
                font-family: futura-pt, 'Noto Sans KR';
                text-transform: uppercase;
                font-size: calc(16vw/14.4);
                font-weight: 700;
                letter-spacing: calc(3.2vw/14.4);
                line-height: calc(21vw/14.4);
                margin-bottom: calc(15vw/14.4);
                display: flex;
                align-items: center;

                &:before {
                    content: "";
                    display: inline-block;
                    height: calc(6vw/14.4);
                    width: calc(6vw/14.4);
                    border-radius: 50%;
                    background: #e43852;
                    margin-right: calc(12vw/14.4);
                }
            }

            p {
                font-weight: 500;
            }

            ul {
                list-style: none;
                margin: 0 0 calc(25vw/14.4) 0;
                padding: 0;

                li {
                    display: flex;
                    align-items: center;
                    font-size: calc(12vw/14.4);
                    line-height: calc(22vw/14.4);
                    text-transform: uppercase;
                    letter-spacing: 1px;

                    &:before {
                        content: "";
                        display:block;
                        width: calc(15vw/14.4);
                        border-top: 1px solid;
                        margin-top: calc(1vw/14.4);
                        background: #4d4d4d;
                        margin-right: calc(15vw/14.4);
                    }
                    span.modal-link{
                        cursor: pointer;
                        display: inline-block;
                        font-size: calc(10vw/14.4);
                        line-height: 1.2;
                        color: #2A3642;
                        margin-left: 15px;
                        font-weight: 400;
                        border-bottom: 1px solid #2A3642;
                    }
                }
            }
        }

        h2 {
            font-family: futura-pt;
            font-size: calc(44vw/14.4);
            text-transform: uppercase;
            color: #2A3642;
            min-height: calc(62vw/14.4);
            font-weight: 100;
            letter-spacing: calc(5.5vw/14.4);
        }

        p {
            -webkit-text-stroke: calc(0.2vw/14.4);
            -webkit-font-smoothing: antialiased;
            font-size: calc(18vw/14.4);
            line-height: calc(24vw/14.4);
            margin-bottom: calc(50vw/14.4);
            font-weight: 100;
        }

        ul {
            padding: 0;
            margin-bottom: calc(46vw/14.4);
            list-style-type: none;

            li {
                font-family: futura-pt;
                font-weight: 100;
                font-size: calc(12vw/14.4);
                line-height: calc(25vw/14.4);
                text-transform: uppercase;
                color: #4d4d4d;
                display: flex;
                // align-items: center;

                svg {
                    margin-right: calc(25vw/14.4);
                    margin-top: calc(10vw/14.4);
                }
            }
        }

        a {
            color: #2A3642;
            font-size: calc(16vw/14.4);
            transition: color 0.3s cubic-bezier(0.22, 0.44, 0, 1);
            display: flex;
            align-items: center;

            &:hover {
                color: #C43E51;

                svg use {
                    fill: #C43E51;
                }
            }

            svg {
                margin-left: calc(10vw/14.4);

                use {
                    transition: fill 0.3s cubic-bezier(0.22, 0.44, 0, 1);
                }
            }
        }
    }
}

.service:nth-of-type(2) {
    background: #f7f7f7;
    min-height: calc(739vw/14.4);

    .visual {
        left: 0;
        min-width: calc(619vw/14.4);
        position: relative;

        img {
            height: calc(380vw/14.4);
            width: calc(380vw/14.4);
            position: absolute;
            bottom: 0;
            left: calc(-70vw/14.4);
        }
    }

    .wrapper {
        display: flex;
    }
}

.service:nth-of-type(3) {
    flex-direction: row-reverse;
    padding-right: 0;
    min-height: calc(810vw/14.4);

    .content {
        // margin-left: calc(140vw/14.4);
        min-width: calc(619vw/14.4);
        width: calc(619vw/14.4);
    }

    .visual {
        min-width: calc(590vw/14.4);
        .main {
            height: 100%;
            position: relative;

            img {
                width: calc(432vw/14.4);
                height: calc(432vw/14.4);
                position: absolute;
                bottom: 0;
            }
        }
    }
}

.service:nth-of-type(4) {
    background: #f7f7f7;
    min-height: calc(579vw/14.4);

    .visual {
        min-width: calc(619vw/14.4);
        width: calc(619vw/14.4);

        .main {
            height: 100%;
            position: relative;

            img {
                height: calc(200vw/14.4);
                width: calc(567vw/14.4);
                position: absolute;
                bottom: calc(83vw/14.4);
                left: calc(-220vw/14.4);
            }
        }
    }

    .wrapper {
        .content {
            min-height: auto;
        }
    }
}

.service:nth-of-type(5) {
    .visual {
        left: 0;
        margin-top: calc(100vw/14.4);
    }

    .wrapper {
        padding-top: calc(170vw/14.4);
        display: flex;
        flex-direction: row-reverse;
    }
}

.workflow {
    background: #C43E51;
    padding: calc(70vw/14.4) 0 calc(70vw/14.4) 0;

    .main-wrapper {
        display: flex;
        justify-content: space-around;
        margin: auto;
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .content {
        max-width: calc(392vw/14.4);
        margin: 0 auto;
        position: relative;
        padding-left: calc(40vw/14.4);

        img {
            width: calc(126vw/14.4);
            height: auto;
            position: absolute;
            z-index: 1;
            top: calc(-20vw/14.4);
            left: calc(-40vw/14.4);
        }

        svg {
            position: absolute;
            z-index: 1;
            top: calc(-20vw/14.4);
            left: calc(-40vw/14.4);
        }

        /deep/ h3 {
            font-family: futura-pt;
            position: relative;
            z-index: 2;
            font-size: calc(32vw/14.4);
            color: #ffffff;
            margin-bottom: calc(40vw/14.4);
            max-width: calc((354vw / 14.4));
            text-transform: uppercase;
            line-height: calc(40vw/14.4);
            font-weight: 300;
            span {
                font-family: futura-pt, 'Noto Sans KR';
                font-weight: 700;
            }
        }

        p {
            font-family: futura-pt, 'Noto Sans KR';
            font-size: calc(16vw/14.4);
            line-height: calc(21vw/14.4);
            color: #ffffff;
            max-width: calc((300vw / 14.4));
            font-weight: 100;
            position: relative;
            z-index: 1;
            &.japanese {
                max-width: calc((392vw / 14.4));
            }
        }
    }
}

.partners {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: calc(95vw/14.4) calc((120vw / 14.4)) calc(47vw/14.4) calc((120vw / 14.4));
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        padding: calc(95vw / 14.4) calc((180vw / 14.4)) calc(47vw / 14.4) calc((180vw / 14.4));
    }

    .partner {
        display: flex;
        flex-wrap: wrap;
        @media screen and (min-width: 1025px) and (max-width: 1440px) {
            margin: 0 calc(12vw/3.2) calc(12vw/3.2);
        }

        &:first-child {
            width: 100%;
            margin-bottom: 80px;
        }

        .wrapper {
            margin: auto auto;
        }

        h3 {
            font-family: futura-pt;
            font-size: calc(20vw/14.4);
            letter-spacing: 0.3em;
            text-transform: uppercase;
            font-weight: 100;
            text-align: center;
            color: #616161;

            span {
                display: block;
                margin-top: calc(15vw/14.4);
            }
        }
    }
}

hr {
    background: #333333;
    height: 1px;
    margin: 0 calc((120vw / 14.4));
    border: none;
}


.ja {
    .service {
        h2 {
            font-size: calc(28vw / 14.4);
        }
        .wrapper{
            ul{
                li{
                    font-family: 'Noto Sans JP', sans-serif !important; 
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {

    .mobile {
        display: block;
    }

    header {
        height: 50vh;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 0px;
        min-height: auto;
        padding-top: calc(100vw / 3.2);

        .separator {
            bottom: 50vh;
        }

        /deep/ .title {
            position: initial;
            min-height: auto;
            height: 50vh;
            width: 90%;
            max-width: 90%;
            margin: auto;
            margin-left: calc(171vw / 14.4);

            h2 {
                font-family: futura-pt, 'Noto Sans KR';
                font-weight: 700;
                text-transform: uppercase;
                margin: auto;
                font-size: 30px;
                line-height: normal;
                letter-spacing: 0.3em;

                span {
                    display: block;
                    font-size: calc(16vw/3.2);
                    line-height: normal;
                }
            }
        }
    }

    .intro {
        display: none;
    }

    .our-approach {
        h2 {
            font-size: calc(16vw/3.2);
            margin: calc(15vw/3.2) 0 calc(20vw/3.2) 0;
            padding-left: calc(20vw/3.2);
            padding-right: calc(20vw/3.2);
        }

        .our-approach-points {
            flex-direction: column;
            align-items: center;

            .point {
                width: auto;
            }

            h3 {
                font-size: calc(16vw/3.2);
            }

            p {
                font-size: calc(12vw/3.2);
            }

            .visual {
                height: calc(100vw/3.2);
                width: calc(100vw/3.2);

                img {
                    height: calc(60vw/3.2);
                }
            }

            .arrow-wrapper {
                margin-top: 0;
                height: calc(50vw/3.2);
                flex-direction: column;
                justify-content: center;

                .arrow-line {
                    width: calc(30vw/3.2);
                    height: 1px;
                    transform: rotate(90deg);
                    display: none;
                }

                .arrow {
                    border-top: calc(10vw / 3.2) solid transparent;
                    border-bottom: calc(10vw / 3.2) solid transparent;
                    border-left: calc(10vw / 3.2) solid #fff;
                    transform: rotate(90deg);
                }
            }
        }
    }

    .service {
        padding: calc(15vw/3.2) 0 calc(150vw/3.2) 0;
        margin-bottom: 0 !important;
        width: auto!important;
        display: block;
        position: relative;

        img {
            position: absolute;
            bottom: 0;
            right: 0;
            height: calc(200vw/3.2);
            width: calc(200vw/3.2);
        }

        .visual {
            position: relative;
            margin-top: 0 !important;
            width: 100%!important;

            .main {
                width: 100%!important;
                padding: calc(16vw / 3.2) calc(25vw / 3.2) calc(10vw / 3.2) calc(28vw / 3.2);

                h2 {
                    font-size: calc(36vw/3.2);
                    line-height: normal;
                }

                p {
                    font-size: calc(14vw/3.2);
                }

                img {
                    display: none;
                    right : calc(20vw/3.2);
                }
            }
        }

        .wrapper {
            padding: 0 0 !important;
            margin-bottom: 0 !important;
            width: auto!important;

            .content {
                width: 100%;
                padding: calc(25vw/3.2) calc(25vw/3.2) calc(40vw/3.2) calc(28vw/3.2)!important;
                margin-left: 0;

                h2 {
                    font-size: calc(26vw/3.2);
                    line-height: calc(32vw/3.2);
                    height: calc(66vw/3.2);
                    line-height: calc(66vw/3.2);
                    vertical-align: middle;
                }

                p {
                    font-size: calc(16vw/3.2);
                    line-height: calc(19vw/3.2);
                    padding: 0;
                    margin-bottom: calc(15vw/3.2);
                }

                h5 {
                    font-size: calc(12vw/3.2);
                    line-height: normal;
                }

                ul {
                    margin-top: calc(10vw/3.2);
                    margin-bottom: calc(15vw /3.2);

                    li {
                        font-size: calc(10vw/3.2);
                        line-height: calc(21vw/3.2);
                        letter-spacing: 20%;

                        span.modal-link{
                            display: inline-block;
                            font-size: calc(9vw/3.2);
                        }

                        svg {
                            margin-right: calc(20vw/3.2);
                            margin-top: calc(8vw/3.2);
                            min-width: 15px;
                        }
                    }
                }

                a {
                    font-size: calc(16vw/3.2);

                    svg {
                        margin-left: calc(15vw/3.2);
                    }
                }
            }
        }

        &:nth-of-type(1) {
            margin-top: 0;
        }

        &:nth-of-type(4) {
            img {
                left: 0;
                bottom: calc(40vw/3.2);
                width: calc(283vw/3.2);
                height: calc(100vw/3.2);
            }
        }
    }

    .workflow {
        padding: calc(55vw/3.2) 0;
        margin-bottom: calc(40vw/3.2);

        .main-wrapper {
            display: block;
            width: 90%;
        }

        .wrapper {
            display: inherit;
            width: 100%;
            margin-bottom: calc(70vw/3.2);

            &:last-child {
                margin-bottom: 0;
            }

            .content {
                width: 100%;
                max-width: 100%;
            }

            img {
                width: calc(97vw / 3.2);
                top: calc(-30vw/3.2);
            }

            h3 {
                max-width: inherit;
                margin-bottom: 20px;
                font-size: calc(22vw/3.2);
                line-height: normal;
            }

            p {
                font-size: calc(16vw/3.2);
                line-height: calc(23vw/3.2);
                max-width: inherit;
                position: relative;
                z-index: 3;
                font-weight: 100;
                padding-top: calc(20vw/3.2);
                &.japanese {
                    font-size: calc(12vw/3.2);
                    max-width: inherit;
                }
            }
        }
    }

    .partners {
        padding: 0;
        width: 90%;
        margin: auto;
        flex-wrap: wrap;
        justify-content: space-around;

        .partner {
            width: 40%;
            justify-content: center;
            margin-bottom: calc(50vw/3.2);

            h3 {
                font-size: calc(12vw/3.2);
                line-height: calc(18vw/3.2);
                max-width: calc(180vw/3.2);
            }

            svg {
                transform: scale(0.8);
            }

            &:nth-child(1) {
                margin-bottom: calc(40vw/3.2);
            }

            &:nth-child(4) {
                order: 5;
            }

            &:nth-child(5) {
                order: 6;
            }
        }
    }

    .ko {
        .service {
            .wrapper .content {
                h2 {
                    max-width: calc(125vw/3.2);
                    min-height: calc(75vw/3.2);
                    font-size: calc(33vw / 3.2);
                    line-height: calc(42vw / 3.2);
                    padding: calc(-8vw / 3.2) 0 0 0;
                }
            }
        }
    }

    .ja {
        header {
            /deep/ .title {
                span.number {
                    margin-top: calc(-91px / 3.2);
                    font-size: calc(130vw / 3.2);
                }

                span {
                    font-size: calc(30vw / 3.2);
                }

                h1 {
                    font-size: calc(24vw / 3.2);
                }
            }
        }
        .service {
            .wrapper .content {
                h2 {
                    max-width: none;
                    min-height: calc(75vw / 3.2);
                    font-size: calc(21vw / 3.2);
                    line-height: calc(42vw / 3.2);
                    padding: calc(7vw / 3.2) 0 0 0;
                }
            }
        }
    }
}
.consulting{
      height: 100%;    
      overflow-y: scroll; 
      &::-webkit-scrollbar {
       background: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #E9E9E9;
        border-radius: 10px;
      }
    .intro{
        padding: 60px 50px;
        p{
            width: 800px;
            max-width: 100%;
            text-align: center;
            margin: 0 auto;
        }
    }
    .grey-bg{
        background-color: #F7F7F7;
        padding: 60px 50px;
    }
    img{
        width: 175px;
        height: 175px;
    }
    h2{
        font-size: 30px;
        text-align: center;
        margin: 0 0 calc(65vw / 19.2);
        text-transform: uppercase;
    }
    p{
        font-weight: 400;
        line-height: 1.5; 
    }
    .process{
        display: flex;
        padding-top: 20px;
        justify-content: space-between;
        > div{
            width: 30%;
        }
        img{
            margin: 0 auto;
        }
        h3{
            text-transform: uppercase;
            font-size: 20px;
            margin-top: 27px;
            margin-bottom: calc(45vw / 19.2);            
            text-align: center;
        }
        .description{   
            font-weight: 400;
            line-height: 1.5;          
            text-align: center;
        }
    }
    .subcontent{        
        padding: 60px 50px;
        .gains-block-wrap{
            max-width: 100%;
            width: 900px;
            margin: 0 auto;
        }
        .gains-block{
            display: flex;   
            align-items: center; 
            margin-bottom: 30px;
            > *{                
                width: 50%;
            }        
            h3{
                font-size: 22px;
                line-height: 1.4;
                padding: 0 5% 0 0;
                text-align: center;
                color: #2A3642;
                display: flex;
                align-items: center;
                &:before{
                    margin-right: 20px;
                    content: url('../../public/img/navy-tri-down.png');
                }
            }
            .description-wrap{
                text-align: center;
                background-color: white;
                position: relative;
                line-height: 1.4;
                padding: 20px 36px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                &:before{
                    content: '';
                    position: absolute;
                    left: -17px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 17.3px 10px 0;
                    border-color: transparent white transparent transparent;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &.up{
                h3{
                    color: #E43852;
                    &:before{
                        content: url('../../public/img/red-tri-up.png')
                    }
                }
                .description-wrap{
                    background-color: #E43852;
                    color: white;
                    &:before{
                        border-color: transparent #E43852 transparent transparent;

                    }
                }
            }
        }        
    }
    @media only screen and (max-width: 1024px) {
        .grey-bg{            
            padding: 60px 20px; 
        }
        h2{
            margin-bottom: 35px;
        }
        .process{ 
            flex-direction: column;
            > div{
                width: 100%;
                margin-bottom: 45px;
            }
            h3{
                margin-bottom: 20px;
            }
        }
        .subcontent{  
            padding: 60px 20px;          
            .gains-block{
                > *{
                    width: 100%;
                }
                flex-direction: column;
                h3{
                    font-size: 20px;
                    flex-direction: column;
                    padding: 20px 0 40px 0;
                    &:before{
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }
                .description-wrap{
                    &:before{        
                        border-width: 0 10px 17px 10px;               
                        border-color: transparent transparent white transparent;
                        top: auto;
                        bottom: 100%;
                        left: 50%;
                        transform: translate(-50%,0);

                    }
                }
                &.up{
                    .description-wrap{
                        &:before{  
                            border-color: transparent transparent #E43852 transparent; 
                        }
                    }
                }
            }
        }
    }
} 
</style>
<style lang="stylus">
.service{
    .wrapper{
        .content{
            .banner{
                margin: 40px 0 calc(120vw / 19.2);
                img{
                    position: static;
                    display: block;
                    width: auto;
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    .visual{
        p{
            > span {
                cursor: pointer;
                display: inline-block;
                margin-top: 20px;
                color: #E33D50;
                font-weight: 700;
                position: relative;
                padding-right: 20px;
                &:after{
                    content: '+';
                    position: absolute;
                    right: 0;
                    top: -2px;
                }
                span {
                    display: block;
                    width: 0;
                    margin: 2px auto auto auto;
                    border-bottom: 1px solid #E33D50;
                    transition: width 0.3s cubic-bezier(0.22, 0.44, 0, 1);
                }
                &:hover {
                    span {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1024px) {
.service{
    .wrapper{
        .content{
            .banner{
                margin-bottom: 60px;
            }
        }
    }
}
}
</style>