import Vue from 'vue';

export default {
  get(lang) {
    if (lang === 'en') {
      lang = '';
    }
    return Vue.http.get(`${process.env.VUE_APP_WP_HOME}/${lang}/wp-json/wp/v2/consulting`);
  },
};
