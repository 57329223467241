<template>
    <div class="project-navigator" v-scroll-reveal>
        <div class="project" v-if="this.prevProject">
            <div class="visual" :style="{ 'background-image': 'url(' + this.prevProject.featured_image + ')' }" 
                v-if="this.prevProject.featured_image">
                <router-link :to="{ name: this.prevProject.slug}"></router-link>
            </div>
            <div class="to-project">
                <router-link :to="{ name: this.prevProject.slug}">
                    <svg width="30" height="16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g transform="translate(19898 4459)">
                        <use xlink:href="#arrow_left0_fill" transform="matrix(0 1 1 0 -19898 -4459)" fill="#3B434D" id="Vector"/>
                    </g>
                    <defs>
                        <path id="arrow_left0_fill" fill-rule="evenodd" d="M8.324.137a.47.47 0 0 0-.665 0L.146 7.664c-.443.442.222 1.104.665.662l6.386-6.36c.184-.183.333-.125.333.137v27.429c0 .2.128.368.305.435A.407.407 0 0 0 8 30c.26 0 .47-.21.47-.468V2.103c0-.259.147-.322.333-.137l6.386 6.36c.443.442 1.108-.22.665-.662L8.324.137z"/>
                    </defs>
                    </svg>
                </router-link>
                <router-link :to="{ name: this.prevProject.slug}">{{ $t("prev_project") }}</router-link>
            </div>
        </div>
        <div class="project" v-if="this.nextProject">
            <div class="visual" :style="{ 'background-image': 'url(' + this.nextProject.featured_image + ')' }" 
                v-if="this.nextProject.featured_image">
                <router-link :to="{ name: this.nextProject.slug}"></router-link>
            </div>
            <div class="to-project">
                <router-link :to="{ name: this.nextProject.slug}">{{ $t("next_project") }}</router-link>
                <router-link :to="{ name: this.nextProject.slug}">
                    <svg width="30" height="16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g transform="translate(18760 4461)">
                        <use xlink:href="#arrow_right0_fill" transform="matrix(0 1 -1 0 -18730 -4461)" fill="#3B434D" id="Vector"/>
                    </g>
                    <defs>
                        <path id="arrow_right0_fill" fill-rule="evenodd" d="M8.324.137a.47.47 0 0 0-.665 0L.146 7.664c-.443.442.222 1.104.665.662l6.386-6.36c.184-.183.333-.125.333.137v27.429c0 .2.128.368.305.435A.407.407 0 0 0 8 30c.26 0 .47-.21.47-.468V2.103c0-.259.147-.322.333-.137l6.386 6.36c.443.442 1.108-.22.665-.662L8.324.137z"/>
                    </defs>
                    </svg>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import PageService from "../services/PageService";
import PostService from "../services/PostService";

export default {
  components: { },

  props: [
    'projectId'
  ],

  data() {
    return {
        isLoading: true,
        nextProject : {},
        prevProject : {},
        item: {},
        acf: {},
        stories: {}
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
        return PageService.get(lang, "our-work")
        .then(result => {
            this.item = result.data[0];
            Promise.all(
            this.item.acf.case_studies.map(post =>
                PostService.get(lang, post.ID).then(result => {
                return result.body;
                })
            )
            ).then(data => {
                this.stories = data;
                const index = data.findIndex(x => x.id === this.projectId);

                if(index === data.length - 1) {
                    this.nextProject = data[0];
                }else{
                    this.nextProject = data[index + 1];
                }

                if(index === 0){
                    this.prevProject = data[index + data.length -1];
                }else{
                    this.prevProject = data[index -1];
                }
            });
        })
        .catch(() => {
            this.error = true;
        });
    }
  },
}
</script>


<style lang="stylus" scoped>
.project-navigator {
    display: flex;;

    .project {
        height: 270px;
        width: 50%;
        position: relative;

        &:nth-child(2) {
            .to-project {
                right: 120px;
                left: auto;
            }

            &:hover {
                svg {
                    transform: translateX(5px);
                }
            }
        }

        &:hover {
            .visual {
                opacity: 1;
            }

            a {
                color: #C43E51;
            }

            svg  {
                transform: translateX(-5px);
                use {
                    fill: #C43E51;
                }
            }
        }
    }

    .visual {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        opacity: 0.8;
        cursor: pointer;
        transition: opacity 0.3s linear;

        > a {
            height: 100%;
            width: 100%;
            display: block;
        }
    }

    .to-project {
        position: absolute;
        bottom: 0;
        left: 120px;
        padding: 0 8px;
        height: 60px;
        background: #fff;
        display: flex;
        align-items: center;

        a {
            display: block;
            font-family: futura-pt;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: 0.3em;
            vertical-align: middle;
            color: #3B434D;
            text-transform: uppercase;
            padding: 10px;
            transition: color 0.3s cubic-bezier(0.22, 0.44, 0, 1);
        }

        svg {
            transition: transform 0.3s cubic-bezier(0.22, 0.44, 0, 1);
            use {
                transition: fill 0.3s linear;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .project-navigator {
        .project {
            height: 120px;

            &:nth-child(2) {
                .to-project {
                    right: 25px;

                    a:first-child {
                        display: none;
                    }

                    a:last-child {
                        display: block;
                    }
                }
            }
        }

        .to-project {
            left: 25px;
            height: 30px;
            padding: 0 10px;

            a {
                padding: 0;
                margin-top: 3px;
            }

            a:last-child {
                display: none;
            }
        }
    }
}
</style>
