<template>
  <div class="brandtech">
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div
      id="content"
      :attr-sub="$t('brandtech')"
      :class="this.$i18n.locale"
      v-else
    >
      <section class="title">
        <hgroup>
          <h1 v-html="this.acf.title"></h1>
        </hgroup>
      </section>

      <section class="expert why">
        <div class="wrapper">
          <h2>Why</h2>
          <div v-html="this.acf.why" class="content"></div>
        </div>
      </section>

      <section id="video-player" class="video-player" v-scroll-reveal>
        <div class="wrapper">
          <div>
            <!-- <h2 v-html="this.acf.video.intro"></h2> -->
          </div>
          <div class="video">
            <vimeo-player
              ref="player"
              :video-id="this.videoID"
              :player-width="640"
              :options="{ responsive: true, title: 0, byline: 0, portrait: 0 }"
              @play.once="onPlay"
              @ended="onEnded"
            ></vimeo-player>
          </div>
        </div>
      </section>

      <section class="expert what">
        <div class="wrapper">
          <h2>What</h2>
          <div v-html="this.acf.what" class="content"></div>
        </div>
      </section>

      <section class="how" v-if="this.acf.how">
        <h2 v-scroll-reveal>How</h2>
        <p v-html="this.acf.how.intro" class="intro" v-scroll-reveal></p>
        <div class="how-points">
          <template v-for="(point, itemObjKey) in this.acf.how.points">
            <div
              :key="itemObjKey"
              class="point"
              v-scroll-reveal="{ delay: 450 * itemObjKey }"
            >
              <div class="visual">
                <img :src="point.visual" alt="" />
              </div>
              <h3>{{ point.title }}</h3>
              <p v-html="point.description"></p>
            </div>
            <div
              v-bind:key="`${itemObjKey}-${point.id}`"
              class="arrow-wrapper"
              v-scroll-reveal="{ delay: 350 * itemObjKey + 200 }"
            >
              <span class="arrow-line"></span>
              <div class="arrow"></div>
            </div>
          </template>
        </div>
      </section>

      <section class="cta-btn">
        <router-link :to="{ name: 'what_we_do' }" @click.native="onClick">
          <div
            v-scroll-reveal
            v-html="this.acf.cta"
            :class="this.$i18n.locale === 'en' ? 'more-padding' : ''"
          ></div>
        </router-link>
      </section>
    </div>
  </div>
</template>

<script>
import PageService from "../services/PageService";

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue"
  );

export default {
  components: {
    LoadingScreen,
  },
  data() {
    return {
      isLoading: true,
      item: {},
      acf: {},
      videoID: "",
    };
  },
  created() {
    this.fetchItem(this.$i18n.locale);
    this.updateVideo(this.$i18n.locale);
  },
  mounted() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").show();
    $(".subtitle:first").addClass("white");

    $("header").removeClass();
    $("header").removeClass("black");
    $("header:first").addClass("fixed");

    var Utils = this.$parent.scrollReveal();
    $(document).on("scroll", () => {
      $(".expert").each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass("active");
        }
      });
    });
  },
  methods: {
    fetchItem(lang) {
      return PageService.get(lang, "brandtech")
        .then((result) => {
          this.item = result.data[0];
          this.acf = this.item.acf;
          setTimeout(() => {
            this.isLoading = false;
            this.$parent.updateSub();
          }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
    updateVideo(lang) {
      switch (lang) {
        case "ko":
          this.videoID = "402784714";
          break;
        case "ja":
          this.videoID = "402784657";
          break;
        default:
          this.videoID = "402784598";
      }
    },
    stopVideo() {
      this.$refs.player.stop();
    },
    onPlay() {
      this.$ga.event("Video", "play", "landing_page_video");
    },
    onEnded() {
      this.$ga.event("Video", "end", "landing_page_video");
    },
    onClick() {
      this.$ga.event("Button", "click", "cta_for_whatwedo");
    },
  },
};
</script>

<style lang="stylus" scoped>

.title {
    background-color: #26313D;
    background-image: url('../../public/img/main_visual_original.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 100vh;
    position: relative;
    display: flex;
    padding-top: calc(200vw / 14.4);
    min-height: 700px;

    hgroup {
        margin-left: calc(171vw / 14.4);
        max-width: calc(871vw / 14.4);
    }

    h1 {
        font-family: futura-pt, 'Noto Sans KR';
        color: #fff;
        font-size: calc(80vw / 14.4);
        line-height: calc(116vw / 14.4);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.3em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 0;
    }

    .separator {
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 60px;
        left: 0;
        right: 0;
        margin: auto;

        span {
            float: right;
            position: relative;
            top: 30px;
            width: 1px;
            height: 30px;
            background: #fff;
        }

        span.black {
            background: #2A3642;
        }
    }
}

.expert {
    width: calc(1320vw / 14.4);
    height: calc(542vw / 14.4);
    padding-left: calc((130vw / 14.4));
    transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    opacity: 0;
    background-repeat: no-repeat;
    background-position: right center;

    &.why {
        float: right;
        background-color: #E43852;
        background-image: url('../../public/img/why.png');
        background-size: auto 90%;
        background-position: 90% center;
        color: #FFF;
        transform: translateX(100%);
        margin-top: calc(67vw / 14.4);
    }

    &.what {
        float: left;
        padding-left: calc((698vw / 14.4));
        background-color: #F2F2F2;
        background-image: url('../../public/img/what.png');
        background-size: auto 115%;
        background-position: left center;
        color: #2A3642;
        transform: translateX(-100%);
        margin-bottom: calc(67vw / 14.4);
    }

    &.active {
        transform: translateX(0);
        opacity: 1;

        .wrapper {
            opacity: 1;
        }
    }

    .wrapper {
        opacity: 0;
        transition: opacity 900ms linear;
        transition-delay: 0.5s;
        max-width: calc(455vw / 14.4);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    h2 {
        font-family: futura-pt, 'Noto Sans KR';
        line-height: normal;
        font-size: calc(45vw / 14.4);
        font-weight: normal;
        margin-bottom: calc(25vw / 14.4);
        text-transform: uppercase;
        color: inherit;
    }

    .content {
        font-family: futura-pt, 'Noto Sans KR';
        font-size: calc(18vw / 14.4);
        line-height: calc(30vw / 14.4);
        font-weight: 100;
        margin-bottom: calc(18vw / 14.4);

        .font-midnight {
            color: #2A3642;
        }

        .font-pink {
            color: #E43852;
        }
    }
}

.video-player {
    clear: both;
    padding: calc(67vw / 14.4) calc((120vw / 14.4));

    .wrapper {
        display: flex;
    }

    h2 {
        font-family: futura-pt, 'Noto Sans KR';
        line-height: 1.5;
        font-size: calc(45vw / 14.4);
        font-weight: normal;
        margin-right: calc(25vw / 14.4);
        text-transform: uppercase;
        letter-spacing: calc(10vw / 14.4);
        color: inherit;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .video {
        width: 100%;
        height: auto;
        cursor: pointer;
        margin: auto;
        text-align: center;

        iframe {
            margin: auto;
        }
    }
}

.how {
    clear: both;
    padding: 0 calc((130vw / 14.4));
    text-align: center;
    padding-bottom: calc(67vw / 14.4);

    h2 {
        font-family: futura-pt, 'Noto Sans KR';
        line-height: normal;
        font-size: calc(45vw / 14.4);
        font-weight: normal;
        margin-bottom: calc(25vw / 14.4);
        text-transform: uppercase;
        color: inherit;
    }

    .intro {
        font-family: futura-pt, 'Noto Sans KR';
        font-size: calc(20vw / 14.4);
        font-weight: 300;
        color: inherit;
        margin: 0 0 calc(38vw / 14.4);

    }
}

.how-points {
    max-width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;

    .point {
        width: calc(439vw/14.4);
    }

    .visual {
        height: calc(161vw/14.4);
        width: calc(161vw/14.4);
        margin: 0 auto calc(35vw/14.4) auto;
        border-radius: 50%;
        background: #E43852;
        display: flex;
        align-items center;
        justify-content: center;

        img {
            height: calc(90vw/14.4);
            width: auto;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: calc(24vw/14.4);
        letter-spacing: calc(4.8vw/14.4);
        color: #E43852;
        margin-bottom: calc(15vw/14.4);
    }

    p {
        color: #2A3642;
        font-size: calc(14vw / 14.4);
        line-height: 1.5;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .arrow-wrapper {
        display: flex;
        align-items: center;
        margin-top: calc(calc(-200vw/14.4)/2);

        &:last-child {
            display: none;
        }
    }

    .arrow-line {
        width: calc(172vw/14.4);
        height: calc(1vw/14.4);
        background: #E43852;
    }

    .arrow {
        width: 0;
        height: 0;
        border-top: calc(10vw/14.4) solid transparent;
        border-bottom: calc(10vw/14.4) solid transparent;
        border-left: calc(10vw/14.4) solid #E43852;
    }
}

.cta-btn {
    display: flex;
    justify-content center;
    padding-bottom: calc(67vw / 14.4);

    div {
        padding: calc(20vw/14.4) calc(35vw/14.4);
        background-color: #2A3642;
        color: #fff;
        transition: color 0.3s cubic-bezier(0.22, 0.44, 0, 1);
        font-family: futura-pt, 'Noto Sans KR';
        font-size: calc(14vw / 14.4);
        font-weight: 500;
        line-height: calc(25vw / 14.4);
        text-align: center;
        .text-uppercase {
            font-weight: 500;
            text-transform: uppercase;
        }
        &:hover {
            color: #E43852;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .title {
        min-height: auto;
        height: 50vh;
        padding-top: calc(100vw / 3.2);
        background-image: url('../../public/img/main_visual_original_mobile.png');

        hgroup {
            left: 35px;
            max-width: 90%;

            h1 {
                font-size: 26px;
                line-height: normal;
            }
        }
    }

    .expert {
        height: calc(144vw * 1.128);
        min-height: 396px;
        width: 100%;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: calc((30vw / 3.2)) 0;

        .wrapper {
            max-width: none;
            top: 0;
            transform: none;
        }

        &.why {
            transform: translateX(0);
            background-position: center bottom 20px;
            background-size: 83% auto;
            opacity: 1;
            margin-top: calc((30vw / 3.2));
            .wrapper {
                opacity: 1;
            }
        }

        &.what {
            padding: 50px;
            background-position: center bottom;
            background-size: 100% auto;
            margin-bottom: calc((30vw / 3.2));
        }

        h2 {
            font-size: calc(21vw/3.2);
        }

        .content {
            font-size: calc(12vw/3.2);
            line-height: calc(18vw / 3.2);
        }
    }

    .video-player {
        padding-top: calc((20vw / 3.2));

        .wrapper {
            flex-direction: column;
        }

        h2 {
            width: 100%;
            text-align: center;
            font-size: calc(16vw / 3.2);
        }
    }

    .how {
        h2 {
            font-size: calc(16vw/3.2);
            margin: calc(15vw/3.2) 0 calc(8vw/3.2) 0;
            padding-left: calc(20vw/3.2);
            padding-right: calc(20vw/3.2);
        }

        .intro {
            font-size: calc(12vw/3.2);
            margin-bottom: calc(20vw/3.2);
        }

        .how-points {
            flex-direction: column;
            align-items: center;

            .point {
                width: auto;
            }

            h3 {
                font-size: calc(16vw/3.2);
            }

            p {
                font-size: calc(12vw/3.2);
                margin-top: calc(12vw/3.2);
            }

            .visual {
                height: calc(100vw/3.2);
                width: calc(100vw/3.2);

                img {
                    height: calc(60vw/3.2);
                }
            }

            .arrow-wrapper {
                margin-top: 0;
                height: calc(50vw/3.2);
                flex-direction: column;
                justify-content: center;

                .arrow-line {
                    width: calc(30vw/3.2);
                    height: 1px;
                    transform: rotate(90deg);
                    display: none;
                }

                .arrow {
                    border-top: calc(10vw / 3.2) solid transparent;
                    border-bottom: calc(10vw / 3.2) solid transparent;
                    border-left: calc(10vw / 3.2) solid #e43852;
                    transform: rotate(90deg);
                }
            }
        }
    }

    .cta-btn {
        margin: calc(25vw / 3.2) calc(45vw / 3.2);

        div {
            font-size: calc(14vw / 3.2);
            line-height: calc(20vw / 3.2);
            padding: calc(12vw / 3.2);
            text-align:center;

            &.more-padding {
              padding: calc(12vw / 3.2) calc(37vw / 3.2);
            }
        }
    }
}


#content {
    &.ja {
        .title hgroup {
            max-width: 90%;
        }

        .expert {
            .wrapper {
                max-width: calc(538vw / 14.4);
            }

            @media only screen and (max-width: 1024px) {
              padding: 50px 10px;
              .wrapper {
                  max-width: inherit;
              }
              .content {
                  font-size: calc(11vw / 3.2);
              }
              &.what .content {
                  font-size: calc(9.5vw / 3.2);
              }
            }
        }
    }

    &.ko {
        .expert {
            @media only screen and (max-width: 1024px) {
                &.what {
                    height: calc(150vw * 1.128);
                }
            }
        }
    }
}
</style>

<style lang="stylus">
.brandtech {
    .mobile-only {
        display: none;
        @media only screen and (max-width: 1024px) {
            display: block;
        }
    }

    .expert {
        &.why p:nth-child(1) {
            margin-bottom: 5%;
        }
        .content {
            .font-dark {
                color: #2A3642;
                font-weight: 500;
            }
            .font-pink {
                color: #E43852;
                font-weight: 500;
            }
        }
    }

    .cta-btn {
        .font-bold {
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}
</style>
