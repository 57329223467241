<template>
    <span class="our-approach-points">
        <span v-for="(point, index) in this.points" :key="'point_' + index" class="point_wrapper">
            <div class="point js-scroll">
                <div class="visual">
                    <img :src="point.visual" alt="">
                </div>
                <h3 >{{ point.title }}</h3>
                <p v-html="point.content"></p>
            </div>
            <div class="arrow-wrapper js-scroll" v-if="(index + 1) != points.length">
                <span class="arrow-line"></span>
                <div class="arrow"></div>
            </div>
        </span>
    </span>
</template>
<script>
    export default {
        props: {
            points: {
                type: Array
            }
        },
        created() {
            const scrollElements = document.querySelectorAll(".js-scroll");
            scrollElements.forEach((el) => {
                el.style.opacity = 0
            })
            window.addEventListener('scroll', () => {
                this.handleScrollAnimation();
            })
            
        }, 
        methods: {
            elementInView: function (el, offset) {
                const elementTop = el.getBoundingClientRect().top;
                return (
                    elementTop <= (window.innerHeight || document.documentElement.clientHeight) - offset
                );
            },
            displayScrollElement: function (element, index) {
                let delay = 250 * index
                if (index % 2 === 1) {
                    delay = 250 * index + 200
                }
                setTimeout(() => {
                    element.classList.add("scrolled");
                }, delay)
            },
            handleScrollAnimation: function () {
                const scrollElements = document.querySelectorAll(".js-scroll");
                scrollElements.forEach((el, key) => {
                    if (this.elementInView(el, 100)) {
                        this.displayScrollElement(el, key);
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>

.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
}
 
.js-scroll.scrolled {
  opacity: 1;
}

    .our-approach-points {
    max-width: 90%;
    display: flex;
    margin: auto;
    justify-content: space-evenly;

    .point_wrapper {
        display: flex;
    }

    .point {
        padding: 0 5vw;
    }

    .visual {
        height: calc(161vw/14.4);
        width: calc(161vw/14.4);
        margin: 0 auto calc(35vw/14.4) auto;
        border-radius: 50%;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: calc(90vw/14.4);
            width: auto;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: calc(24vw/14.4);
        letter-spacing: calc(4.8vw/14.4);
        color: #ffffff;
        margin-bottom: calc(15vw/14.4);
    }

    p {
        color: #ffffff;
        font-size: calc(20vw/14.4);
        line-height: 1.32;
    }

    .arrow-wrapper {
        display: flex;
        align-items: center;
        margin-top: calc(calc(-200vw/14.4)/2);

        // &:last-child {
        //     display: none;
        // }
    }

    .arrow-line {
        width: calc(172vw/14.4);
        height: calc(1vw/14.4);
        background: #fff;
    }

    .arrow {
        width: 0;
        height: 0;
        border-top: calc(10vw/14.4) solid transparent;
        border-bottom: calc(10vw/14.4) solid transparent;
        border-left: calc(10vw/14.4) solid #fff;
    }

}

@media only screen and (max-width: 1024px) {
    .our-approach-points {
            flex-direction: column;
            align-items: center;

            .point_wrapper {
                flex-direction: column;                
            }

            .point {
                width: auto;
            }

            h3 {
                font-size: calc(16vw/3.2);
            }

            p {
                font-size: calc(12vw/3.2);
            }

            .visual {
                height: calc(100vw/3.2);
                width: calc(100vw/3.2);

                img {
                    height: calc(60vw/3.2);
                }
            }

            .arrow-wrapper {
                margin-top: 0;
                height: calc(50vw/3.2);
                flex-direction: column;
                justify-content: center;

                .arrow-line {
                    width: calc(30vw/3.2);
                    height: 1px;
                    transform: rotate(90deg);
                    display: none;
                }

                .arrow {
                    border-top: calc(10vw / 3.2) solid transparent;
                    border-bottom: calc(10vw / 3.2) solid transparent;
                    border-left: calc(10vw / 3.2) solid #fff;
                    transform: rotate(90deg);
                }
            }
        }
}
    
</style>